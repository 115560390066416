import React, { ReactChildren, ReactChild } from "react";
import styled from "styled-components";

interface AuthSecondaryWrapperProps {
  children: ReactChild | ReactChildren;
}

const AuthSecondaryWrapper = ({ children }: AuthSecondaryWrapperProps) => {
  return (
    <Outer className="AuthSecondaryWrapper-Outer">
      <P1>Settings</P1>
      {children}
    </Outer>
  );
};

export default AuthSecondaryWrapper;

const Outer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border: 1px solid #f3f3f3;
  box-shadow: inset 3px 3px 30px rgba(213, 213, 213, 0.6);
  padding: 0 40px;
`;

const P1 = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  margin: 20px 0px;
`;
