import { useEffect, useState } from "react";

export const useFilterArray = <T>({
  inputText,
  filter_by,
  data,
}: {
  inputText: string;
  filter_by: (input_text: string, items: T[]) => T[];
  data: T[];
}) => {
  const [filteredData, setFilteredData] = useState(() =>
    filter_by(inputText, data)
  );

  useEffect(() => {
    setFilteredData(filter_by(inputText, data));
  }, [data, filter_by, inputText]);

  return filteredData;
};

export const useAsync = <T, A = undefined>(
  func: (a: A) => Promise<T>,
  args?: A
) => {
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<T>();

  const updateData = async (data: A) => {
    setLoading(true);
    try {
      const res = await func(data);
      setData(res);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const refresh = async () => {
    if (!args) return;
    await updateData(args);
  };

  useEffect(() => {
    if (!args) return;
    updateData(args).finally(() => setFirstLoad(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args, func]);

  return { loading, updateData, firstLoad, error, data, refresh };
};

export type useAsyncReturnType<T, A = undefined> = ReturnType<
  typeof useAsync<Awaited<ReturnType<T>>, A>
>;
