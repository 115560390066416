import React from "react";
import styled from "styled-components";
import { Redirect, Switch, Route } from "react-router-dom";

import { Home } from "./pages/Home";
import { Support } from "./pages/Support";

import { useAuthentication } from "./context/authentication";

import AuthMenuWrapper from "./components/AuthMenuWrapper";
import SpinnerLarge from "./components/SpinnerLarge";
import Login from "./pages/LandingAuth/Login";
import Register from "./pages/LandingAuth/Register";
import ForgotPassword from "./pages/LandingAuth/ForgotPassord";
import Payments from "./pages/Payments";
import { useIsUserActive } from "./services/hooks";
import Project from "pages/Project";

export const App = () => {
  const { isFetchingUser, isLogged } = useAuthentication();

  if (!isLogged && isFetchingUser)
    return (
      <SpinnerContainer>
        <SpinnerLarge />
      </SpinnerContainer>
    );

  return isLogged ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

const AuthenticatedApp = () => {
  const { isLogged } = useAuthentication();
  const userIsActive = useIsUserActive();

  if (!isLogged) return <Redirect to="/login" />;
  if (!userIsActive)
    return (
      <AuthMenuWrapper hideSidebar>
        <Home />
      </AuthMenuWrapper>
    );

  return (
    <Switch>
      <Route path="/" exact>
        <AuthMenuWrapper>
          <Home />
        </AuthMenuWrapper>
      </Route>

      <Route path="/support" exact>
        <AuthMenuWrapper>
          <Support />
        </AuthMenuWrapper>
      </Route>

      <Route path="/project/:projectid" exact>
        <Project />
      </Route>

      <Route path="/payments" exact>
        <AuthMenuWrapper>
          <Payments />
        </AuthMenuWrapper>
      </Route>

      <Redirect from="*" to="/" />
    </Switch>
  );
};

const UnauthenticatedApp = () => {
  return (
    <Switch>
      <Route exact path={["/", "/login"]}>
        <Login />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

const SpinnerContainer = styled.div``;
