import styled from "styled-components";
import { Button1 } from "components/CommonComponents";

// TODO: Delete soon
export const GeneralSummaryInner = styled.div``;
// END TODO

export const Main = styled.div`
  padding: 30px 10%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{ alignitems?: string }>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignitems }) => alignitems || "initial"};
`;

export const ReportContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background-color: #f3f3f3;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border: 1px solid #f3f3f3;
  box-shadow: inset 3px 3px 30px rgba(213, 213, 213, 0.6);
`;

export const UploaderContainer = styled.div`
  flex: 1;
  background-color: #f3f3f3;
  margin-right: 30px;
  padding: 20px;
  border-radius: 40px;
  border: 2px dashed #c1c1c1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    font-family: "Spline Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #c1c1c1;
  }

  span {
    color: #7d7d7d;
    text-decoration: underline;
  }
`;

export const SpinnerContainer = styled.div`
  margin-right: 10px;
`;

export const SpinnerContainer2 = styled.div`
  margin-left: 10px;
`;

export const ContainerCreate = styled(Container)`
  align-items: center;
  justify-content: center;
`;

export const ContainerGenerate = styled(Container)`
  align-items: center;
`;

export const ContainerGenerateInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row0 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Row1 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Row2 = styled.div`
  display: flex;
  align-items: center;
`;

export const Row3 = styled(Row)`
  flex: 1;
  align-items: initial;
`;

export const Row6 = styled.div`
  width:50%;
`;

export const Row3Left = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

export const Row3Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Row4 = styled.div`
  display: flex;
`;

export const ToggleBtns = styled.div``;

const ToggleBtnRadius = "5px";
export const ToggleBtn = styled.button<{ active: boolean; left: boolean }>`
  background-color: ${({ active }) => (active ? "black" : "white")};
  color: ${({ active }) => (active ? "white" : "#7D7D7D")};
  padding: 5px 10px;
  border-top-left-radius: ${({ left }) => (left ? ToggleBtnRadius : "0px")};
  border-bottom-left-radius: ${({ left }) => (left ? ToggleBtnRadius : "0px")};
  border-top-right-radius: ${({ left }) => (left ? "0px" : ToggleBtnRadius)};
  border-bottom-right-radius: ${({ left }) => (left ? "0px" : ToggleBtnRadius)};
  border: 1px solid ${({ active }) => (active ? "black" : "#7D7D7D")};
  cursor: ${({ active }) => (active ? "default" : "pointer")};
  min-width: 150px;
  font-size: 15px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const File2 = styled.div`
  background-color: white;
  padding: 5px 25px;
  border-radius: 40px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileName = styled.p`
  cursor: pointer;
  text-decoration: underline;
  font-size: 17px;
`;

export const ViewFileBtn = styled.img`
  width: 18px;
`;

export const TopBarRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const TopBarLeft = styled.div``;

export const TopBarCenter = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StepContainer = styled.div<{ status: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;

  span {
    font-size: 22px;
    font-weight: 500;
    color: ${({ status }) => getStepText(status)};
  }

  p {
    font-size: 12px;
    text-align: center;
  }

  .step {
    background-color: ${({ status }) => getStepBg(status)};
    border: 2px solid ${({ status }) => getStepBorder(status)};
    width: 60px;
    height: 60px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const getStepBg = (status: string) => {
  switch (status) {
    case "future":
      return "white";

    case "active":
      return "#e4fd90";

    case "done":
      return "#F3F3F3";

    default:
      return "#e4fd90";
  }
};

const getStepBorder = (status: string) => {
  switch (status) {
    case "future":
      return "#e4fd90";

    case "active":
      return "#e4fd90";

    case "done":
      return "#F3F3F3";

    default:
      return "#e4fd90";
  }
};

const getStepText = (status: string) => {
  switch (status) {
    case "future":
      return "#2C2C2C";

    case "active":
      return "#2C2C2C";

    case "done":
      return "#C1C1C1";

    default:
      return "#e4fd90";
  }
};

const getStepLine = (status: string) => {
  switch (status) {
    case "future":
      return "#e4fd90";

    case "active":
      return "#F3F3F3";

    case "done":
      return "#F3F3F3";

    default:
      return "#e4fd90";
  }
};

export const StepLine = styled.div<{ status: string }>`
  width: 40px;
  height: 2px;
  background-color: ${({ status }) => getStepLine(status)};
  margin-top: 30px;
`;

export const TopBarRight = styled.div``;

export const BackBtnImg = styled.img``;

export const FilesEmptyState = styled.div`
  border: 1px solid #c1c1c1;
  border-radius: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BackBtn = styled.p`
  text-decoration: underline;
  color: #346af8;
  cursor: pointer;
  font-size: 16px;
`;

export const Btns = styled.div`
  display: flex;
  align-items: center;
`;

export const Step2Img = styled.img`
  width: 70%;
  max-width: 300px;
`;

export const ProcessDataBtn = styled.button<{ complete: boolean }>`
  cursor: pointer;
  background-color: ${({ complete }) => (complete ? "white" : "#38c154")};
  color: ${({ complete }) => (complete ? "#38c154" : "white")};
  margin: 10px 0;
  padding: 10px 20px;
  border: 1px solid #38c154;
  font-size: 18px;
  margin-right: 15px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NewFileBtn = styled(Button1)`
  margin-right: 10px;
`;

// ARCHIVED
export const Table = styled.table`
  flex: 1;

  th {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #b9b9b9;
  }

  td {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebebeb;
  }

  .name {
    width: 60%;
  }

  .file {
    width: 10%;
  }

  .date {
    width: 30%:
  }
`;

export const File = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .name {
  }

  .btm {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .date {
    font-size: 12px;
    margin: 0;
  }

  .delete_cell {
    display: flex;
    justify-content: center;
  }

  .delete_icon {
    max-width: 16px;
  }
`;

export const FileIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const HIU1 = styled.h1``;

export const HIU2 = styled.h2``;

export const P0 = styled.p`
  color: #346af8;
  font-size: 16px;

  span {
    font-weight: 600;
  }
`;

export const P1 = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-size: 28px;
  color: #5c5c5c;
  font-weight: 300;
`;

export const P2 = styled.p`
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  color: #346af8;
`;

export const P3 = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #346af8;
`;

export const P4 = styled.p`
  margin: 0;
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 22px;
  font-weight: 500;
  color: #346af8;
  text-align: center;
`;

export const P5 = styled.p`
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #346af8;
  text-align: center;
`;

export const P6 = styled.p`
  margin: 0;
  font-size: 22px;
  color: #5c5c5c;
  font-weight: 300;
  max-width: 300px;
`;

export const P6Small = styled(P6)`
  font-size: 14px;
`;

export const P7 = styled.p`
  margin: 0;
  font-size: 22px;
  color: #5c5c5c;
  font-weight: 300;
`;

export const P7Small = styled(P7)`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const PSelectedKeywords = styled(P7Small)`
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const Checkmark = styled.img`
  width: 29px;
  height: 24px;
  margin-right: 10px;
`;

export const ContainerGenerated = styled(Container)`
  align-items: top;
  padding-bottom: 50px;
`;

export const ReportInner = styled.div`
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 20px;
`;

export const ReportInnerLoading = styled.div`
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 20px;
  height:70px;
`;

export const R1 = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
`;

export const R2 = styled.p`
  font-size: 18px;
`;

export const R3 = styled.p`
  font-size: 13px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 3px;
  background-color: #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SummaryContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top:5px;
`

export const Keywords = styled.div`
  width: calc(100% - 30px);
  display: flex;
  flex-wrap: wrap;
  background-color: #f3f3f3;
  padding: 15px;
  border-radius: 20px;
`;

export const Keyword = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;

  width: calc(25% - 42px);
  margin: 5px 5px;
  padding: 10px 15px;
  border: 1px solid ${({ selected }) => (selected ? "#E4FD90" : "#2c2c2c")};
  background-color: ${({ selected }) => (selected ? "#E4FD90" : "white")};
  border-radius: 20px;
  cursor: pointer;

  :hover {
    background-color: #f5ffd5;
    transition-duration: 0.6s;
  }

  @media (max-width: 1050px) {
    width: calc(50% - 42px);
  }
`;

export const KeywordText = styled.p<{ selected: boolean }>`
  color: #000;
  font-size: 17px;
  margin: 0;
  text-transform: capitalize;

  span {
    color: #a6a6a6;
    font-size: 14px;
  }
`;

export const KeywordText2 = styled(KeywordText)`
  font-size: 15px;

  span {
    font-size: 13px;
  }
`;

export const UnselectedCheckMarkDiv = styled.div`
  background-color: white;
  border: 1px solid #949494;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 5px;
`;

export const SelectedCheckMarkDiv = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const Col6 = styled.div`
  width: 50%;
`
export const Col3 = styled.div`
  width: 25%;
  display:flex;
  align-items: center;
  justify-content:start;
`

export const dateConverter = (timestamp: any) => {
  let date = new Date(timestamp).toDateString();

  return date;
};
