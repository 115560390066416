import React, { FC, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

import AlertModal from "components/AlertModal";
import { H1, H2 } from "components/CommonComponents";
import { ProjectType, useFirestore } from "context/firestore";
import { useShowModal } from "context/Modal";
import {
  Row3,
  Row3Left,
  FilesEmptyState,
  Row3Right,
  UploaderContainer,
} from "../projectComponents";
import { PrimaryBtnStyled } from "./surveyComponents";
import FilesTableRow from "./FilesTableRow";
import dataProcesss from "services/DataProcess";

const UploadFiles: FC<{
  project?: ProjectType;
  projectFiles?: any[];
  triggerNextStep: (fromAction?: boolean) => void;
}> = ({ project, projectFiles, triggerNextStep }) => {
  const [loadedFiles, setLoadedFiles] = useState<File[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const { uploadPdf } = useFirestore();
  const showModal = useShowModal();

  const onDrop = (files: File[]) => {
    setLoadedFiles(files);
  };

  const handleUploadFiles = async () => {
    setUploadingFile(true);
    try {
      if (loadedFiles.length === 0) throw new Error("No files provided.");
      if (!project) throw new Error("Page haven't loaded yet.");

      await uploadPdf(project.id, loadedFiles);
      await dataProcesss.transcript.upload_data_to_pinecone(project.id)
      triggerNextStep(false);
    } catch (e: any) {
      showModal(<AlertModal>{e.message}</AlertModal>);
      setUploadingFile(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    onDrop,
  });

  const deleteFileHelper = async (delete_fileid: any) => {
    // try {
    //   await DataProcessService.deleteFile({ fileid: delete_fileid });
    //   // listProjects();
    //   setProjectFilesAndStep();
    // } catch (err: any) {
    //   alert(err.message);
    // }
  };

  if (!project) return <div>Loading...</div>;

  return (
    <Row3>
      <Row3Left>
        <H1>{project.name}</H1>
        {/* <div > */}
        <UploaderContainer {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {loadedFiles.length > 0 && (
            <div>
              <h4>Loaded files:</h4>
              <ul>
                {loadedFiles.map((file: File) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
          {loadedFiles.length === 0 && (
            <p>
              Drag and drop your files here or <span>browse your computer</span>
            </p>
          )}
        </UploaderContainer>
        {/* </div> */}

        <PrimaryBtnStyled disabled={uploadingFile} onClick={handleUploadFiles}>
          {uploadingFile ? "Uploading.." : "Upload File"}
        </PrimaryBtnStyled>
      </Row3Left>

      <Row3Right>
        <H2Styled>Uploaded Files</H2Styled>
        {projectFiles && projectFiles.length > 0 ? (
          projectFiles.map((f: any, id: number) =>
            f.deleted ? null : (
              <FilesTableRow
                file_object={f}
                deleteFileHelper={deleteFileHelper}
                key={id}
              />
            )
          )
        ) : (
          <FilesEmptyState>
            <img src={require("assets/emptybox.png")} alt="No files." />
            <p>No files uploaded</p>
          </FilesEmptyState>
        )}
      </Row3Right>
    </Row3>
  );
};

export default UploadFiles;

const H2Styled = styled(H2)`
  margin-top: 40px;
  margin-bottom: 23px;
`;
