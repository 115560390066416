import { useAuthentication } from 'context/authentication';
import { useFirestore } from 'context/firestore';
import React, { useEffect, useState } from 'react';
import dataProcesss from 'services/DataProcess';
import styled, { keyframes } from 'styled-components'

const Logo = styled.img`
    width:50px;
    height:50px;
    border-radius:50%;
    margin-top:5px;
`

const OuterDiv = styled.div`
  margin-top:50px;
  padding-bottom:100px;
  display: flex;
`

const ContactColumn = styled.div`
  flex: 0 0 33%;
  max-width: 33%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`

const ChatColumn = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  min-width: 540px;
  position: relative;
  width: 100%;
  border-radius:3rem;
  border:2px solid black;
`
const UserLogo = styled.div`
    width:50px;
    height:50px;
    border-radius:50%;
    background:#4e6266;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:5px;

`

const MessageContainer = styled.div<{user?: any}>`
    display:flex;
    flex-direction: ${({ user }) => user === "bot" ? 'row' : "row-reverse"};
    align-items:start;
    margin:0px 10px;
    justify-items: ${({ user }) => user === "bot" ? 'start' : "end"};

`

const MessageListColumn = styled.div`
  display:flex;
  flex-direction: column-reverse;
  overflow: scroll;
  padding-top: 3rem;
  background-color: #f3f3f3;
  box-sizing: border-box;
  border: 1px solid #dee2e6;
  height: 400px;
  border-radius:3rem 3rem 0rem 0rem;
  padding-bottom:15px;
`

const Message = styled.div`
  padding: 1rem;
  margin: 0.25rem;
  float: left;
  background: #f4f7f9;
  border-radius: 2rem;
  border: 1px black solid;
  max-width:600px;
`

const MessageListHeader = styled.div`
  padding: 1rem 0rem !important;
  border-bottom:2px solid black;
`

const MessageListTitle = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
`
const MessageForm = styled.form`
  max-width: 100%;
  padding:20px;
  margin:0;
  background:#e5e5e5;
  border-radius:0rem 0rem 3rem 3rem;
`
export const FlexBox = styled.div`
    display:flex;
    gap:4px;

`

const Col9 = styled.div`
  width: 85%;
`

const Col3 = styled.div`
  width: 15%;
  display:flex;
  align-items: flex-end;
  justify-content:end;
`

const MessageInput = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius:2rem;
`

const MessageButton = styled.button<{disabled:boolean}>`
  color: #fff;
  background-color:  ${({ disabled}) =>
  disabled ? "#758F95" :"black" };
  padding: 16px 32px;
  font-size: 1rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border-radius:3rem;
  cursor: ${({ disabled}) =>
  disabled ? "normal" :"pointer" };;
`

export const DataHeaderDiv = styled.div<{
    selectable: boolean;
    selected: boolean;
    qualitative: boolean;
  }>`
    border: 1px solid ${({ selectable }) => (selectable ? "black" : "#eee")};
    border-radius: 8px;
    padding: 8px 11px;
    margin-bottom: 15px;
    display: flex;
    cursor: ${({ selected, selectable }) =>
      selected ? "pointer" : selectable ? "pointer" : ""};
    transition: all 0.2s ease-in-out;
    background-color: ${({ selected, selectable }) =>
      selected ? "black" : selectable ? "white" : "#F3F3F3"};
  
    :hover {
      // background-color: #eee;
      background-color: ${({ selected, selectable }) =>
        selected ? "black" : selectable ? "#eee" : "#F3F3F3"};
    }
  
    .headername {
      font-weight: bold;
      margin: 0;
      font-size: 17px;
      color: ${({ selected }) => (selected ? "white" : "black")};
    }
  
    .columndatatype {
      background-color: ${({ qualitative }) =>
        qualitative ? "green" : "maroon"};
      color: white;
      font-size: 10px;
      padding: 2px 5px;
      border-radius: 5px;
      font-weight: 500;
      margin-left: 10px;
    }
  
    .exampletext {
      font-weight: light;
      margin: 0;
      margin-top: 5px;
      font-size: 13px;
      color: ${({ selected }) => (selected ? "grey" : "#444")};
    }
  
    .data-header-right {
      margin: auto 10px;
    }
  
    .data-header-right-check-icon {
      display: ${({ selected, selectable }) => (selected ? "block" : "none")};
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 20px;
    }
  
    .data-header-right-circle {
      display: ${({ selected, selectable }) =>
        selected ? "none" : selectable ? "block" : "none"};
      width: 15px;
      height: 15px;
      border-radius: 20px;
      border: 1px solid black;
      background-color: white;
    }
  `;
  
  export const DataHeaderLeft = styled.div`
    flex: 1;
  `;
  
  export const DataHeaderCircleIcon = styled.div`
    margin: auto 10px;
    border: 1px solid black;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-color: white;
  `;
  const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;

  const Dot = styled.div<{delay:any}>`
  background-color: #757575;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px;  /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${props => props.delay};
`;


const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top:15px;
`;
export const LoadingDots = () => <DotWrapper>
      <Dot delay="0s" />
      <Dot delay=".1s" />
      <Dot delay=".2s" />
    </DotWrapper>


const getFirstLettersFromEmail = (email:any) => {
    return email?.slice(0,2)?.toUpperCase();
}



function ChatBox({projectid, transcriptQuestionAnswerList, transcriptFields, projectFiles,selectedTranscriptFiles}:any) {
    type Message = {
        user?:string;
        text?:string;
    }
    const { createTranscriptQuestionAns } = useFirestore()
    const { user: userData } = useAuthentication();
    const [questionAnsData,setQuestionAnsData] = useState<any>({
      question:"",
      selectedFileIds:[]
    })
    const [message,setMessage] = useState<Message>()
    const _staticmsgList = [
      {user:'bot',text:"Hi, welcome to SumUps Q&A Bot !"},
      {user:'bot',text:"Ask any questions related to your transcripts, enter a question and select any transcript to answer the question."},
  ]
    const [messageList,setMessageList] = useState<Message[]>(_staticmsgList)
    const getMsgList = async() => {
      const questionAnswerList = []
      if (transcriptQuestionAnswerList) {
        for (var i = 0; i < transcriptQuestionAnswerList.length; i++) {
          const currentFile = transcriptQuestionAnswerList[i].data();
          questionAnswerList.push(currentFile);
        }
      }
        const _data= questionAnswerList.sort(function(a:any, b:any){return a.createdOn - b.createdOn}); 
        let _newMsgList = _staticmsgList;
        _data.map((i:any) => {
            _newMsgList.push({
                user:'user',
                text:i.question
            })
            if(i.answer) {
                _newMsgList.push({
                    user:'bot',
                    text:i.answer
                })
            }
        })
        setMessageList(_newMsgList)
        setMessage({
            user:'user',
            text:""
        })

    }

    useEffect(() => {
        getMsgList()
    },[projectid, transcriptQuestionAnswerList])
    
    const [isLoading,setLoading] = useState(false)
    const submitForm = async(event:any) => {
       try{
        setLoading(true)
        event.preventDefault();
        if(!message) throw new Error("Empty message")
        const newMessageList = [...messageList, message]
        setMessageList(newMessageList)
        const _text = message.text
        setMessage({
            user:"user",
            text:""
        })
        if(!_text) throw new Error("Empty message")
        const _newQuestionAns = await createTranscriptQuestionAns(projectid,_text,questionAnsData.selectedFileIds)
        const _id = _newQuestionAns.f_.path.segments[1]
        await dataProcesss.transcript.get_summary_of_question_ans(projectid,_id)
        setQuestionAnsData({
          question:"",
          selectedFileIds:[]
        })
        
     
       }catch(err:any){
        console.log(err)
      }finally {
        setLoading(false)
      }
      }
    const updateMessage = (text:string) => {
        setMessage({
            user:'user',
            text
        })
        setQuestionAnsData({
          ...questionAnsData,
          question:text
        })
    }

    const selectColumnHeaderHelper = (headerName: string) => {
        if (questionAnsData.selectedFileIds.includes(headerName)) {
          setQuestionAnsData({
            ...questionAnsData,
            selectedFileIds:questionAnsData.selectedFileIds.filter((item:any) => item !== headerName),
          });
        } else {
            setQuestionAnsData({
            ...questionAnsData,
            selectedFileIds:[...questionAnsData.selectedFileIds,headerName]
          });

        }
      };
    
      const [transcripts, setTranscripts] = useState<any[]>([]);
      useEffect(() => {
        let localProjectFilesBuilder: any[] = [];
        if (projectFiles) {
          for (var i = 0; i < projectFiles.length; i++) {
            const currentFile = projectFiles[i].data();
            currentFile.fileId = projectFiles[i].id;
            localProjectFilesBuilder.push(currentFile);
          }
          setTranscripts(localProjectFilesBuilder);
        }
      
      }, [projectFiles]);

      const MAX_MESSAGE_COUNT = 12

    return (
        <OuterDiv>
          <ChatColumn>
            <MessageListColumn>
              <div>
                {messageList.map( (msg: Message,index:number) => {
                    return <MessageContainer key={msg.text+index.toString()} user={msg.user}>
                            {msg.user === 'bot' ? <Logo src={require('../../../assets/chubbs-response-icon.png')} /> : 
                            <UserLogo>{getFirstLettersFromEmail(userData?.email)}</UserLogo>
                            }
                            <Message>{ msg.text }</Message>

                        </MessageContainer>
                  })
                }
                {transcriptFields?.answer_question_from_transcript?.running && <MessageContainer user={'bot'}>
                            <Logo src={require('../../../assets/chubbs-response-icon.png')} /> 
                            <LoadingDots/>

                        </MessageContainer>}

              </div>
            </MessageListColumn>
            <div >
              <MessageForm onSubmit={submitForm}>
                <FlexBox>
                <Col9>
                  <MessageInput type="text" disabled={transcriptFields?.answer_question_from_transcript?.running || isLoading || messageList.length > MAX_MESSAGE_COUNT} value={message?.text} onChange={(e) => updateMessage(e.target.value)} placeholder='Ask your question...' />
                </Col9>
                <Col3>
                  <MessageButton type="submit" disabled={!(message?.text && questionAnsData.selectedFileIds?.length >0)}>Ask</MessageButton>
                </Col3>
                </FlexBox>

                {message?.text &&    <FlexBox>
                  {transcripts.map((header: any, index: any) => {
        return (
          <DataHeaderDiv
            key={index}
            onClick={() =>
              questionAnsData.selectedFileIds.length < 2
                ? selectColumnHeaderHelper(header.fileId)
                : questionAnsData.selectedFileIds.includes(header.fileId)
                ? selectColumnHeaderHelper(header.fileId)
                : null
            }
            selected={questionAnsData.selectedFileIds?.includes(header.fileId)}
            selectable={questionAnsData.selectedFileIds?.length < 2}
            qualitative={true}
          >
            <DataHeaderLeft>
              <p className="headername">{header.name?.split('.')[0]} </p>
            </DataHeaderLeft>
          </DataHeaderDiv>
        );
      })}
                  </FlexBox>}
              </MessageForm>
            </div>
          </ChatColumn>
        </OuterDiv>
      );
}



export default ChatBox;