import React, { useEffect, useMemo, useState } from "react";

import { Main, Container, P1, Step2Img } from "../projectComponents";
import { H1 } from "components/CommonComponents";
import UploadFiles from "./UploadFiles";
import TranscriptResults from "./TranscriptResults";
import TopBar from "./TopBar";
import { useParams } from "react-router-dom";
import {
  TranscriptFileSummaryType,
  FilesOnDb,
  useFirestore,
} from "context/firestore";
import { useAsync } from "hooks";
import { useShowModal } from "context/Modal";
import AlertModal from "components/AlertModal";
import {
  useFirestoreFieldListener,
  useFirestoreQueryListener,
} from "services/hooks";
import dataProcesss from "services/DataProcess";
import ChooseAnalysisType from "./ChooseAnalysisType";
import SelectData from "./SelectData";
import ExploreAnalysis from "./ExploreAnalysis";

const transcript_fields = ["transcript"];
const proj_col = "projects";

const useWatchTranscriptFields = (projectId: string) => {
  const { transcript } = useFirestoreFieldListener(
    proj_col,
    projectId,
    transcript_fields
  );

  return transcript;
};

const useListenForProjectFiles = (projectId: string) => {
  const { db } = useFirestore();
  const query = useMemo(
    () => db.collection("files").where("projectId", "==", projectId),
    [db, projectId]
  );

  const filesList = useFirestoreQueryListener<FilesOnDb>(query);

  return filesList;
};

const useListenForFileSummaries = (projectId: string) => {
  const { db } = useFirestore();
  const query = useMemo(
    () =>
      db.collection("transcriptsSummaries").where("projectId", "==", projectId),
    [db, projectId]
  );

  const fileSummariesList =
    useFirestoreQueryListener<TranscriptFileSummaryType>(query);

  return fileSummariesList;
};

const useListenForKeywordSummaries = (projectId: string) => {
  const { db } = useFirestore();
  const query = useMemo(
    () =>
      db
        .collection("transcriptKeywordSummary")
        .where("projectId", "==", projectId),
    [db, projectId]
  );

  const transcriptSummaryList =
    useFirestoreQueryListener<TranscriptFileSummaryType>(query);

  return transcriptSummaryList;
};

const useListenForQuestionAnswer = (projectId: string) => {
  const { db } = useFirestore();
  const query = useMemo(
    () =>
      db
        .collection("transcriptQuestionAnswers")
        .where("projectId", "==", projectId),
    [db, projectId]
  );

  const transcriptQuestionAnswerList =
    useFirestoreQueryListener<TranscriptFileSummaryType>(query);

  return transcriptQuestionAnswerList;
};

const useListenForGeneralSummaries = (projectId: string) => {
  const { db } = useFirestore();
  const query = useMemo(
    () =>
      db
        .collection("transcriptsGeneralSummaries")
        .where("projectId", "==", projectId),
    [db, projectId]
  );

  const generalSummariesList =
    useFirestoreQueryListener<TranscriptFileSummaryType>(query);

  return generalSummariesList;
};

export const Transcript = () => {
  const showModal = useShowModal();
  const [step, setStep] = useState<number>(1);
  const [surveyAnalysisType, setSurveyAnalysisType] = useState<string | null>(
    null
  );
  const [selectedTranscriptFiles, setSelectedTranscriptFiles] = useState<
    string[]
  >([]);
  const [keyword, setKeyword] = useState<string | null>(null);
  const { projectid } = useParams<{ projectid: string }>();
  const { getProjectByProjectId } = useFirestore();
  const project = useAsync(getProjectByProjectId, projectid);
  const projectFiles = useListenForProjectFiles(projectid);
  const fileSummaries = useListenForFileSummaries(projectid);
  const generalSummaries = useListenForGeneralSummaries(projectid);
  const keywordSummaries = useListenForKeywordSummaries(projectid);
  const transcriptQuestionAnswerList = useListenForQuestionAnswer(projectid);
  const [startProcessingFiles, setStartProcessingFiles] =
    useState<boolean>(false);

  const transcriptFields = useWatchTranscriptFields(projectid);

  const hasSelectedData = () => {
    if (surveyAnalysisType === "just-text-responses") {
      return true;
    }

    if (surveyAnalysisType === "keyword-summary") {
      return true;
    }

    if (surveyAnalysisType === "question-answer-bot") {
      return true;
    }

    return false;
  };

  const hasStartedRunningApis = () => {
    if (surveyAnalysisType === "just-text-responses")
      return !!transcriptFields?.get_summary_of_transcripts?.started;
    if (surveyAnalysisType === "keyword-summary") return true;
    if (surveyAnalysisType === "question-answer-bot") return true;

    return false;
  };
  const finishedSteps: { [key: number]: () => boolean | undefined } = {
    1: () => hasStartedRunningApis() || projectFiles.length > 0,
    2: () => !!transcriptFields?.upload_transcript_vectors?.finished,
    3: () => surveyAnalysisType !== null,
    4: () => true,
    5: hasSelectedData,
    6: () => {
      if (!hasStartedRunningApis()) return false;

      if (
        surveyAnalysisType === "just-text-responses" &&
        transcriptFields?.get_summary_of_transcripts?.finished
      ) {
        return true;
      }

      if (surveyAnalysisType === "keyword-summary") {
        return true;
      }
      if (surveyAnalysisType === "question-answer-bot") {
        return true;
      }
    },
    7: () => false,
  };

  const triggerNextStep = (fromAction = true) => {
    const checkStepFunc = finishedSteps[step];

    if (!checkStepFunc()) {
      if (fromAction)
        showModal(<AlertModal>Finish this step first</AlertModal>);
      return;
    } else {
      const nextStep = step + 1;
      triggerStepSideEffects(nextStep).catch(() => {
        showModal(<AlertModal>Something went wrong</AlertModal>);
        setStep(step);
      });

      setStep(nextStep);
    }
  };

  useEffect(() => {
    // go to last available page
    if (!project.error && project.firstLoad) {
      triggerNextStep(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    step,
    project.firstLoad,
    project.loading,
    projectFiles,
    startProcessingFiles,
    transcriptFields,
  ]);

  const triggerStepSideEffects = async (nextStep: number) => {
    switch (nextStep) {
      case 5:
        try {
          if (surveyAnalysisType === "just-text-responses") {
            if (!transcriptFields?.get_summary_of_transcripts?.started) {
              setStartProcessingFiles(true);
              await dataProcesss.transcript.get_summary_of_transcripts(
                projectid
              );
            }
          } else if (surveyAnalysisType === "stratified-text-responses") {
          } else if (surveyAnalysisType === "keyword-summary") {
            // TBD
          } else if (surveyAnalysisType === "question-answer-bot") {
            // TBD
          } else {
            throw new Error("Invalid analysis type");
          }
        } catch (e) {
          throw e;
        }
    }
  };

  return (
    <Main>
      <TopBar
        step={step}
        topBarNextFunction={async () => {
          if (projectFiles.length === 0) {
            showModal(
              <AlertModal>
                Please upload at least one transcript file first.
              </AlertModal>
            );
          } else {
            triggerNextStep();
          }
        }}
      />
      {step === 1 && (
        <UploadFiles
          projectFiles={projectFiles}
          project={project.data}
          triggerNextStep={triggerNextStep}
        />
      )}
      {step === 2 && (
        <Container alignitems={"center"}>
          <br />
          <H1>Great! Your file is being processed.</H1>
          <P1>This shouldn't take too long!</P1>
          <Step2Img src={require("assets/generating-report-chubbs.png")} />
        </Container>
      )}
      {step === 3 && (
        <ChooseAnalysisType
          project={project}
          surveyAnalysisType={surveyAnalysisType}
          setSurveyAnalysisType={setSurveyAnalysisType}
          transcriptFields={transcriptFields}
        />
      )}
      {step === 4 && (
        <Container alignitems={"center"}>
          <br />
          <H1>Great! Your data is being processed.</H1>
          <P1>This shouldn't take too long!</P1>
          <Step2Img src={require("assets/generating-report-chubbs.png")} />
        </Container>
      )}
      {step === 5 && (
        <SelectData
          projectFiles={projectFiles}
          setSelectedTranscriptFiles={setSelectedTranscriptFiles}
          setKeyword={setKeyword}
          keyword={keyword}
          selectedTranscriptFiles={selectedTranscriptFiles}
          surveyAnalysisType={surveyAnalysisType}
        />
      )}
      {step === 6 && (
        <Container alignitems={"center"}>
          <br />
          <H1>Great! Your data is being processed.</H1>
          <P1>This shouldn't take too long!</P1>
          <Step2Img src={require("assets/generating-report-chubbs.png")} />
        </Container>
      )}
      {step === 7 && (
        <ExploreAnalysis
          project={project}
          projectId={projectid}
          projectFiles={projectFiles}
          fileSummaries={fileSummaries}
          generalSummaries={generalSummaries}
          keyword={keyword}
          setKeyword={setKeyword}
          selectedTranscriptFiles={selectedTranscriptFiles}
          setSelectedTranscriptFiles={setSelectedTranscriptFiles}
          surveyAnalysisType={surveyAnalysisType}
          transcriptFields={transcriptFields}
          keywordSummaries={keywordSummaries}
          transcriptQuestionAnswerList={transcriptQuestionAnswerList}
        />
      )}
    </Main>
  );
};
