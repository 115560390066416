import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { File, dateConverter } from "../projectComponents";
import { DeleteBtn, DeleteCancelBtn } from "components/ThemeComponents";

const FilesTableRow = ({ file_object, deleteFileHelper }) => {
  let file = file_object.data();
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteFileHelperLocal = (passed_fileid) => {
    setDeleting(true);
    deleteFileHelper(passed_fileid);
  };

  useEffect(() => {
    setDeleting(false);
    setShowDeleteBtn(false);
  }, [file_object]);

  return (
    <File>
      <a href={file.url} target="_blank" rel="noopener noreferrer">
        <p className="name">{file.name}</p>
      </a>
      {/* <td className="file">
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          <FileIcon src={FileIconImg} />
        </a>
      </td> */}
      <div className="btm">
        <p className="date">
          {file.createdon ? dateConverter(file.createdon) : "N/A"}
        </p>
        <div className="delete">
          {showDeleteBtn ? (
            deleting ? (
              <span>Deleting...</span>
            ) : (
              <BtnsRow>
                <DeleteCancelBtn onClick={() => setShowDeleteBtn(false)}>
                  Cancel
                </DeleteCancelBtn>
                <DeleteBtn onClick={() => deleteFileHelperLocal(file.id)}>
                  Delete?
                </DeleteBtn>
              </BtnsRow>
            )
          ) : (
            <div className="delete_cell">
              <img
                alt="Delete Butto"
                onClick={() => setShowDeleteBtn(true)}
                className="delete_icon"
                src={require("assets/bin.png")}
              />
            </div>
          )}
        </div>
      </div>
    </File>
  );
};

export default FilesTableRow;

const BtnsRow = styled.div`
  display: flex;
`;
