import { AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useFirebase } from "../context/firebase";
import { useFirestore } from "../context/firestore";
import http from "./httpService";

type Options = Omit<AxiosRequestConfig<any>, "url" | "baseURL">;

export const useFetchBackend = <T = any>(url: string, options?: Options) => {
  const [fetching, setFetching] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<T>();

  const fetchData = useCallback(async () => {
    setFetching(true);
    try {
      const res = await http.request({
        url,
        ...options,
      });
      setData(res.data);
    } catch {
      setError(true);
    } finally {
      setFetching(false);
    }
  }, [options, url]);

  useEffect(() => {
    fetchData().then(() => setFirstLoad(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wait = useCallback(async () => {
    if (fetching) {
      await new Promise<void>((resolve) => {
        const intervalId = setInterval(() => {
          if (!fetching) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }
  }, [fetching]);

  return {
    data,
    fetching,
    firstLoad,
    error,
    fetchData,
    firstLoadSuccess: firstLoad && !error,
    wait,
  };
};

export const useIsUserActive = () => {
  const { user } = useFirebase();

  return !!user?.emailVerified;
};

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

type FieldValues = Record<string, any>;

export const useFirestoreFieldListener = <T extends FieldValues = FieldValues>(
  collection: string,
  documentId: string,
  fields: string[]
): RecursivePartial<T> => {
  const [fieldValues, setFieldValues] = useState<Partial<T>>({} as T);

  const { db } = useFirestore();

  useEffect(() => {
    const unsubscribe = db
      .collection(collection)
      .doc(documentId)
      .onSnapshot((doc) => {
        const data = doc.data();
        if (data) {
          const newFieldValues: Partial<T> = {} as T;
          fields.forEach((field: keyof T) => {
            if (field in data) {
              newFieldValues[field] = data[field as string];
            } else {
              newFieldValues[field] = undefined;
            }
          });
          setFieldValues(newFieldValues);
        } else {
          setFieldValues({});
        }
      });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [collection, db, documentId, fields]);

  return fieldValues;
};

export type DbQueryListenerType<
  T extends firebase.firestore.DocumentData = firebase.firestore.DocumentData
> = {
  id: string;
  data: () => T;
};

export const useFirestoreQueryListener = <
  T extends firebase.firestore.DocumentData
>(
  query: firebase.firestore.Query
): DbQueryListenerType<T>[] => {
  const [documents, setDocuments] = useState<DbQueryListenerType<T>[]>([]);

  useEffect(() => {
    const unsubscribe = query.onSnapshot((snapshot) => {
      const updatedDocuments: DbQueryListenerType<T>[] = [];
      snapshot.forEach((doc) => {
        updatedDocuments.push({
          id: doc.id,
          data: () => doc.data() as T,
        });
      });
      setDocuments(updatedDocuments);
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [query]);

  return documents;
};
