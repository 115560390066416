import React, { useEffect, useMemo, useState } from "react";
import { PROJECT_TYPES_MAP, useFirestore } from "context/firestore";
import { useAuthentication } from "context/authentication";
import AuthMainWrapper from "components/AuthMainWrapper";
import { PrimaryBtn, TH2 } from "components/ThemeComponents";
import SpinnerLarge from "components/SpinnerLarge";
import ProjectCardSpinner from "components/ProjectCardSpinner";
import { useIsUserActive } from "services/hooks";
import { Button1, Label } from "components/CommonComponents";
import http from "services/httpService";
import { useHideModal, useShowModal } from "context/Modal";
import { useAsync } from "hooks";
import {
  paginatedArray,
  projectStatusHelper,
  PROJECTS_PER_PAGE,
  ProjStatusFilters,
  ProjTypeFilters,
  splitArray,
  useFilter,
} from "./util";
import {
  Btm,
  EmptyState,
  EmptyStateContainer,
  EmptyStateText,
  Filter,
  Filters,
  FiltersSection,
  Main,
  MainCentered,
  PgBtn,
  PrimaryBtnRow,
  ProjectCard,
  ProjectList,
  ProjectListColumn,
  Row,
} from "./styles";
import NewProjectModal from "./NewProjectModal";

export const Home = () => {
  const { user } = useAuthentication();
  const { createProject, listProjectByUserId } = useFirestore();
  const userIsActive = useIsUserActive();
  const showModal = useShowModal();
  const hideModal = useHideModal();

  const projTypeFilters = useFilter(ProjTypeFilters);

  const selectedProjType = projTypeFilters.filterBy.projectType as
    | keyof typeof PROJECT_TYPES_MAP
    | undefined;

  const projStatusFilters = useFilter(
    selectedProjType ? ProjStatusFilters[selectedProjType] : []
  );

  const projectProps = useMemo(
    () =>
      user?.uid
        ? {
            userId: user.uid,
            filterBy: {
              ...projStatusFilters.filterBy,
              ...projTypeFilters.filterBy,
            },
          }
        : undefined,
    [user, projStatusFilters.filterBy, projTypeFilters.filterBy]
  );

  const projects = useAsync(listProjectByUserId, projectProps);
  const projectsLength =
    (projects.firstLoad ? projects.data?.length : undefined) || 0;

  const projectsPaginated = useMemo(
    () => projects.data && paginatedArray(projects.data, PROJECTS_PER_PAGE),
    [projects.data]
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(0);

  const currentPageSplited =
    !!projectsPaginated?.pageCount &&
    projectsPaginated.pages[currentPage] &&
    splitArray(projectsPaginated.pages[currentPage]);

  useEffect(() => {
    if (
      !!projectsPaginated?.pageCount &&
      !projectsPaginated.pages[currentPage]
    ) {
      setCurrentPage(projectsPaginated.pages.length - 1);
    }
  }, [currentPage, projectsPaginated]);

  const handleCreateProject = async (
    newProjectName: string | undefined,
    projectType: string
  ) => {
    try {
      if (!newProjectName || newProjectName.length === 0)
        throw new Error("Project name should not be empty");
      setLoading(true);
      await createProject(newProjectName, projectType);
      setLoading(false);
      hideModal();
      await projects.refresh();
    } catch (err: any) {
      alert(err.message);
    }
  };

  const handleSendConfirmationEmailAgain = async () => {
    try {
      await http.post("/send-confirmation-email");
      alert("Email sent");
    } catch {
      alert("Something went wrong. Try again later");
    }
  };

  const handleNextPage = () => {
    if (!projectsPaginated?.pages[currentPage + 1]) return;
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (!projectsPaginated?.pages[currentPage - 1]) return;
    setCurrentPage(currentPage - 1);
  };

  if (!userIsActive) {
    return (
      <MainCentered>
        <TH2>Thank you for signing up for SumUps!</TH2>
        <p>
          In order to use the SumUps app, you need to confirm your email by
          clicking the link we sent you.
        </p>
        <p>Couldn't find it?</p>
        <Button1 onClick={handleSendConfirmationEmailAgain}>
          send it again
        </Button1>
      </MainCentered>
    );
  }

  const Pagination = () => (
    <Btm>
      <PgBtn
        onClick={handlePreviousPage}
        disabled={currentPage === 0}
        left={true}
      >
        {"<"} Prev
      </PgBtn>
      <p>
        Showing: {currentPage * PROJECTS_PER_PAGE + 1} to{" "}
        {Math.min((currentPage + 1) * PROJECTS_PER_PAGE, projectsLength)} of{" "}
        {projectsLength || "?"}
      </p>
      <PgBtn
        onClick={handleNextPage}
        disabled={projectsPaginated?.pageCount === currentPage + 1}
        left={false}
      >
        Next {">"}
      </PgBtn>
    </Btm>
  );

  return (
    <AuthMainWrapper>
      <Main>
        <PrimaryBtnRow>
          <FiltersSection>
            <Label>Filter:</Label>
            <Filters>
              {ProjTypeFilters.map((filter, index) => {
                return (
                  <Filter
                    clicked={projTypeFilters.clickedFilters[index]}
                    onClick={() => projTypeFilters.handleFilterClick(index)}
                    key={index}
                  >
                    {filter.text}
                  </Filter>
                );
              })}
            </Filters>
            <Filters>
              {selectedProjType &&
                ProjStatusFilters[selectedProjType].map((filter, index) => {
                  return (
                    <Filter
                      clicked={projStatusFilters.clickedFilters[index]}
                      onClick={() => projStatusFilters.handleFilterClick(index)}
                      key={index}
                    >
                      {filter.text}
                    </Filter>
                  );
                })}
            </Filters>
          </FiltersSection>

          <PrimaryBtn
            onClick={() =>
              showModal(
                <NewProjectModal handleCreateProject={handleCreateProject} />
              )
            }
          >
            New Project +
          </PrimaryBtn>
        </PrimaryBtnRow>

        {currentPageSplited && projectsLength > 0 ? (
          <ProjectList>
            {currentPageSplited.map((innerProjectsArray, index) => (
              <ProjectListColumn key={index}>
                {innerProjectsArray.map((project, index2) => {
                  let projectStatusObj = projectStatusHelper(project);
                  return (
                    <ProjectCard
                      key={index2}
                      statusobj={projectStatusObj}
                      to={"/project/" + project.id}
                    >
                      <p className="name">
                        {project.name}
                        <br />
                        {PROJECT_TYPES_MAP[project.projectType]}
                      </p>
                      <Row>
                        <p className="status">{projectStatusObj.label}</p>

                        {project?.loading && <ProjectCardSpinner />}
                      </Row>
                    </ProjectCard>
                  );
                })}
              </ProjectListColumn>
            ))}
          </ProjectList>
        ) : loading ? (
          <EmptyStateContainer>
            <SpinnerLarge />
          </EmptyStateContainer>
        ) : (
          <EmptyStateContainer>
            <EmptyState
              src={require("assets/sumups-projects-empty-state.png")}
            />
            <EmptyStateText>
              Nothing to see yet, your projects will appear here
            </EmptyStateText>
          </EmptyStateContainer>
        )}

        {projectsLength > 0 && <Pagination />}
      </Main>
    </AuthMainWrapper>
  );
};
