import React from "react";
import { getProjectByProjectIdType, useFirestore } from "context/firestore";
import { useAsync, useAsyncReturnType } from "hooks";
import { useParams } from "react-router-dom";
import { Survey } from "./Survey";
import Submission from "./Submission";
import { Transcript } from "./Transcript";

type ProjectAsyncType = useAsyncReturnType<getProjectByProjectIdType, string>;

const Project = () => {
  const { getProjectByProjectId } = useFirestore();
  const { projectid } = useParams<{ projectid: string }>();

  const project: ProjectAsyncType = useAsync(getProjectByProjectId, projectid);

  if (project.loading) return <div>Loading</div>;

  if (project.data?.projectType === "survey") return <Survey />;
  if (project.data?.projectType === "submission") return <Submission />;
  if (project.data?.projectType === "transcript") return <Transcript />;

  return <div>Invalid project.</div>;
};

export default Project;
