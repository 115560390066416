import { Link } from "react-router-dom";
import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  width: calc(100% - 100px);
  height: calc(100vh - 100px);
  padding: 50px;

  @media (max-width: 700px) {
    flex-direction: column;
    margin: auto;
  }
`;

export const OuterLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  // align-items: ;
`;

export const InnerLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
`;

export const Logo = styled.img`
  width: 140px;
  height: 45px;
  object-fit: contain;
`;

export const InnerRight = styled.div`
  flex: 1;
  background-color: #e4fd90;
  display: flex;
  border-radius: 42px;
  // flex-direction: column;
  // align-items: center;

  form {
    display: flex;
    flex-direction: column;
  }
`;

export const LoginDudeImg = styled.img`
  margin: auto auto 0 0;
  max-width: 80%;
  display: block;
  max-height: 90%;
`;

export const RegisterDudeImg = styled.img`
  margin: auto 0 0 auto;
  max-width: 50%;
  max-height: 90%;
  display: block;
`;

export const ForgotPasswordLink = styled(Link)`
  margin-top: -10px;
  margin-bottom: 10px;

  font-size: 0.8rem;
`;
