import styled from "styled-components";

// const handleColorType = (color: string) => {
//     switch (color) {
//       case "blue1":
//         return "#346AF8";
//       case "blue2":
//         return "#4870DE";
//       default:
//         return "#161831";
//     }
// };

export const H1 = styled.h1`
  font-size: 36px;
`;

export const H1Blue = styled.h1`
  color: #346af8;

  span {
    font-weight: 300;
  }
`;

export const H2 = styled.h2``;

export const Label = styled.label`
  font-size: 11px;
  font-weight: 600;
  color: #464646;
  margin: 5px 0;
  margin-left: 3px;
`;

export const Label2 = styled(Label)`
  font-size: 14px;
`;

export const Input = styled.input`
  font-size: 14px;
  border: 1px solid #dadff6;
  border-radius: 4px;
  background-color: white;
  padding: 8px;
  margin-bottom: 10px;
`;

export const Input2 = styled(Input)`
  margin: auto 5px;
`;

export const Button1 = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#d4d4d4" : "#346af8")};
  color: white;
  margin: 10px 0;
  padding: 10px 20px;
  border: 1px solid #d4d4d4;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 1px 4px #33333345;
`;

export const Button2 = styled(Button1)`
  background-color: white;
  color: #346af8;
  border: 1px solid #346af8;
  box-shadow: none;
`;

export const Button3 = styled(Button1)`
  margin: 0;
  border: 1px solid red;
  color: red;
  background-color: white;
  padding: 5px 10px;
  font-size: 15px;
`;

export const Button4 = styled(Button1)`
  margin: 0;
  color: white;
  background-color: red;
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 15px;
`;
