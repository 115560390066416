import React, { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const PROJECT_COLLECTION_NAME = "projects";
const FILE_COLLECTION_NAME = "files";
const CONTENT_COLLECTION_NAME = "contents";
const PROJECT_STATISTICS_COLLECTION_NAME = "tokenUsage";

type TrackingProviderProps = {
  children: React.ReactNode;
};

export type ProjectApiState = {
  started: boolean;
  running: boolean;
  error: boolean;
  finished: boolean;
};

export type FilesOnDb = {
  createdon: number;
  name: string;
  projectId: string;
  url: string;
  path: string;
};

export type TranscriptFileSummaryType = {
  createdon: number;
  name: string;
  projectId: string;
  url: string;
  path: string;
};

export type TranscriptQuestionAnswersType = {
  createdon: number;
  question: string;
  projectId: string;
  answer: string;
  selectedTranscriptIds: string;
};


export const PROJECT_TYPES_MAP = {
  survey: "Survey",
  submission: "Submissions",
  transcript: "Transcript",
};

export type ProjectType = {
  id: string;
  name: string;
  userId: string;
  dataProcessed: boolean;
  loading: boolean;
  projectType: keyof typeof PROJECT_TYPES_MAP;
  reportGenerated: boolean;
  createdon: number;
  tabular?: {
    loadMetadata?: {
      error?: boolean;
      finished?: boolean;
      running?: boolean;
      started?: boolean;
    };
    get_categorical_summaries?: {
      error?: boolean;
      finished?: boolean;
      running?: boolean;
      started?: boolean;
    };
    get_summary_of_columns?: {
      error?: boolean;
      finished?: boolean;
      running?: boolean;
      started?: boolean;
    };
  };

  transcript?: {
    get_summary_of_transcripts?: {
      running?: boolean;
      started?: boolean;
      error?: boolean;
    };
  };
};

type createProjectType = (
  projectName: string,
  projectType: string
) => Promise<void>;
export type getProjectByProjectIdType = (
  projectId: string
) => Promise<ProjectType>;
type listProjectByUserIdType = ({
  userId,
  filterBy,
}: {
  userId: string;
  filterBy?: {
    reportGenerated?: boolean;
    dataProcessed?: boolean;
  };
}) => Promise<ProjectType[]>;
type editProjectType = (
  // id -> Project Id
  id: string,
  data: any
) => Promise<void>;

type ProjectKeywordType = {};

type GeneralSummaryType = {
  projectId: string;
  summary: string;
};

type CategoricalSummaryType = {
  projectId: string;
  keyword: string;
  label: string;
  summary: string;
};

type FileType = {
  name: string;
  project: string;
  url: string;
};

type TableColumnSummaries = {
  fileId: string;
  projectId: string;
  summaries: {
    qualitativeField: string;
    summary: string;
  }[];
};

type TableTabularCategoricalSummaries = {
  fileId: string;
  projectId: string;
  summaries: {
    qualitativeField: string;
    quantitativeField: string;
    summary: string;
    uniqueValue: string;
  }[];
};

type listFilesByProjectIdType = (projectId: string) => Promise<FileType[]>;

type uploadPDFType = (projectId: string, file: any) => Promise<void>;

type getProjectKeywordsType = (
  projectId: string
) => Promise<ProjectKeywordType[]>;

type getProjectGeneralSummaryType = (
  projectId: string
) => Promise<GeneralSummaryType[]>;

type getProjectCategoricalSummariesType = (
  projectId: string
) => Promise<CategoricalSummaryType[]>;

type getReportsByProjectIdType = (projectId: string) => Promise<any[]>;

type getFileContentsByFileIdType = (
  fileId: string,
  projectId: string
) => Promise<any[]>;

type getProjectStatisticsType = (projectId: string) => Promise<any>;

type createProjectQuestionType = (
  projectid: string,
  keywords: string[],
  question: string
) => Promise<any>;

type getProjectQuestionsType = (projectid: string) => Promise<any>;

type getProjectQuestionAnswersType = (projectid: string) => Promise<any>;

type getSurveyQuestionAnsType = (projectid: string) => Promise<any>;

type createProjectKeywordType = (
  projectid: string,
  keywords: string,
  qualitativeFields: string[]
) => Promise<any>;

type createTranscriptKeywordsType = (
  projectid: string,
  keywords: string,
  selectedTranscriptIds: string[]
) => Promise<any>;

type createProjectQuestionAnsType = (
  projectid: string,
  question: string,
  qualitativeFields: string[]
) => Promise<any>;

type createTranscriptQuestionAnsType = (
  projectid: string,
  question: string,
  selectedTranscriptIds: string[]
) => Promise<any>;

type FirestoreState = {
  createProject: createProjectType;
  getProjectByProjectId: getProjectByProjectIdType;
  listProjectByUserId: listProjectByUserIdType;
  editProject: editProjectType;
  listFilesByProjectId: listFilesByProjectIdType;
  uploadPdf: uploadPDFType;
  getProjectKeywords: getProjectKeywordsType;
  getProjectGeneralSummary: getProjectGeneralSummaryType;
  getProjectCategoricalSummaries: getProjectCategoricalSummariesType;
  getReportsByProjectId: getReportsByProjectIdType;
  getFileContentsByFileId: getFileContentsByFileIdType;
  getProjectStatistics: getProjectStatisticsType;
  createProjectQuestion: createProjectQuestionType;
  getProjectQuestions: getProjectQuestionsType;
  getProjectQuestionAnswers: getProjectQuestionAnswersType;
  fetchAllUserFiles: () => Promise<firebase.storage.ListResult>;
  fetchFile: (filename: string) => Promise<void>;
  createProjectKeywords: createProjectKeywordType;
  createProjectQuestionAns: createProjectQuestionAnsType;
  createTranscriptQuestionAns: createTranscriptQuestionAnsType;
  createTranscriptKeywords:createTranscriptKeywordsType;
  getProjectTabularCategoricalSummaries: (projectId: string) => Promise<{
    id: string;
    data: TableTabularCategoricalSummaries;
  }>;
  getTableColumnSummaries: (projectId: string) => Promise<{
    id: string;
    data: TableColumnSummaries;
  }>;
  getSurveyKeywords: (projectId: string) => Promise<{
    id: string;
    data: any;
  }>;
  getTranscriptKeywords: (projectId: string) => Promise<any[]>;
  getSurveyQuestionAnsList: getSurveyQuestionAnsType;
  db: firebase.firestore.Firestore;
};

const FirestoreContext = createContext<FirestoreState | undefined>(undefined);

function FirestoreProvider({ children }: TrackingProviderProps) {
  const db = firebase.firestore();
  // const storage = firebase.storage();
  const auth = firebase.auth();
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const [user, setUser] = useState<firebase.User | null>(null);
  // const [isFetchingUser, setIsFetchingUser] = useState(true);

  auth.onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }

    // setIsFetchingUser(false);
  });

  const createProject = (projectName: string, projectType: string) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let extra = {};
        if (projectType === "survey") {
          const o = {
            error: false,
            finished: false,
            running: false,
            started: false,
          };
          extra = {
            ...extra,
            tabular: {
              loadMetadata: o,
              get_categorical_summaries: o,
              get_summary_of_columns: o,
            },
          };
        }
        if (user) {
          await db.collection(PROJECT_COLLECTION_NAME).add({
            userId: user.uid,
            name: projectName,
            createdon: Date.now(),
            reportGenerated: false,
            dataProcessed: false,
            projectType,
            ...extra,
          });
          // alert("Project added successfully");
          resolve();
        } else {
          reject("User not found");
        }
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const fetchAllUserFiles = () => {
    return new Promise<firebase.storage.ListResult>((resolve, reject) => {
      if (!user) return reject();

      const userFolderRef = storageRef.child(`users/${user.uid}`);
      userFolderRef
        .listAll()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchFile = (filename: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!user) return reject();
      const userFolderRef = storageRef.child(`users/${user.uid}`);
      const fileRef = userFolderRef.child(filename);

      fileRef
        .getDownloadURL()
        .then((url) => {
          const fileLink = document.createElement("a");
          fileLink.href = url;
          fileLink.download = "filename.jpg";
          fileLink.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const editProject = (projectId: string, data: any) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (user) {
          await db
            .collection(PROJECT_COLLECTION_NAME)
            .doc(projectId)
            .update(data);
          alert("Successfully updated project");
          resolve();
        } else {
          reject("User not found");
        }
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const uploadPdf = async (projectId: string, files: FileList) => {
    if (!user) return;
    try {
      var storageRef = firebase.storage().ref();
      let fileUploads = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        var fileRef = storageRef
          .child(`users/${user.uid}/${projectId}/${crypto.randomUUID()}`)
          .put(file);
        fileUploads.push(fileRef);
      }
      const fileURLReqs = (await Promise.all(fileUploads)).map(async (f) => ({
        url: await f.ref.getDownloadURL(),
        path: f.ref.fullPath,
      }));
      const filesData = await Promise.all(fileURLReqs);
      const filesCol = db.collection(FILE_COLLECTION_NAME);
      let updates = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileAdd = filesCol.add({
          name: file.name,
          url: filesData[i].url,
          path: filesData[i].path,
          projectId,
          createdon: Date.now(),
        });
        updates.push(fileAdd);
      }
      await Promise.all(updates);
    } catch (err) {
      console.log(err);
    }
  };

  const listFilesByProjectId = (projectId: string) => {
    return new Promise<FileType[]>(async (resolve, reject) => {
      try {
        const result: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> =
          await db
            .collection(FILE_COLLECTION_NAME)
            .where("projectId", "==", projectId)
            .get();

        let files: FileType[] = [];

        result.forEach((doc: any) => {
          const file = doc.data();
          file.id = doc.id;
          file.createdon = file.createdon ? file.createdon : 0;
          files.push(file);
        });

        files.sort(
          (a: any, b: any) => Number(b.createdon) - Number(a.createdon)
        );

        resolve(files);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const listProjectByUserId: listProjectByUserIdType = ({
    userId,
    filterBy,
  }) => {
    return new Promise<ProjectType[]>(async (resolve, reject) => {
      try {
        let query = db
          .collection(PROJECT_COLLECTION_NAME)
          .where("userId", "==", userId);

        if (filterBy) {
          Object.entries(filterBy).forEach(([key, filter]) => {
            query = query.where(key, "==", filter);
          });
        }

        const result = await query.get();

        let projects: ProjectType[] = [];

        result.forEach((doc: any) => {
          const project = doc.data();
          project.id = doc.id;
          project.createdon = project.createdon ? project.createdon : 0;
          projects.push(project);
        });

        projects.sort(
          (a: any, b: any) => Number(b.createdon) - Number(a.createdon)
        );

        resolve(projects);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getProjectKeywords = (projectId: string) => {
    return new Promise<ProjectKeywordType[]>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection("keywords")
          .where("projectId", "==", projectId)
          .get();

        const projectKeywords: ProjectKeywordType[] = [];
        result.forEach((doc: any) => {
          const projectKeyword = doc.data();
          projectKeyword.id = doc.id;
          projectKeywords.push(projectKeyword);
        });

        projectKeywords.sort(
          (a: any, b: any) => Number(b.count) - Number(a.count)
        );

        resolve(projectKeywords);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getProjectGeneralSummary = (projectId: string) => {
    return new Promise<GeneralSummaryType[]>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection("generalSummaries")
          .where("projectId", "==", projectId)
          .get();

        const generalSummaries: GeneralSummaryType[] = [];
        result.forEach((doc: any) => {
          const generalSummary = doc.data();
          generalSummary.id = doc.id;
          generalSummaries.push(generalSummary);
        });

        resolve(generalSummaries);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getProjectCategoricalSummaries = (projectId: string) => {
    return new Promise<CategoricalSummaryType[]>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection("categoricalSummaries")
          .where("projectId", "==", projectId)
          .get();

        const categoricalSummaries: CategoricalSummaryType[] = [];
        result.forEach((doc: any) => {
          const categoricalSummary = doc.data();
          categoricalSummary.id = doc.id;
          categoricalSummaries.push(categoricalSummary);
        });

        resolve(categoricalSummaries);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getProjectByProjectId = (projectId: string) => {
    return new Promise<ProjectType>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection(PROJECT_COLLECTION_NAME)
          .doc(projectId)
          .get()
          .then((snap) => {
            if (snap.exists) {
              const projectData = snap.data();
              return projectData;
            }
          });

        resolve({ ...result, id: projectId });
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getReportsByProjectId = (projectId: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection("reports")
          .where("projectId", "==", projectId)
          .get();

        let reports: any[] = [];
        result.forEach((doc: any) => {
          const report = doc.data();
          report.id = doc.id;
          reports.push(report);
        });

        resolve(reports);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getFileContentsByFileId = (fileId: string, projectId: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection(CONTENT_COLLECTION_NAME)
          .where("projectId", "==", projectId)
          .where("fileId", "==", fileId)
          .get();

        let contents: any[] = [];
        result.forEach((doc: any) => {
          const content = doc.data();
          content.id = doc.id;
          contents.push(content);
        });

        resolve(contents);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getProjectStatistics = (projectId: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection(PROJECT_STATISTICS_COLLECTION_NAME)
          .where("projectId", "==", projectId)
          .get();

        let projectStatistics: any[] = [];

        result.forEach((doc: any) => {
          const projectStatistic = doc.data();
          projectStatistic.id = doc.id;
          projectStatistics.push(projectStatistic);
        });

        resolve(projectStatistics);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const createProjectQuestion = (
    projectId: string,
    keywords: string[],
    question: string
  ) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db.collection("questions").add({
          projectId,
          keywords,
          question,
          createdon: Date.now(),
        });

        resolve(result);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getProjectQuestions = (projectId: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection("questions")
          .where("projectId", "==", projectId)
          .get();

        let projectQuestions: any[] = [];

        result.forEach((doc: any) => {
          const projectQuestion = doc.data();
          projectQuestion.id = doc.id;
          projectQuestions.push(projectQuestion);
        });

        resolve(projectQuestions);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getProjectQuestionAnswers = (projectId: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection("questionAnswers")
          .where("projectId", "==", projectId)
          .get();

        let projectQuestionAnswers: any[] = [];

        result.forEach((doc: any) => {
          const projectQuestionAnswer = doc.data();
          projectQuestionAnswer.id = doc.id;
          projectQuestionAnswers.push(projectQuestionAnswer);
        });

        resolve(projectQuestionAnswers);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };

  const getProjectTabularCategoricalSummaries = (projectId: string) =>
    new Promise<{
      id: string;
      data: TableTabularCategoricalSummaries;
    }>(async (resolve, reject) => {
      const result = await db
        .collection("tableCategoricalSummaries")
        .where("projectId", "==", projectId)
        .limit(1)
        .get();
      result.forEach((doc) => {
        resolve({
          id: doc.id,
          data: doc.data() as TableTabularCategoricalSummaries,
        });
      });
    });

  const getTableColumnSummaries = (projectId: string) =>
    new Promise<{
      id: string;
      data: TableColumnSummaries;
    }>(async (resolve, reject) => {
      const result = await db
        .collection("tableColumnSummaries")
        .where("projectId", "==", projectId)
        .limit(1)
        .get();
      result.forEach((doc) => {
        resolve({
          id: doc.id,
          data: doc.data() as TableColumnSummaries,
        });
      });
    });

  const getSurveyKeywords = (projectId: string) =>
    new Promise<{
      id: string;
      data: TableColumnSummaries;
    }>(async (resolve, reject) => {
      const result = await db
        .collection("tableKeywordSummary")
        .where("projectId", "==", projectId)
        .limit(1)
        .get();
      result.forEach((doc) => {
        resolve({
          id: doc.id,
          data: doc.data() as TableColumnSummaries,
        });
      });
    });

  const createProjectKeywords = (projectId: string,keyword: string, qualitativeFields: string[]) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db.collection("tableKeywordSummary").add({
          projectId,
          keyword,
          selectedColumns:qualitativeFields,
          createdOn: Date.now(),
        });

        resolve(result);
      } catch (err: any) {
        console.log("err",err.message)
        reject(err.message);
      }
    });
  }

  const getTranscriptKeywords = (projectId: string) =>
  new Promise<{
    id: string;
    data: any;
  }[]>(async (resolve, reject) => {
    const result = await db
      .collection("transcriptKeywordSummary")
      .where("projectId", "==", projectId)
      .get();
    let summary: any[] = [];

    result.forEach((doc: any) => {
      const keywordData = doc.data();
      keywordData.id = doc.id;
      summary.push(keywordData);
    });
    resolve(summary);
  });

const createTranscriptKeywords = (projectId: string,keyword: string, qualitativeFields: string[]) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const result: any = await db.collection("transcriptKeywordSummary").add({
        projectId,
        keyword,
        selectedTranscriptIds:qualitativeFields,
        createdOn: Date.now(),
      });

      resolve(result);
    } catch (err: any) {
      console.log("err",err.message)
      reject(err.message);
    }
  });
}


  const createProjectQuestionAns = (projectId: string,question: string, qualitativeFields: string[]) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db.collection("tableQuestionAnswers").add({
          projectId,
          question,
          selectedColumns:qualitativeFields,
          createdOn: Date.now(),
        });

        resolve(result);
      } catch (err: any) {
        console.log("err",err.message)
        reject(err.message);
      }
    });
  }

  const createTranscriptQuestionAns = (projectId: string,question: string, qualitativeFields: string[]) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db.collection("transcriptQuestionAnswers").add({
          projectId,
          question,
          selectedTranscriptIds:qualitativeFields,
          createdOn: Date.now(),
        });

        resolve(result);
      } catch (err: any) {
        console.log("err",err.message)
        reject(err.message);
      }
    });
  }

  const getSurveyQuestionAnsList = (projectId: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const result: any = await db
          .collection("tableQuestionAnswers")
          .where("projectId", "==", projectId)
          .get();

        let projectQuestionAnswers: any[] = [];

        result.forEach((doc: any) => {
          const projectQuestionAnswer = doc.data();
          projectQuestionAnswer.id = doc.id;
          projectQuestionAnswers.push(projectQuestionAnswer);
        });

        resolve(projectQuestionAnswers);
      } catch (err: any) {
        reject(err.message);
      }
    });
  };
  return (
    <FirestoreContext.Provider
      value={{
        createProject,
        editProject,
        getProjectByProjectId,
        listProjectByUserId,
        listFilesByProjectId,
        uploadPdf,
        getProjectKeywords,
        getProjectGeneralSummary,
        getProjectCategoricalSummaries,
        getReportsByProjectId,
        getFileContentsByFileId,
        getProjectStatistics,
        createProjectQuestion,
        getProjectQuestions,
        getProjectQuestionAnswers,
        fetchFile,
        fetchAllUserFiles,
        getProjectTabularCategoricalSummaries,
        getTableColumnSummaries,
        getSurveyKeywords,
        createProjectKeywords,
        createProjectQuestionAns,
        createTranscriptQuestionAns,
        getSurveyQuestionAnsList,
        getTranscriptKeywords,
        createTranscriptKeywords,
        db,
      }}
    >
      {children}
    </FirestoreContext.Provider>
  );
}

function useFirestore() {
  const context = useContext(FirestoreContext);

  if (context === undefined) {
    throw new Error("useFirestore must be used within a FirestoreProvider");
  }

  return context;
}

export { FirestoreProvider, useFirestore };
