import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Row,
  ReportContainer,
  ReportInner,
  R1,
  P1,
  P2,
} from "../projectComponents";
import { H1 } from "components/CommonComponents";

const TranscriptResults: FC<{
  project?: any;
  projectFiles?: any[];
  fileSummaries?: any[];
  generalSummaries?: any[];
}> = ({ project, projectFiles, fileSummaries, generalSummaries }) => {
  console.log(project);
  console.log(projectFiles);
  console.log(fileSummaries);

  const [localProjectFiles, setLocalProjectFiles] = useState<any[]>([]);
  const [generalSummary, setGeneralSummary] = useState<string | null>(null)
  useEffect(() => {
    let localProjectFilesBuilder: any[] = [];
    if (projectFiles) {
      for (var i = 0; i < projectFiles.length; i++) {
        console.log(projectFiles[i].data());
        const currentFile = projectFiles[i].data();
        currentFile.fileId = projectFiles[i].id;
        localProjectFilesBuilder.push(currentFile);
      }
    }
    if (fileSummaries) {
      for (var i = 0; i < fileSummaries.length; i++) {
        console.log(fileSummaries[i].data());
        for (var j = 0; j < localProjectFilesBuilder.length; j++) {
          if (
            fileSummaries[i].data().fileId == localProjectFilesBuilder[j].fileId
          ) {
            localProjectFilesBuilder[j].summary =
              fileSummaries[i].data().summary;
          }
        }
      }
    }
    if (generalSummaries && generalSummaries.length > 0) {
        console.log(generalSummaries[0].data());
        const _summary = generalSummaries[0].data().summary;
        setGeneralSummary(_summary)
      }
    console.log(localProjectFilesBuilder);
    setLocalProjectFiles(localProjectFilesBuilder);
  }, [project, projectFiles, fileSummaries]);

  const filenameWithoutExtension = (name:string) => name.split('.')[0]
  return (
    <ReportContainer>
      <H1>{project?.data?.name}</H1>

      <React.Fragment>
          <br />
          <R1>General Summary</R1>

          <ReportInner>
            <p>{generalSummary}</p>
          </ReportInner>
        </React.Fragment>

        <H1> Summary by Transcript:</H1>
      {localProjectFiles.map((file, index) => (
        <React.Fragment key={index}>
          <br />
          <R1>{filenameWithoutExtension(file?.name)}</R1>

          <ReportInner>
            <p>{file?.summary}</p>
          </ReportInner>
        </React.Fragment>
      ))}


    </ReportContainer>
  );
};

export default TranscriptResults;
