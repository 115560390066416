import React, { useState } from "react";
import styled from "styled-components";

import AuthMainWrapper from "../../components/AuthMainWrapper";
import { Input, Textarea, PrimaryBtn } from "../../components/ThemeComponents";
import Spinner from "../../components/Spinner";
import { H1 } from "../../components/CommonComponents";
import DataProcessService from "../../services/DataProcess";

export const Support = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [body, setBody] = useState<string>();

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleBody = (e: any) => {
    setBody(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(email, body);
    await DataProcessService.sendSlackMsg({
      msg: "New support request from " + email + " : " + body,
    });
    alert(
      "Your message has been sent. We will get back to you as soon as possible."
    );
    setEmail("");
    setBody("");
    setLoading(false);
  };

  return (
    <AuthMainWrapper>
      <Main>
        <H1>What can we help you with?</H1>
        <InputStyled placeholder="Email" value={email} onChange={handleEmail} />
        <TextareaStyled
          placeholder="Write your message here"
          value={body}
          onChange={handleBody}
        />
        {loading ? (
          <Spinner />
        ) : (
          <PrimaryBtnStyled onClick={handleSubmit}>Submit</PrimaryBtnStyled>
        )}
      </Main>
    </AuthMainWrapper>
  );
};

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const InputStyled = styled(Input)`
  max-width: initial;
`;

const TextareaStyled = styled(Textarea)`
  max-width: initial;
  padding: 15px 20px;
`;

const PrimaryBtnStyled = styled(PrimaryBtn)`
  max-width: 200px;
  margin: 0 auto;
`;
