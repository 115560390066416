import React, { FC } from "react";
import { TH2, TS1 } from "./ThemeComponents";

const AlertModal: FC<{ title?: string }> = ({ title = "Error", children }) => (
  <div>
    <TH2>{title}</TH2>
    <TS1>{children}</TS1>
  </div>
);

export default AlertModal;
