import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useFirestore } from "context/firestore";
import {
  OverlayBackground,
  OverlayInner,
} from "components/ThemeComponents";
import { Row1 } from "../projectComponents";
import { H1 } from "components/CommonComponents";

const FileContentsComponent = ({ file, closeFileContentsComponent }) => {
  const [fileContents, setFileContents] = useState([]);
  // const [loading, setLoading] = useState(false);
  const { getFileContentsByFileId } = useFirestore();

  const getFileContents = async (file) => {
    let contents = await getFileContentsByFileId(file.id, file.projectId);
    sortArray(contents);

    let fileContentsState = [];
    for (var i = 0; i < contents.length; i++) {
      fileContentsState.push(contents[i]);
    }
    setFileContents(fileContentsState);
  };

  useEffect(() => {
    getFileContents(file);
    // eslint-disable-next-line
  }, []);

  return (
    <OverlayBackground>
      <OverlayInner>
        <Row1>
          <H1>Text extracted from {file.name}</H1>

          <CloseBtn
            onClick={() => closeFileContentsComponent(false)}
            src={require("assets/overlay-close.png")}
          />
        </Row1>
        <Main>
          {fileContents.map((content, id) => {
            if (
              content.text &&
              content.text !== "" &&
              content.text.length > 5
            ) {
              return (
                <Content key={id}>
                  <p className="content-heading">
                    #{id + 1} | Page: {content.pageNumber}
                    {content.heading &&
                      content.heading.length > 3 &&
                      ` | Section Heading: ${content.heading}`}
                  </p>
                  <p>{content.text}</p>
                </Content>
              );
            } else {
              return null;
            }
          })}
        </Main>
      </OverlayInner>
    </OverlayBackground>
  );
};

export default FileContentsComponent;

const CloseBtn = styled.img`
  width: 30px;
  height: 30px;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

// In this function take an array of objects and sort them by the order property
const sortArray = (array) => {
  array.sort((a, b) => {
    return a.order - b.order;
  });
};

const Content = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  flex: 1;

  .content-heading {
    margin-bottom: 25px;
    font-size: 22px;
    font-weight: 600;
  }

  p {
    margin: 0;
    font-size: 18px;
  }
`;
