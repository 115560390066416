import React, { useEffect } from "react";
import { OverlayBackground, OverlayInner } from "../components/ThemeComponents";

interface ModalProps {
  children: React.ReactNode;
}

const HIDE_MODAL = "HIDE_MODAL";

const hideModalEvent = new Event(HIDE_MODAL);

const Modal: React.FC<ModalProps> = ({ children }) => {
  const hideModal = useHideModal();
  return (
    <OverlayBackground onClick={hideModal}>
      <OverlayInner onClick={(e) => e.stopPropagation()}>
        {children}
      </OverlayInner>
    </OverlayBackground>
  );
};

// Modal context
interface ModalContextType {
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
}

const ModalContext = React.createContext<ModalContextType>({
  showModal: () => {},
  hideModal: () => {},
});

// Modal context provider
interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalQueue, setModalQueue] = React.useState<React.ReactNode[]>([]);

  const showModal = (content: React.ReactNode) => {
    setModalQueue([...modalQueue, content]);
  };

  const hideModal = () => {
    setModalQueue(modalQueue.slice(1));
  };

  const activeModal = modalQueue[0] || null;

  useEffect(() => {
    document.addEventListener(HIDE_MODAL, hideModal);

    return () => document.removeEventListener(HIDE_MODAL, hideModal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {activeModal && <Modal>{activeModal}</Modal>}
    </ModalContext.Provider>
  );
};

// Hook to add modals to the queue
export const useShowModal = (): ((content: React.ReactNode) => void) => {
  const { showModal } = React.useContext(ModalContext);

  return showModal;
};

// Hook to remove modals from the queue
export const useHideModal = (): (() => void) => {
  const { hideModal } = React.useContext(ModalContext);

  return hideModal;
};

export const triggerHideModal = () => {
  document.dispatchEvent(hideModalEvent);
};
