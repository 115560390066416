import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const MainCentered = styled(Main)`
  align-items: center;
`;

export const PrimaryBtnRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
`;

export const Filters = styled.div`
  display: flex;
`;

export const selectedFilter = css`
  border: 2px solid red;
`;

export const Filter = styled.p<{ clicked?: boolean }>`
  margin: 0px;
  margin-right: 6px;
  font-size: 11px;
  border: 1px solid grey;
  padding: 3px 6px;
  border-radius: 7px;
  cursor: pointer;
  transition: background-color 0.7s ease-in-out;

  ${(p) => (p.clicked ? selectedFilter : "")};

  :hover {
    background-color: #f1f1f1;
  }
`;

export const EmptyStateContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyState = styled.img`
  width: 80%;
  max-width: 300px;
`;

export const EmptyStateText = styled.p`
  text-align: center;
  max-width: 270px;
  color: #c1c1c1;
`;

export const Btm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
`;

// export const ProjectCard = styled(Link)<{ statusobj: any }>`
export const PgBtn = styled.button<{ left: any }>`
  margin-left: ${({ left }) => (left ? "0" : "10px")};
  margin-right: ${({ left }) => (left ? "10px" : "0")};
  font-size: 12px;

  background-color: ${({ disabled }) => (disabled ? "#f1f1f1" : "#fff")};
  color: ${({ disabled }) => (disabled ? "#c1c1c1" : "#000")};
  border-radius: 10px;
  border: 1px solid ${({ disabled }) => (disabled ? "#f1f1f1" : "#000")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

export const ProjectList = styled.div`
  display: flex;
  flex: 1;
`;

export const ProjectListColumn = styled.div`
  flex: 1;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const ProjectCard = styled(Link)<{ statusobj: any }>`
  text-decoration: none;
  cursor: pointer;
  background-color: ${({ statusobj }) =>
    statusobj.bgColor ? statusobj.bgColor : "#F3F3F3"};
  flex: 1;
  margin: 5px 0;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid
    ${({ statusobj }) => (statusobj.bgColor ? statusobj.bgColor : "#F3F3F3")};
  transition: border ease-in-out 0.4s;

  p {
    color: ${({ statusobj }) =>
      statusobj.textColor ? statusobj.textColor : "#F3F3F3"};
    margin: 0;
    font-family: "Spline Sans", sans-serif;
  }

  .name {
    font-weight: 600;
    font-size: 16px;
  }

  .status {
    font-size: 11px;
  }

  :hover {
    border: 1px solid
      ${({ statusobj }) =>
        statusobj.textColor ? statusobj.textColor : "#F3F3F3"};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

export const ProjectTypeSelect = styled.select``;
