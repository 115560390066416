import styled from "styled-components";
import { Input, PrimaryBtn } from "components/ThemeComponents";
import { Row4 } from "../projectComponents";

export const PrimaryBtnStyled = styled(PrimaryBtn)`
  margin-right: 30px;
`;

export const TypeSelectorBtn = styled.div<{ selected: boolean }>`
  border: 2px solid black;
  border-radius: 8px;
  padding: 50px 40px;
  flex: 1;
  margin: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: ${({ selected }) => (selected ? "black" : "white")};

  p {
    color: ${({ selected }) => (selected ? "white" : "black")};
  }

  :hover {
    background-color: ${({ selected }) => (selected ? "#333" : "#eee")};
  }
`;

export const TypeSelectorBtnCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 20px;
  border: 2px solid black;
  margin-left: auto;
  margin-top: 20px;
  background-color: white;
`;

export const DataHeaderDiv = styled.div<{
  selectable: boolean;
  selected: boolean;
  qualitative: boolean;
}>`
  border: 1px solid ${({ selectable }) => (selectable ? "black" : "#eee")};
  border-radius: 8px;
  padding: 8px 11px;
  margin-bottom: 15px;
  display: flex;
  cursor: ${({ selected, selectable }) =>
    selected ? "pointer" : selectable ? "pointer" : ""};
  transition: all 0.2s ease-in-out;
  background-color: ${({ selected, selectable }) =>
    selected ? "black" : selectable ? "white" : "#F3F3F3"};

  :hover {
    // background-color: #eee;
    background-color: ${({ selected, selectable }) =>
      selected ? "black" : selectable ? "#eee" : "#F3F3F3"};
  }

  .headername {
    font-weight: bold;
    margin: 0;
    font-size: 17px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }

  .columndatatype {
    background-color: ${({ qualitative }) =>
      qualitative ? "green" : "maroon"};
    color: white;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 5px;
    font-weight: 500;
    margin-left: 10px;
  }

  .exampletext {
    font-weight: light;
    margin: 0;
    margin-top: 5px;
    font-size: 13px;
    color: ${({ selected }) => (selected ? "grey" : "#444")};
  }

  .data-header-right {
    margin: auto 10px;
  }

  .data-header-right-check-icon {
    display: ${({ selected, selectable }) => (selected ? "block" : "none")};
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 20px;
  }

  .data-header-right-circle {
    display: ${({ selected, selectable }) =>
      selected ? "none" : selectable ? "block" : "none"};
    width: 15px;
    height: 15px;
    border-radius: 20px;
    border: 1px solid black;
    background-color: white;
  }
`;

export const DataHeaderLeft = styled.div`
  flex: 1;
`;

export const DataHeaderCircleIcon = styled.div`
  margin: auto 10px;
  border: 1px solid black;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background-color: white;
`;

export const SurveyReportQuestion = styled.div`
  flex: 1;
  margin: 10px;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SurveyStratificationRow = styled.div`
  display: flex;
  width: 100%;

  .survey-row-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }
`;

export const WhiteInput = styled(Input)`
  background:white;
  width:100%;
  padding: 12px 20px;
  max-width: 500px;


`

export const Row4Flex = styled(Row4)`
  align-items:start;
  justify-content:start
`