import React, { FC } from "react";
import styled from "styled-components";
import { Elements } from "@stripe/react-stripe-js";

import SaveCreditCardForm from "./SaveCreditCardForm";
import { useShowModal } from "../../context/Modal";
import { stripePromise } from "./stripe";

const SaveCreditCardModal = () => (
  <Elements
    stripe={stripePromise}
    options={{
      mode: "setup",
      currency: "usd",
    }}
  >
    <SaveCreditCardForm />
  </Elements>
);

const CreditCards: FC<{ cards: Array<any> }> = ({ cards }) => {
  const showModal = useShowModal();
  console.log(cards);

  return (
    <>
      <P1>Card Details</P1>
      <P2>Select default payment method</P2>
      <Cards>
        {cards.map(({ card }, i) => (
          <Card key={i} first={i === 0}>
            <CardRow>
              <Garbage src={require("../../assets/garbage-2.png")} />

              {card.brand === "mastercard" ? (
                <CardLogo src={require("../../assets/mastercard.png")} />
              ) : card.brand === "visa" ? (
                <CardLogo src={require("../../assets/visa.png")} />
              ) : card.brand === "amex" ? (
                <CardLogo src={require("../../assets/amex.png")} />
              ) : null}
            </CardRow>

            <CardNumber>
              <CardNumberSection>XXXX</CardNumberSection>
              <CardNumberSection>XXXX</CardNumberSection>
              <CardNumberSection>XXXX</CardNumberSection>
              <CardNumberSection>{card.last4}</CardNumberSection>
            </CardNumber>
            {/* {card.brand} - {card.last4}{" "} */}
          </Card>
        ))}
        <EmptyCard onClick={() => showModal(<SaveCreditCardModal />)}>
          <p className="plus">+</p>
          <p className="text">Add a card</p>
        </EmptyCard>
      </Cards>
      {/* <button onClick={() => showModal(<SaveCreditCardModal />)}>add</button> */}
    </>
  );
};

export default CreditCards;

const P1 = styled.h3`
  margin-bottom: 15px;
`;

const P2 = styled.p`
  margin-top: 0;
  margin-bottom: 30px;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.div<{ first: boolean }>`
  background: ${({ first }) =>
    first
      ? `linear-gradient(
    143.38deg,
    rgba(199, 209, 245, 0.5) 21.22%,
    rgba(116, 146, 235, 0.5) 92.37%
  )`
      : `rgba(193, 193, 193, 0.5)`};
  border-radius: 15px;

  min-width: 200px;
  height: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const CardRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Garbage = styled.img`
  width: 15px;
  height: 20px;
  object-fit: contain;
`;

const CardLogo = styled.img`
  width: 30px;
  height: 21px;
  object-fit: contain;
`;

const CardNumber = styled.div`
  display: flex;
`;

const CardNumberSection = styled.p`
  margin: 0 5px;
`;

const EmptyCard = styled.div`
  width: 200px;
  height: 100px;
  padding: 20px;
  border: 2px dashed #c1c1c1;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c1c1c1;
  cursor: pointer;

  .plus {
    font-size: 44px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .text {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;
