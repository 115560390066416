import { useAuthentication } from "context/authentication";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import dataProcesss from "services/DataProcess";
import styled, { keyframes } from "styled-components";
import { Row4 } from "../projectComponents";

const Logo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 5px;
`;

const OuterDiv = styled.div`
  margin-top: 50px;
  padding-bottom: 150px;
  display: flex;
`;

const ContactColumn = styled.div`
  flex: 0 0 33%;
  max-width: 33%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const ChatColumn = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  min-width: 540px;
  position: relative;
  width: 100%;
  border-radius: 3rem;
  border: 2px solid black;
`;
const UserLogo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #4e6266;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

const MessageContainer = styled.div<{ user?: any }>`
  display: flex;
  flex-direction: ${({ user }) => (user === "bot" ? "row" : "row-reverse")};
  align-items: start;
  margin: 0px 10px;
  justify-items: ${({ user }) => (user === "bot" ? "start" : "end")};
`;

const MessageListColumn = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow: scroll;
  padding-top: 3rem;
  background-color: #f3f3f3;
  box-sizing: border-box;
  border: 1px solid #dee2e6;
  height: 400px;
  border-radius: 3rem 3rem 0rem 0rem;
  padding-bottom: 15px;
`;

const Message = styled.div`
  padding: 1rem;
  margin: 0.25rem;
  float: left;
  background: #f4f7f9;
  border-radius: 2rem;
  border: 1px black solid;
  max-width: 600px;
`;

const MessageListHeader = styled.div`
  padding: 1rem 0rem !important;
  border-bottom: 2px solid black;
`;

const MessageListTitle = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
`;
const MessageForm = styled.form`
  max-width: 100%;
  padding: 20px;
  margin: 0;
  background: #e5e5e5;
  border-radius: 0rem 0rem 3rem 3rem;
`;
const FlexBox = styled.div`
  display: flex;
  gap: 4px;
`;

const Col9 = styled.div`
  width: 85%;
`;

const Col6 = styled.div`
  width: 50%;
`;
const Col3 = styled.div`
  width: 15%;
  display: flex;
  align-items: flex-end;
  justify-content: end;
`;

const MessageInput = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 2rem;
`;

const MessageButton = styled.button<{ disabled: boolean }>`
  color: #fff;
  background-color: ${({ disabled }) => (disabled ? "#758F95" : "black")};
  padding: 16px 32px;
  font-size: 1rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border-radius: 3rem;
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
`;

export const DataHeaderDiv = styled.div<{
  selectable: boolean;
  selected: boolean;
  qualitative: boolean;
}>`
  border: 1px solid ${({ selectable }) => (selectable ? "black" : "#eee")};
  border-radius: 8px;
  padding: 8px 11px;
  margin-bottom: 15px;
  display: flex;
  cursor: ${({ selected, selectable }) =>
    selected ? "pointer" : selectable ? "pointer" : ""};
  transition: all 0.2s ease-in-out;
  background-color: ${({ selected, selectable }) =>
    selected ? "black" : selectable ? "white" : "#F3F3F3"};

  :hover {
    // background-color: #eee;
    background-color: ${({ selected, selectable }) =>
      selected ? "black" : selectable ? "#eee" : "#F3F3F3"};
  }

  .headername {
    font-weight: bold;
    margin: 0;
    font-size: 17px;
    color: ${({ selected }) => (selected ? "white" : "black")};
  }

  .columndatatype {
    background-color: ${({ qualitative }) =>
      qualitative ? "green" : "maroon"};
    color: white;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 5px;
    font-weight: 500;
    margin-left: 10px;
  }

  .exampletext {
    font-weight: light;
    margin: 0;
    margin-top: 5px;
    font-size: 13px;
    color: ${({ selected }) => (selected ? "grey" : "#444")};
  }

  .data-header-right {
    margin: auto 10px;
  }

  .data-header-right-check-icon {
    display: ${({ selected, selectable }) => (selected ? "block" : "none")};
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 20px;
  }

  .data-header-right-circle {
    display: ${({ selected, selectable }) =>
      selected ? "none" : selectable ? "block" : "none"};
    width: 15px;
    height: 15px;
    border-radius: 20px;
    border: 1px solid black;
    background-color: white;
  }
`;

export const DataHeaderLeft = styled.div`
  flex: 1;
`;

export const DataHeaderCircleIcon = styled.div`
  margin: auto 10px;
  border: 1px solid black;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background-color: white;
`;
const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;

const Dot = styled.div<{ delay: any }>`
  background-color: #757575;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px; /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${(props) => props.delay};
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
`;
export const LoadingDots = () => (
  <DotWrapper>
    <Dot delay="0s" />
    <Dot delay=".1s" />
    <Dot delay=".2s" />
  </DotWrapper>
);

const getFirstLettersFromEmail = (email: any) => {
  return email?.slice(0, 2)?.toUpperCase();
};

function ChatBox({
  tableMetadata,
  setQuestionAnsData,
  questionAnsData,
  createSurveyQuestionAns,
  getSurveyQuestionAns,
  projectid,
  tabularFields,
}: any) {
  type Message = {
    user?: string;
    text?: string;
  };
  const { user: userData } = useAuthentication();

  const [message, setMessage] = useState<Message>();
  const _staticmsgList = [
    { user: "bot", text: "Hi, welcome to SumUps Q&A Bot !" },
    {
      user: "bot",
      text: "Ask any questions related to your data, enter a question and select any qualitative columns to answer the question.",
    },
  ];
  const [messageList, setMessageList] = useState<Message[]>(_staticmsgList);
  const getMsgList = async () => {
    const data = await getSurveyQuestionAns(projectid);
    const _data = data.sort(function (a: any, b: any) {
      return a.createdOn - b.createdOn;
    });
    let _newMsgList = _staticmsgList;
    _data.map((i: any) => {
      _newMsgList.push({
        user: "user",
        text: i.question,
      });
      if (i.answer) {
        _newMsgList.push({
          user: "bot",
          text: i.answer,
        });
      }
    });
    setMessageList(_newMsgList);
    setMessage({
      user: "user",
      text: "",
    });
  };

  useEffect(() => {
    getMsgList();
  }, [projectid, tabularFields]);

  const [isLoading, setLoading] = useState(false);
  const submitForm = async (event: any) => {
    try {
      setLoading(true);
      event.preventDefault();
      if (!message) throw new Error("Empty message");
      const newMessageList = [...messageList, message];
      setMessageList(newMessageList);
      const _text = message.text;
      setMessage({
        user: "user",
        text: "",
      });
      const _newQuestionAns = await createSurveyQuestionAns(
        projectid,
        _text,
        questionAnsData.qualitativeFields.map((i: any) => i.value)
      );
      const _id = _newQuestionAns.f_.path.segments[1];
      await dataProcesss.tabular.get_question_ans({
        projectId: projectid,
        question_id: _id,
      });
      setQuestionAnsData({
        ...questionAnsData,
        qualitativeFields: [],
      });
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const updateMessage = (text: string) => {
    setMessage({
      user: "user",
      text,
    });
  };

  const selectColumnHeaderHelperQuestion = (data: any) => {
    setQuestionAnsData({
      ...questionAnsData,
      qualitativeFields: data,
    });
  };

  const MAX_MESSAGES_ALLOWED = 12; // ADD 2 EXTRA

  return (
    <OuterDiv>
      <ChatColumn>
        <MessageListColumn>
          <div>
            {messageList.map((message: Message) => {
              return (
                <MessageContainer key={message.text} user={message.user}>
                  {message.user === "bot" ? (
                    <Logo
                      src={require("../../../assets/chubbs-response-icon.png")}
                    />
                  ) : (
                    <UserLogo>
                      {getFirstLettersFromEmail(userData?.email)}
                    </UserLogo>
                  )}
                  <Message>{message.text}</Message>
                </MessageContainer>
              );
            })}
            {tabularFields?.answer_question_from_survey?.running && (
              <MessageContainer user={"bot"}>
                <Logo
                  src={require("../../../assets/chubbs-response-icon.png")}
                />
                <LoadingDots />
              </MessageContainer>
            )}
          </div>
        </MessageListColumn>
        <div>
          <MessageForm onSubmit={submitForm}>
            <FlexBox>
              <Col9>
                <MessageInput
                  type="text"
                  maxLength={500}
                  disabled={
                    tabularFields?.answer_question_from_survey?.running ||
                    isLoading ||
                    messageList.length > MAX_MESSAGES_ALLOWED
                  }
                  value={message?.text}
                  onChange={(e) => updateMessage(e.target.value)}
                  placeholder="Ask your question..."
                />
              </Col9>
              <Col3>
                <MessageButton
                  type="submit"
                  disabled={
                    !(
                      message?.text &&
                      questionAnsData.qualitativeFields?.length > 0
                    )
                  }
                >
                  Ask
                </MessageButton>
              </Col3>
            </FlexBox>
            {message?.text && (
              <Row4>
                <Col6>
                  <Select
                    options={tableMetadata
                      .filter(
                        (header: any) => header.dataType === "qualitative"
                      )
                      .map((i: any) => ({
                        value: i.columnName,
                        label: i.columnName,
                      }))}
                    placeholder="Select qualitative columns"
                    value={questionAnsData.qualitativeFields}
                    onChange={selectColumnHeaderHelperQuestion}
                    isOptionDisabled={() =>
                      questionAnsData.qualitativeFields.length >= 5
                    }
                    isSearchable={true}
                    isMulti={true}
                  />
                </Col6>
              </Row4>
            )}
          </MessageForm>
        </div>
      </ChatColumn>
    </OuterDiv>
  );
}

export default ChatBox;
