import React, { ReactChildren, ReactChild } from "react";
import styled from "styled-components";

// import { useAuthentication } from "../context/authentication";
// import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";

interface AuthMainWrapperProps {
  children: ReactChild | ReactChildren;
}

const AuthMainWrapper = ({ children }: AuthMainWrapperProps) => {
  return (
    <Outer className="AuthMainWrapper-Outer">
      <P1>{"Good " + timeOfDay() + "!"}</P1>
      <Inner>{children}</Inner>
    </Outer>
  );
};

export default AuthMainWrapper;

const Outer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border: 1px solid #f3f3f3;
  box-shadow: inset 3px 3px 30px rgba(213, 213, 213, 0.6);
`;

const P1 = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  margin: 20px 40px;
`;

const Inner = styled.div`
  flex: 1;
  display: flex;
  background-color: white;
  border: 1px solid #e4e4e4;
  box-shadow: 0px -4px 50px rgba(192, 192, 192, 0.2);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 40px;
`;

const timeOfDay = () => {
  const date = new Date();
  const hour = date.getHours();

  if (hour >= 5 && hour < 12) {
    return "morning";
  } else if (hour >= 12 && hour < 18) {
    return "afternoon";
  } else if (hour >= 18 && hour < 22) {
    return "evening";
  } else {
    return "night";
  }
};
