import React, { FC, useState } from "react";
import { H1 } from "components/CommonComponents";
import { DbQueryListenerType } from "services/hooks";
import { Container, R1, R2, R3, ReportContainer } from "../projectComponents";
import {
  DataHeaderDiv,
  DataHeaderLeft,
  SurveyStratificationRow,
} from "./surveyComponents";
import { KeywordsData, QuestionAnsData } from ".";
import { Input } from "components/ThemeComponents";
import ChatBox from "./ChatBox";
import { useFirestore } from "context/firestore";

const SelectTextResponses = ({
  tableMetadata,
  selectedUploadedFileHeadersQual,
  selectColumnHeaderHelperQual,
  selectedUploadedFileHeadersQuant,
  selectColumnHeaderHelperQuant,
}: any) => {
  return (
    <Container>
      <H1>Survey Test Project</H1>
      <R1>Column Analysis</R1>
      <R3>Select the columns of data you want to analyze.</R3>
      {tableMetadata.map((header: any, index: any) => {
        return (
          <DataHeaderDiv
            key={index}
            onClick={() =>
              header.dataType === "qualitative" &&
              selectedUploadedFileHeadersQual.length < 2
                ? selectColumnHeaderHelperQual(header.columnName)
                : selectedUploadedFileHeadersQual.includes(header.columnName)
                ? selectColumnHeaderHelperQual(header.columnName)
                : null
            }
            selected={selectedUploadedFileHeadersQual.includes(
              header.columnName
            )}
            selectable={
              header.dataType === "qualitative" &&
              selectedUploadedFileHeadersQual.length < 2
            }
            qualitative={header.dataType === "qualitative"}
          >
            <DataHeaderLeft>
              <p className="headername">
                {header.columnName}{" "}
                <span className="columndatatype">
                  {header.dataType === "qualitative"
                    ? "Qualitative"
                    : "Quantitative"}
                </span>
              </p>
              <p className="exampletext">
                # of unique responses: {header.uniqueValues} | Example data:{" "}
                {header.columnExample}
              </p>
            </DataHeaderLeft>
            <div className="data-header-right">
              <img
                className="data-header-right-check-icon"
                src={require("assets/checkmark-new.png")}
              />
              <div className="data-header-right-circle" />
            </div>
          </DataHeaderDiv>
        );
      })}
    </Container>
  );
};

const SelectStratifiedText: FC<StratifiedProps> = ({
  tableMetadata,
  selectedUploadedFileHeadersQual,
  selectColumnHeaderHelperQual,
  selectedUploadedFileHeadersQuant,
  selectColumnHeaderHelperQuant,
}) => {
  return (
    <Container>
      <H1>Survey Test Project</H1>
      <R1>Data Mapping</R1>
      <R3>
        Select up to 2 columns you want to analyze against. We will confront
        qualitative columns against every option in the quantitative column and
        provide you a summary for each combination possible.
      </R3>
      <SurveyStratificationRow>
        <div className="survey-row-col">
          <R2>
            Select a <b>qualitative</b> data column:
          </R2>
          {tableMetadata.map((header: any, index: any) => {
            if (header.dataType === "qualitative")
              return (
                <DataHeaderDiv
                  key={index}
                  onClick={() =>
                    header.dataType === "qualitative" &&
                    selectedUploadedFileHeadersQual.length < 2
                      ? selectColumnHeaderHelperQual(header.columnName)
                      : selectedUploadedFileHeadersQual.includes(
                          header.columnName
                        )
                      ? selectColumnHeaderHelperQual(header.columnName)
                      : null
                  }
                  selected={selectedUploadedFileHeadersQual.includes(
                    header.columnName
                  )}
                  selectable={
                    header.dataType === "qualitative" &&
                    selectedUploadedFileHeadersQual.length < 2
                  }
                  qualitative={header.dataType === "qualitative"}
                >
                  <DataHeaderLeft>
                    <p className="headername">
                      {header.columnName}{" "}
                      <span className="columndatatype">
                        {header.dataType === "qualitative"
                          ? "Qualitative"
                          : "Quantitative"}
                      </span>
                    </p>
                    <p className="exampletext">
                      # of unique responses: {header.uniqueValues} | Example
                      data: {header.columnExample}
                    </p>
                  </DataHeaderLeft>
                  <div className="data-header-right">
                    <img
                      className="data-header-right-check-icon"
                      src={require("assets/checkmark-new.png")}
                    />
                    <div className="data-header-right-circle" />
                  </div>
                </DataHeaderDiv>
              );

            return null;
          })}
        </div>
        <div className="survey-row-col">
          <R2>
            Select a <b>quantitative</b> data column:
          </R2>
          {tableMetadata.map((header: any, index: any) => {
            if (header.uniqueValues <= 10)
              return (
                <DataHeaderDiv
                  key={index}
                  onClick={() =>
                    selectedUploadedFileHeadersQuant.length < 1
                      ? selectColumnHeaderHelperQuant(header.columnName)
                      : selectedUploadedFileHeadersQuant.includes(
                          header.columnName
                        )
                      ? selectColumnHeaderHelperQuant(header.columnName)
                      : null
                  }
                  selected={selectedUploadedFileHeadersQuant.includes(
                    header.columnName
                  )}
                  selectable={selectedUploadedFileHeadersQuant.length < 1}
                  qualitative={header.dataType === "qualitative"}
                >
                  <DataHeaderLeft>
                    <p className="headername">
                      {header.columnName}{" "}
                      <span className="columndatatype">
                        {header.dataType === "qualitative"
                          ? "Qualitative"
                          : "Quantitative"}
                      </span>
                    </p>
                    <p className="exampletext">
                      # of unique responses: {header.uniqueValues} | Example
                      data: {header.columnExample}
                    </p>
                  </DataHeaderLeft>
                  <div className="data-header-right">
                    <img
                      className="data-header-right-check-icon"
                      src={require("assets/checkmark-new.png")}
                    />
                    <div className="data-header-right-circle" />
                  </div>
                </DataHeaderDiv>
              );

            return null;
          })}
        </div>
      </SurveyStratificationRow>
    </Container>
  );
};

const GenerateKeywordSummaries = ({
  tableMetadata,
  selectedUploadedFileHeadersQual,
  selectColumnHeaderHelperQual,
  keywordsData,
  setKeywordsData
}: any) => {
  return (
    <Container>
      <H1>Survey Test Project</H1>
      <R1>Keyword Analysis</R1>

      <R3>Enter keyword</R3>
      <Input
        value={keywordsData.keyword || ""}
        onChange={(e: any) => {
          setKeywordsData({
            ...keywordsData,
            keyword:e.target.value
          });
        }}
        maxLength={30}
      />
      <R3>Select the columns you want to analyze.</R3>
      {tableMetadata.filter((header:any) => header.dataType === "qualitative" ).map((header: any, index: any) => {
        return (
          <DataHeaderDiv
            key={index}
            onClick={() =>
              header.dataType === "qualitative" &&
              keywordsData.qualitativeFields.length < 2
                ? selectColumnHeaderHelperQual(header.columnName)
                : keywordsData.qualitativeFields.includes(header.columnName)
                ? selectColumnHeaderHelperQual(header.columnName)
                : null
            }
            selected={keywordsData.qualitativeFields.includes(
              header.columnName
            )}
            selectable={
              header.dataType === "qualitative" &&
              keywordsData.qualitativeFields.length < 2
            }
            qualitative={header.dataType === "qualitative"}
          >
            <DataHeaderLeft>
              <p className="headername">
                {header.columnName}{" "}
                <span className="columndatatype">
                  Qualitative
                </span>
              </p>
              <p className="exampletext">
                # of unique responses: {header.uniqueValues} | Example data:{" "}
                {header.columnExample}
              </p>
            </DataHeaderLeft>
            <div className="data-header-right">
              <img
                className="data-header-right-check-icon"
                src={require("assets/checkmark-new.png")}
              />
              <div className="data-header-right-circle" />
            </div>
          </DataHeaderDiv>
        );
      })}
    </Container>
  );
};

const SelectData: FC<SelectDataProps> = ({
  selectedUploadedFileHeadersQual,
  setSelectedUploadedFileHeadersQual,
  selectedUploadedFileHeadersQuant,
  setSelectedUploadedFileHeadersQuant,
  tableMetadata,
  keywordsData,
  setKeywordsData,
  setQuestionAnsData,
  questionAnsData,
  projectid,
  dataProcesss,
  tabularFields,
  ...props
}) => {
  const { createProjectQuestionAns, getSurveyQuestionAnsList } = useFirestore()

  const parsedTableMetadata = tableMetadata[0]
    ? parseDataFields(tableMetadata[0].data().dataFields)
    : null;


  const selectColumnHeaderHelperQual = (headerName: string) => {
    if (selectedUploadedFileHeadersQual.includes(headerName)) {
      setSelectedUploadedFileHeadersQual(
        selectedUploadedFileHeadersQual.filter((item) => item !== headerName)
      );
    } else {
      setSelectedUploadedFileHeadersQual([
        ...selectedUploadedFileHeadersQual,
        headerName,
      ]);
    }
  };

  const selectColumnHeaderHelperQuant = (headerName: string) => {
    if (selectedUploadedFileHeadersQuant.includes(headerName)) {
      setSelectedUploadedFileHeadersQuant(
        selectedUploadedFileHeadersQuant.filter((item) => item !== headerName)
      );
    } else {
      setSelectedUploadedFileHeadersQuant([
        ...selectedUploadedFileHeadersQuant,
        headerName,
      ]);
    }
  };

  const selectColumnHeaderHelperKeywords = (headerName: string) => {
    if (keywordsData.qualitativeFields.includes(headerName)) {
      setKeywordsData({
        ...keywordsData,
        qualitativeFields:keywordsData.qualitativeFields.filter((item) => item !== headerName),
      });
    } else {
      setKeywordsData({
        ...keywordsData,
        qualitativeFields:[...keywordsData.qualitativeFields,headerName]
      });
    }
  };

  if (!parsedTableMetadata) return <div>Loading...</div>;

  if (props.surveyAnalysisType === "just-text-responses") {
    return (
      <SelectTextResponses
        selectedUploadedFileHeadersQual={selectedUploadedFileHeadersQual}
        selectColumnHeaderHelperQual={selectColumnHeaderHelperQual}
        tableMetadata={parsedTableMetadata}
        {...props}
      />
    );
  } else if (props.surveyAnalysisType === "stratified-text-responses") {
    return (
      <SelectStratifiedText
        tableMetadata={parsedTableMetadata}
        selectedUploadedFileHeadersQual={selectedUploadedFileHeadersQual}
        selectColumnHeaderHelperQual={selectColumnHeaderHelperQual}
        selectedUploadedFileHeadersQuant={selectedUploadedFileHeadersQuant}
        selectColumnHeaderHelperQuant={selectColumnHeaderHelperQuant}
        {...props}
      />
    );
  } else if (props.surveyAnalysisType === "keyword-summary") {
    return(<></>)
  } else {
    return (
      <>
        <H1>Question Answer Bot</H1>
       <ChatBox 
        tableMetadata={parsedTableMetadata}
        setQuestionAnsData={setQuestionAnsData}
        questionAnsData={questionAnsData}
        createSurveyQuestionAns={createProjectQuestionAns}
        getSurveyQuestionAns={getSurveyQuestionAnsList}
        projectid={projectid}
        dataProcesss={dataProcesss}
        tabularFields={tabularFields}
        />
      </>
    )
  }
};

export default SelectData;

interface StratifiedProps
  extends Omit<
    SelectDataProps,
    | "setSelectedUploadedFileHeadersQual"
    | "setSelectedUploadedFileHeadersQuant"
    | "tableMetadata"
    | "keywordsData"
    | "setKeywordsData"
    | "questionAnsData"
    | "setQuestionAnsData" 
    | "projectid"
    | "dataProcesss"
    | "tabularFields" 
  > {
  selectColumnHeaderHelperQual: (headerName: string) => void;
  selectColumnHeaderHelperQuant: (headerName: string) => void;
  tableMetadata: TableMetadataType[];
}

type TableMetadataType = {
  dataType: string;
  uniqueValues: number;
  columnName: string;
};

const parseDataFields = (data: any) =>
  data
    .map((f: any) => ({
      ...f,
      dataType: f.uniqueValues > 10 ? "qualitative" : "quantitative",
    }))
    .sort((a: any, b: any) => {
      if (a.dataType === b.dataType) return 0;
      if (a.dataType === "quantitative") return 1;
      return -1;
    }) as TableMetadataType[];

type SelectDataProps = {
  surveyAnalysisType: string | null;
  tableMetadata: DbQueryListenerType[];
  selectedUploadedFileHeadersQual: string[];
  setSelectedUploadedFileHeadersQual: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  selectedUploadedFileHeadersQuant: string[];
  setSelectedUploadedFileHeadersQuant: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  keywordsData:KeywordsData;
  setKeywordsData:React.Dispatch<
  React.SetStateAction<KeywordsData>
  >;
  questionAnsData:QuestionAnsData;
  setQuestionAnsData:React.Dispatch<
  React.SetStateAction<QuestionAnsData>
  >;
  projectid: string;
  dataProcesss: any;
  tabularFields: any;
};
