import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import { useFirestore } from "context/firestore";
import { useAuthentication } from "context/authentication";
import { Link } from "react-router-dom";

import DataProcessService from "services/DataProcess";
import CategoricalSummaryComponent from "./CategoricalSummaryComponent";
import FileContentsComponent from "./FileContentsComponent";
import QuestionsAndAnswers from "./QuestionsAndAnswers";
import ProjectStatistics from "./ProjectStatistics";
import SpinnerLarge from "components/SpinnerLarge";
import CheckmarkNewImg from "assets/checkmark-new.png";

import { Button3, Button4, H1, H2 } from "components/CommonComponents";
import {
  Row2,
  Row3,
  Row3Left,
  Row3Right,
  Main,
  SpinnerContainer,
  ContainerCreate,
  Container,
  UploaderContainer,
  ReportContainer,
  TopBarRow,
  TopBarLeft,
  TopBarCenter,
  StepContainer,
  StepLine,
  TopBarRight,
  FilesEmptyState,
  BackBtnImg,
  PSelectedKeywords,
  ReportInner,
  HIU1,
  HIU2,
  R1,
  R2,
  Step2Img,
  P7Small,
  Keywords,
  Keyword,
  KeywordText,
  UnselectedCheckMarkDiv,
  SelectedCheckMarkDiv,
  P1,
  Row1,
  ToggleBtns,
  ToggleBtn,
  FilesContainer,
  File2,
  FileName,
  ViewFileBtn,
} from "../projectComponents";
import FilesTableRow from "./FilesTableRow";
import PDFReportManager from "./PDFReportManager";
import { PrimaryBtn } from "components/ThemeComponents";
import { useFetchBackend } from "services/hooks";
import { useHideModal, useShowModal } from "context/Modal";
import AlertModal from "components/AlertModal";
import { useFilterArray } from "hooks";
import http from "services/httpService";
import { IS_PROD } from "config";

function searchKeywords(input_text: string, items: any[]) {
  return items.filter((kw) => kw.word.includes(input_text.toLowerCase()));
}

const ConfirmDeleteModal = ({
  handleDeleteProject,
}: {
  handleDeleteProject: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const hideModal = useHideModal();
  return (
    <AlertModal title="Are you sure?">
      This action can't be reversed
      <br />
      <br />
      <PrimaryBtn
        disabled={loading}
        onClick={async () => {
          let error = false;
          setLoading(true);
          try {
            await handleDeleteProject();
          } catch {
            alert("Something went wrong, try again later");
            error = true;
          } finally {
            setLoading(false);
            if (!error) {
              hideModal();
              history.push("/");
            }
          }
        }}
      >
        Delete
      </PrimaryBtn>
    </AlertModal>
  );
};

export const Submission = () => {
  const { user } = useAuthentication();
  const [userIsActive, setUserIsActive] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const { projectid } = useParams<{ projectid: string }>();
  const [step, setStep] = useState<number>(0);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [showFileContents, setShowFileContents] = useState<boolean>(false);
  const [fileDetails, setFileDetails] = useState<any>();

  const [projectName, setProjectName] = useState<string>();
  const [projectKeywords, setProjectKeywords] = useState<any[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<{
    [k: string]: any;
  }>({});
  const selectedKeywordsArray = useMemo(
    () => Object.entries(selectedKeywords),
    [selectedKeywords]
  );
  const [generalSummaires, setGeneralSummaires] = useState<any[]>([]);
  const [categoricalSummaries, setCategoricalSummaries] = useState<any[]>([]);

  const hasSetupPayments = useFetchBackend("/payments/check-setup-payments");
  const showModal = useShowModal();
  const [keywordsSearchText, setKeywordsSearchText] = useState("");

  const filteredProjectKeywords = useFilterArray({
    inputText: keywordsSearchText,
    filter_by: searchKeywords,
    data: projectKeywords,
  });

  const {
    getProjectByProjectId,
    listFilesByProjectId,
    uploadPdf,
    getProjectKeywords,
    getProjectGeneralSummary,
    getProjectCategoricalSummaries,
  } = useFirestore();

  const onDrop = (files: File[]) => {
    setUploadFiles(files);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop,
  });

  const renderSelectedKeywordsText = () => {
    const selectedKeywordsStr = selectedKeywordsArray.map(
      ([_, kw]: any) => kw.word
    );
    if (selectedKeywordsStr.length > 0) {
      let allKeywordsString = "";
      for (var i = 0; i < selectedKeywordsStr.length; i++) {
        if (i === selectedKeywordsStr.length - 1) {
          allKeywordsString += selectedKeywordsStr[i];
        } else {
          allKeywordsString += selectedKeywordsStr[i] + ", ";
        }
      }

      return `Here are the keywords you've selected: ` + allKeywordsString;
    } else {
      return `You haven't selected any keywords yet.`;
    }
  };

  const deselectKeywordHelper = (word: any) => {
    // remove from array
    setSelectedKeywords((curr) => {
      const newObj: any = { ...curr };
      delete newObj[word.id];

      return newObj;
    });
  };

  const selectKeywordHelper = (word: any) => {
    setSelectedKeywords((curr) => ({ ...curr, [word.id]: word }));
  };

  const toggleSelectedKeyword = (word: any) => {
    if (!selectedKeywords[word.id as keyof typeof selectedKeywords]) {
      selectKeywordHelper(word);
    } else {
      deselectKeywordHelper(word);
    }
  };

  const compareFiles = (a: File[], b: File[]): File[] => {
    const result: File[] = [];
    // iterate through the files in the first array
    a.forEach((fileA) => {
      // check if the file also exists in the second array
      const match = b.find((fileB) => fileB.name === fileA.name);
      // if the file exists in both arrays, add it to the result array
      if (match) {
        result.push(fileA);
      }
    });
    return result;
  };

  const handleUploadPDF = async () => {
    try {
      setUploadingFile(true);
      if (uploadFiles.length === 0) throw new Error("No files provided.");
      if (!projectid) throw new Error("Project ID not provided.");

      const repeatedFiles = compareFiles(uploadFiles, files);
      if (repeatedFiles.length > 0)
        throw new Error(`One of the selected files has already been uploaded.`);
      await uploadPdf(projectid, uploadFiles);
      await setProjectFilesAndStep();
      setUploadFiles([]);
      setUploadingFile(false);
    } catch (e: any) {
      setUploadingFile(false);
      alert(`Error: ${e.message}`);
    }
  };

  const startDataProcessing = async () => {
    // setProjectLoading(true);
    try {
      await DataProcessService.startDataProcessing({ projectid });
      // setStep(2);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const startReportGeneration = async () => {
    try {
      let requestBody = {
        projectid: projectid,
        keywords: selectedKeywordsArray.map(([_, kw]: any) => kw.word),
      };
      console.log(requestBody);

      await DataProcessService.startReportGeneration(requestBody);
    } catch (err) {}
  };

  const deleteFileHelper = async (delete_fileid: any) => {
    try {
      await DataProcessService.deleteFile({ fileid: delete_fileid });
      // listProjects();
      setProjectFilesAndStep();
    } catch (err: any) {
      alert(err.message);
    }
  };

  const setProjectFilesAndStep = async () => {
    if (!projectid) return;
    const projectData = await getProjectByProjectId(projectid);
    setProjectName(projectData.name);
    // if (projectData?.loading) setProjectLoading(true);
    const projectFiles = await listFilesByProjectId(projectid);
    setFiles([...projectFiles]);
    let temporaryStep = 1;
    if (projectFiles.length > 0) temporaryStep = 1; // uploading files
    if (projectData.loading) temporaryStep = 2; // currently processing data (loader, "data processing button" disable, next btn disabled, )
    if (projectData.dataProcessed) {
      temporaryStep = 3; // data processed, select report stuff, can go back ("Review files you uploaded")

      const projectKeywords = await getProjectKeywords(projectid);
      setProjectKeywords(projectKeywords);
    }
    if (projectData.dataProcessed && projectData.loading) temporaryStep = 4; // Generating report
    if (projectData.reportGenerated) {
      // view report
      temporaryStep = 4;

      const generalSummaries = await getProjectGeneralSummary(projectid);
      setGeneralSummaires(generalSummaries);

      const categoricalSummaires = await getProjectCategoricalSummaries(
        projectid
      );
      setCategoricalSummaries(categoricalSummaires);
    }

    setShowFiles(false);
    setStep(temporaryStep);
    setInitialLoading(false);
  };

  const topBarNextFunction = async () => {
    if (IS_PROD) {
      await hasSetupPayments.wait();
      if (hasSetupPayments.error) {
        return showModal(
          <AlertModal>You need to set up a payment method.</AlertModal>
        );
      }
    }

    if (step === 1) {
      if (files.length === 0) {
        alert("Please upload at least one file.");
      } else {
        startDataProcessing();
      }
    } else if (step === 3) {
      if (Object.keys(selectedKeywords).length === 0) {
        alert("Please select at least one keyword.");
      } else {
        startReportGeneration();
      }
    }
  };

  const handleDeleteProject = async () => {
    await http.delete("/projects/" + projectid);
  };

  const renderTopBar = () => {
    return (
      <TopBarRow>
        <TopBarLeft>
          <Link to="/">
            <BackBtnImg src={require("assets/back-btn.png")} />
          </Link>

          {showDeleteBtn ? (
            deleting ? (
              <span>Deleting...</span>
            ) : (
              <BtnsRow>
                <Button3 onClick={() => setShowDeleteBtn(false)}>
                  Cancel
                </Button3>
                <Button4
                  onClick={() =>
                    showModal(
                      <ConfirmDeleteModal
                        handleDeleteProject={handleDeleteProject}
                      />
                    )
                  }
                >
                  Delete?
                </Button4>
              </BtnsRow>
            )
          ) : (
            <DeleteBtn
              alt="Delete Button"
              onClick={() => setShowDeleteBtn(true)}
              className="delete_icon"
              src={require("assets/bin.png")}
            />
          )}
        </TopBarLeft>

        <TopBarCenter>
          <StepContainer
            status={step === 1 ? "active" : step > 1 ? "done" : "future"}
          >
            <div className="step">
              <span>1</span>
            </div>
            <p>Upload</p>
          </StepContainer>

          <StepLine
            status={step === 2 ? "active" : step > 2 ? "done" : "future"}
          />

          <StepContainer
            status={step === 2 ? "active" : step > 2 ? "done" : "future"}
          >
            <div className="step">
              <span>2</span>
            </div>
            <p>Extract</p>
          </StepContainer>

          <StepLine
            status={step === 3 ? "active" : step > 3 ? "done" : "future"}
          />

          <StepContainer
            status={step === 3 ? "active" : step > 3 ? "done" : "future"}
          >
            <div className="step">
              <span>3</span>
            </div>
            <p>Keywords</p>
          </StepContainer>

          <StepLine
            status={step === 4 ? "active" : step > 4 ? "done" : "future"}
          />

          <StepContainer
            status={step === 4 ? "active" : step > 4 ? "done" : "future"}
          >
            <div className="step">
              <span>4</span>
            </div>
            <p>Report</p>
          </StepContainer>
        </TopBarCenter>

        <TopBarRight>
          {step < 4 && step !== 2 && (
            <PrimaryBtn onClick={() => topBarNextFunction()}>Next</PrimaryBtn>
          )}
        </TopBarRight>
      </TopBarRow>
    );
  };

  const renderToggleBtns = () => {
    return (
      <ToggleBtns>
        <ToggleBtn
          left={true}
          active={!showFiles}
          onClick={() => (showFiles ? setShowFiles(!showFiles) : null)}
        >
          Report
        </ToggleBtn>
        <ToggleBtn
          left={false}
          active={showFiles}
          onClick={() => (!showFiles ? setShowFiles(!showFiles) : null)}
        >
          Analysed files
        </ToggleBtn>
      </ToggleBtns>
    );
  };

  const renderFiles = () => {
    return (
      <FilesContainer>
        <H2>Analysed files</H2>
        <P1>You can see the text we extracted from each file</P1>
        {files.map((file: any, fileId: number) => (
          <File2 key={fileId}>
            <FileName onClick={() => showFileContentsFunction(file)}>
              {file.name}
            </FileName>
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              <ViewFileBtn src={require("assets/file-view.png")} />
            </a>
          </File2>
        ))}
      </FilesContainer>
    );
  };

  const showFileContentsFunction = (file: any) => {
    setShowFileContents(!showFileContents);
    setFileDetails(file);
  };

  const renderComponentByStep = () => {
    if (initialLoading) {
      return (
        <SpinnerContainer>
          <SpinnerLarge />
        </SpinnerContainer>
      );
    }

    switch (step) {
      case 1:
        return (
          <Row3>
            <Row3Left>
              {initialLoading ? null : (
                <H1>{projectName && projectName.length > 0 && projectName}</H1>
              )}
              {/* <div > */}
              <UploaderContainer {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {uploadFiles.length > 0 && (
                  <div>
                    <h4>Loaded files:</h4>
                    <ul>
                      {uploadFiles.map((file: File) => (
                        <li key={file.name}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {uploadFiles.length === 0 && (
                  <p>
                    Drag and drop your files here or{" "}
                    <span>browse your computer</span>
                  </p>
                )}
              </UploaderContainer>
              {/* </div> */}

              <PrimaryBtnStyled
                onClick={() => (uploadingFile ? null : handleUploadPDF())}
                disabled={
                  uploadingFile || uploadFiles.length === 0 ? true : false
                }
              >
                {uploadingFile ? "Uploading.." : "Upload File"}
              </PrimaryBtnStyled>
            </Row3Left>

            <Row3Right>
              <H2Styled>Uploaded Files</H2Styled>
              {files.length > 0 ? (
                files.map((f: any, id: number) =>
                  f.deleted ? null : (
                    <FilesTableRow
                      file_object={f}
                      deleteFileHelper={deleteFileHelper}
                      key={id}
                    />
                  )
                )
              ) : (
                <FilesEmptyState>
                  <img src={require("assets/emptybox.png")} alt="No files." />
                  <p>No files uploaded</p>
                </FilesEmptyState>
              )}
            </Row3Right>
          </Row3>
        );

      case 2:
        return (
          <Container alignitems={"center"}>
            <br />
            <H1>Great! Your data is being processed</H1>
            <P1>We'll let you know when the process is finished via email.</P1>
            <Step2Img src={require("assets/data-processing-chubbs.png")} />
          </Container>
        );

      case 3:
        return (
          <Container>
            {initialLoading ? null : (
              <H1>{projectName && projectName.length > 0 && projectName}</H1>
            )}

            <H2>Select the keywords you want analyzed & summarized</H2>
            <P7Small>
              You can select up to 5, we will provide a summary of suggestions
              and consequences for each keyword from the submissions
            </P7Small>
            <PSelectedKeywords>
              <i>{renderSelectedKeywordsText()}</i>
              {selectedKeywordsArray.map(([_, k], i) => (
                <SelectedKeyword
                  onClick={() => toggleSelectedKeyword(k)}
                  key={i}
                >
                  {k.word}
                </SelectedKeyword>
              ))}
            </PSelectedKeywords>
            <div>
              <input onChange={(e) => setKeywordsSearchText(e.target.value)} />
            </div>
            <Keywords>
              {filteredProjectKeywords.map((k, id) => {
                const wordId = k.id as keyof typeof selectedKeywords;
                let selected = !!selectedKeywords[wordId];
                return (
                  <Keyword
                    key={id}
                    selected={selected}
                    onClick={() => toggleSelectedKeyword(k)}
                  >
                    <KeywordText selected={selected}>
                      {k?.word} <span>({k?.count})</span>
                      {selected}
                    </KeywordText>

                    {selected ? (
                      <SelectedCheckMarkDiv>
                        <img alt="Check mark" src={CheckmarkNewImg} />
                      </SelectedCheckMarkDiv>
                    ) : (
                      <UnselectedCheckMarkDiv />
                    )}
                  </Keyword>
                );
              })}
            </Keywords>
          </Container>
        );

      case 4:
        if (generalSummaires.length === 0) {
          return (
            <Container alignitems={"center"}>
              <br />
              <H1>Generating report...</H1>
              <P1>
                We'll let you know when the process is finished via email.
              </P1>
              <Step2Img src={require("assets/generating-report-chubbs.png")} />
            </Container>
          );
        } else {
          return (
            <>
              <ReportContainer>
                <Row1>
                  {initialLoading ? null : (
                    <H1>
                      {projectName && projectName.length > 0 && projectName}
                    </H1>
                  )}

                  <Row2>
                    <PDFReportManager
                      projectid={projectid}
                      projectName={projectName}
                    />
                    {renderToggleBtns()}
                  </Row2>
                </Row1>

                {showFiles ? (
                  renderFiles()
                ) : (
                  <>
                    <ProjectStatistics
                      projectid={projectid}
                      keywords={projectKeywords}
                    />

                    <R1>General Summary</R1>
                    <ReportInner>
                      {generalSummaires.map((item, id) => {
                        return (
                          <R2
                            key={id}
                            dangerouslySetInnerHTML={{ __html: item.summary }}
                          />
                        );
                      })}
                    </ReportInner>

                    <R1>Keyword Summaries</R1>
                    {categoricalSummaries.map((item, id) => {
                      if (item.summary && item.summary.length > 1) {
                        return (
                          <CategoricalSummaryComponent
                            categoricalSummary={item}
                            key={id}
                          />
                        );
                      }
                      return null;
                    })}

                    <R1>Your Questions</R1>
                    <QuestionsAndAnswers
                      dataProcessService={DataProcessService}
                      keywords={projectKeywords}
                      projectid={projectid}
                    />
                  </>
                )}
              </ReportContainer>
            </>
          );
        }
    }
  };

  const starterFunction = async () => {
    if (!user?.uid) return;

    // Get user role
    let userStatus = await DataProcessService.getUserStatus({
      userid: user?.uid,
    });

    if (userStatus.approved_user) {
      setProjectFilesAndStep();

      const db = firebase.firestore();
      db.collection("projects")
        .doc(projectid)
        .onSnapshot((doc) => {
          if (doc) {
            // const project =doc.data();
            setProjectFilesAndStep();
          }
        });
    } else {
      setUserIsActive(false);
    }
  };

  useEffect(() => {
    starterFunction();
    // eslint-disable-next-line
  }, []);

  if (!userIsActive) {
    return (
      <Main>
        <ContainerCreate>
          <HIU1>Thank you for signing up for SumUps!</HIU1>
          <HIU2>
            In order to use the SumUps app, you need to confirm your email by
            clicking the link we sent you.
          </HIU2>
        </ContainerCreate>
      </Main>
    );
  }

  return (
    <Main>
      {showFileContents && (
        <FileContentsComponent
          closeFileContentsComponent={setShowFileContents}
          file={fileDetails}
        />
      )}
      {initialLoading ? null : renderTopBar()}
      {renderComponentByStep()}
    </Main>
  );
};

const H2Styled = styled(H2)`
  margin-top: 40px;
  margin-bottom: 23px;
`;

const PrimaryBtnStyled = styled(PrimaryBtn)`
  margin-right: 30px;
`;

const BtnsRow = styled.div`
  display: flex;
`;

const DeleteBtn = styled.img`
  max-width: 24px;
`;

const SelectedKeyword = styled(Keyword).attrs({ selected: true })``;

export default Submission;
