import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { ModalProvider } from "./context/Modal";
import { RootProvider } from "./rootProviders";
import GlobalStyles from "./styles/globalStyles";

ReactDOM.render(
  <React.StrictMode>
    <RootProvider>
      <GlobalStyles />
      <ModalProvider>
        <App />
      </ModalProvider>
    </RootProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
