import React, { Dispatch, FC } from "react";
import { H1 } from "components/CommonComponents";
import { Row4, Container, R3, R1, R2 } from "../projectComponents";
import {
  ReportGeneratedStatusBtn,
  TypeSelectorBtn,
  TypeSelectorBtnCircle,
} from "./surveyComponents";
import { TabularFields } from ".";
import ChatBox from "./ChatBox";

const ChooseAnalysisType: FC<{
  surveyAnalysisType: string | null;
  setSurveyAnalysisType: Dispatch<string | null>;
  tabularFields: TabularFields;
}> = ({ setSurveyAnalysisType, surveyAnalysisType, tabularFields }) => {
  return (
    <Container>
      <H1>Survey Test Project</H1>
      <R1>Select analysis mode</R1>
      <R2>Choose from the analysis modes given below.</R2>

      <Row4>
        <TypeSelectorBtn
          onClick={() => setSurveyAnalysisType("just-text-responses")}
          selected={surveyAnalysisType === "just-text-responses"}
        >
          <R1>Just text responses</R1>
          <R3>
            You'll select just 1 column of the file. We'll provide a summary of
            the qualitative data in the chosen column.
          </R3>
          {tabularFields?.get_summary_of_columns?.finished ? (
            <ReportGeneratedStatusBtn>
              Summary Generated
            </ReportGeneratedStatusBtn>
          ) : (
            <TypeSelectorBtnCircle />
          )}
        </TypeSelectorBtn>

        <TypeSelectorBtn
          onClick={() => setSurveyAnalysisType("stratified-text-responses")}
          selected={surveyAnalysisType === "stratified-text-responses"}
        >
          <R1>Stratified text responses</R1>
          <R3>
            You'll connect a qualitative data column with a multiple choice one.
            We'll provide a summary of the qualitative data segmented by each
            multiple choice column you selected.
          </R3>
          {tabularFields?.get_categorical_summaries?.finished ? (
            <ReportGeneratedStatusBtn>
              Summary Generated
            </ReportGeneratedStatusBtn>
          ) : (
            <TypeSelectorBtnCircle />
          )}
        </TypeSelectorBtn>
      </Row4>
      <Row4>
        <TypeSelectorBtn
          onClick={() => setSurveyAnalysisType("keyword-summary")}
          selected={surveyAnalysisType === "keyword-summary"}
        >
          <R1>Keyword Summary</R1>
          <R3>
            You'll select one keyword. We will generate summary based on that.
          </R3>
          {tabularFields?.get_summary_of_keyword_tabular?.finished ? (
            <ReportGeneratedStatusBtn>
              Summary Generated
            </ReportGeneratedStatusBtn>
          ) : (
            <TypeSelectorBtnCircle />
          )}
        </TypeSelectorBtn>

        <TypeSelectorBtn
          onClick={() => setSurveyAnalysisType("question-answer-bot")}
          selected={surveyAnalysisType === "question-answer-bot"}
        >
          <R1>Question Answer Bot</R1>
          <R3>
            You can ask questions regarding your tabular data and Q&A Bot will
            provide you the necessary answers, based on the qualitative columns
            you have selected.
          </R3>
          {tabularFields?.answer_question_from_survey?.finished ? (
            <ReportGeneratedStatusBtn>Continue Q&A</ReportGeneratedStatusBtn>
          ) : (
            <TypeSelectorBtnCircle />
          )}
        </TypeSelectorBtn>
      </Row4>
    </Container>
  );
};

export default ChooseAnalysisType;
