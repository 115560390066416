import { API_BASE_URL } from "../config";
import axios from "axios";
import { getCurrentUser } from "../context/firebase";
// import logger from "./logService";
// import { toast } from "react-toastify";
// import utils from "../util/utils";

const http = axios.create({
  baseURL: API_BASE_URL,
  timeout: 120000,
});

http.interceptors.request.use(async (config) => {
  const user = getCurrentUser();
  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    //both error must be log
    // logger.log("Logging the error", error);

    if (!expectedError) {
      //   toast.error(error.response.data);
    }
    return Promise.reject(error);
  }
);

export default http;
