import React, { FC, useState } from "react";
import { useDropzone } from "react-dropzone";
import AlertModal from "components/AlertModal";
import { H1 } from "components/CommonComponents";
import { ProjectType, useFirestore } from "context/firestore";
import { useShowModal } from "context/Modal";
import dataProcesss from "services/DataProcess";
import {
  Row3,
  Row3Left,
  UploaderContainer,
} from "../projectComponents";
import { PrimaryBtnStyled } from "./surveyComponents";

const UploadFile: FC<{
  project?: ProjectType;
  triggerNextStep: (fromAction?: boolean) => void;
}> = ({ project, triggerNextStep }) => {
  const [loadedFiles, setLoadedFiles] = useState<File[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const { uploadPdf } = useFirestore();
  const showModal = useShowModal();

  const onDrop = (files: File[]) => {
    setLoadedFiles(files);
  };

  const handleUploadFiles = async () => {
    setUploadingFile(true);
    try {
      if (loadedFiles.length === 0) throw new Error("No files provided.");
      if (!project) throw new Error("Page haven't loaded yet.");

      await uploadPdf(project.id, loadedFiles);
      dataProcesss.tabular.get_tabular_data(project.id);
      triggerNextStep(false);
    } catch (e: any) {
      showModal(<AlertModal>{e.message}</AlertModal>);
      setUploadingFile(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      csv: [".csv"],
      excel: [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    },
    maxFiles: 1,
    onDrop,
  });

  if (!project) return <div>Loading...</div>;

  return (
    <Row3>
      <Row3Left>
        <H1>{project.name}</H1>
        {/* <div > */}
        <UploaderContainer {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {loadedFiles.length > 0 && (
            <div>
              <h4>Loaded files:</h4>
              <ul>
                {loadedFiles.map((file: File) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
          {loadedFiles.length === 0 && (
            <p>
              Drag and drop your files here or <span>browse your computer</span>
            </p>
          )}
        </UploaderContainer>
        {/* </div> */}

        <PrimaryBtnStyled disabled={uploadingFile} onClick={handleUploadFiles}>
          {uploadingFile ? "Uploading.." : "Upload File"}
        </PrimaryBtnStyled>
      </Row3Left>
    </Row3>
  );
};

export default UploadFile;
