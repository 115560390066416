import { ProjectType, PROJECT_TYPES_MAP } from "context/firestore";
import { useEffect, useState } from "react";
import { deepArrayEquals } from "utils";

export const PROJECTS_PER_PAGE = 9;

export const ProjTypeFilters: {
  text: string;
  filterBy: {
    [k: string]: string | boolean;
  };
  alone?: boolean;
}[] = [
  {
    text: "All",
    filterBy: {},
    alone: true,
  },
  {
    text: "Survey",
    filterBy: {
      projectType: "survey",
    },
    alone: true,
  },
  {
    text: "Submissions",
    filterBy: {
      projectType: "submission",
    },
    alone: true,
  },
];

export const ProjStatusFilters: {
  [k in keyof typeof PROJECT_TYPES_MAP]: typeof ProjTypeFilters;
} = {
  survey: [
    {
      text: "All",
      filterBy: {},
      alone: true,
    },
    {
      text: "Loaded metadata",
      filterBy: {
        "tabular.loadMetadata.finished": true,
        "tabular.get_categorical_summaries.started": false,
        "tabular.get_summary_of_columns.started": false,
      },
      alone: true,
    },
    {
      text: "Generating Categorical report",
      filterBy: {
        "tabular.get_categorical_summaries.finished": false,
        "tabular.get_categorical_summaries.started": true,
      },
      alone: true,
    },
    {
      text: "Generating Summary report",
      filterBy: {
        "tabular.get_summary_of_columns.finished": false,
        "tabular.get_summary_of_columns.started": true,
      },
      alone: true,
    },
    {
      text: "Generated Categorical report",
      filterBy: {
        "tabular.get_categorical_summaries.finished": true,
      },
      alone: true,
    },
    {
      text: "Generated Summary report",
      filterBy: {
        "tabular.get_summary_of_columns.finished": true,
      },
      alone: true,
    },
  ],
  submission: [
    {
      text: "All",
      filterBy: {},
      alone: true,
    },
    {
      text: "Uploading Files",
      filterBy: {
        reportGenerated: false,
        dataProcessed: false,
      },
      alone: true,
    },
    {
      text: "Data Processed",
      filterBy: {
        dataProcessed: true,
        reportGenerated: false,
      },
      alone: true,
    },
    {
      text: "Report Generated",
      filterBy: {
        reportGenerated: true,
      },
      alone: true,
    },
  ],
  transcript: [],
};

export const useFilter = (_filters: typeof ProjTypeFilters) => {
  const [filterBy, setFilterBy] = useState<{
    reportGenerated?: boolean;
    dataProcessed?: boolean;
    projectType?: string;
  }>({});

  const [filters, setFilters] = useState(_filters);

  const [clickedFilters, setClickedFilters] = useState(() =>
    _filters.map(() => false)
  );

  const handleFilterClick = (filterIndex: number) => {
    const clickedFilter = _filters[filterIndex];
    if (!clickedFilter) return;

    let newClickedFilters = [...clickedFilters];
    let newFilter = {};

    if (clickedFilter.alone) {
      newClickedFilters = newClickedFilters.map(() => false);
    } else {
      newClickedFilters = newClickedFilters.map((clicked, i) =>
        _filters[i].alone ? false : clicked
      );
    }

    newClickedFilters[filterIndex] = !clickedFilters[filterIndex];

    newClickedFilters.forEach((f, index) => {
      if (!f) return;
      newFilter = { ..._filters[index].filterBy };
    });

    setFilterBy(newFilter);
    setClickedFilters(newClickedFilters);
  };

  useEffect(() => {
    if (_filters.length === 0) {
      if (clickedFilters.length !== 0) {
        setClickedFilters([]);
        setFilterBy({});
        return;
      }
    } else {
      const areFiltersDifferent = !deepArrayEquals(_filters, filters);

      if (areFiltersDifferent) {
        setClickedFilters(_filters.map(() => false));
        setFilterBy({});
        setFilters(filters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_filters]);

  return { handleFilterClick, filterBy, clickedFilters };
};

export const splitArray = <T>(arr: T[]) => {
  let result = [];
  let count = 0;
  let shuffledArray = arr;
  // shuffledArray = shuffleArray(arr);
  for (
    let i = 0;
    i < shuffledArray.length && count < PROJECTS_PER_PAGE;
    i += 3
  ) {
    result.push(shuffledArray.slice(i, i + 3));
    count += 3;
  }
  return result;
};

export const paginatedArray = <T>(array: T[], pageSize: number) => {
  const pageCount = Math.ceil(array.length / pageSize);

  return {
    pages: Array.from({ length: pageCount }, (_, index) => {
      const startIndex = index * pageSize;
      const endIndex = startIndex + pageSize;
      return array.slice(startIndex, endIndex);
    }),

    pageCount,
  };
};

export const projectStatusHelper = (project: ProjectType) => {
  let statusObj = {
    label: "Uploading Files",
    bgColor: "#F3F3F3",
    textColor: "#9EA7B7",
  };
  if (project?.dataProcessed) {
    statusObj.label = "Data Processed";
  }
  if (project?.reportGenerated) {
    statusObj.label = "Report Generated";
  }

  if (project?.projectType === "survey") {
    if (project?.tabular) {
      if (project.tabular.loadMetadata?.finished)
        statusObj.label = "Loaded metadata";

      if (project.tabular?.get_categorical_summaries?.running)
        statusObj.label = "Running categorical summaries";

      if (project.tabular?.get_summary_of_columns?.running)
        statusObj.label = "Running columns summaries";

      if (
        project.tabular?.get_categorical_summaries?.finished ||
        project.tabular?.get_summary_of_columns?.finished
      )
        statusObj.label = "Report Generated";
    }
    statusObj.bgColor = "lightgreen";
    statusObj.textColor = "black";
  }

  return statusObj;
};
