import React from "react";

import AuthSecondaryWrapper from "../../components/AuthSecondaryWrapper";
import If from "../../components/If";
import CreditCards from "./CreditCards";
import BillingHistory from "./BillingHistory";
import { useFetchBackend } from "../../services/hooks";

const Payments = () => {
  const paymentMethods = useFetchBackend("/payments/payment-methods");
  const billingHistory = useFetchBackend<{ data?: any[] }>(
    "/payments/billing-history"
  );

  return (
    <AuthSecondaryWrapper>
      <>
        <If condition={paymentMethods.firstLoad}>
          <CreditCards cards={paymentMethods?.data?.data || []} />
        </If>

        <If condition={billingHistory.firstLoad}>
          {billingHistory.data?.data && (
            <BillingHistory billingHistory={billingHistory.data?.data} />
          )}
        </If>
      </>
    </AuthSecondaryWrapper>
  );
};

export default Payments;
