import React, { useEffect, useState } from "react";
import styled from "styled-components";
import firebase from "firebase/app";

import { useFirestore } from "context/firestore";

import {
  InputFull as Input,
  OverlayBackground,
  OverlayInner,
  PrimaryBtn,
  SecondaryBtn,
} from "components/ThemeComponents";
import {
  Keyword,
  KeywordText2,
  Keywords,
  Row1,
  SelectedCheckMarkDiv,
  UnselectedCheckMarkDiv,
} from "../projectComponents";
import { H1, Label2 } from "components/CommonComponents";
import CheckmarkNewImg from "assets/checkmark-new.png";
import Spinner from "components/Spinner";

const QuestionsAndAnswers = ({ projectid, keywords, dataProcessService }) => {
  const [loading, setLoading] = useState(false);
  const [projectKeywords, setProjectKeywords] = useState([]);

  const [projectQuestions, setProjectQuestions] = useState([]);

  const [showQuestionModel, setShowQuestionModel] = useState(false);
  const [question, setQuestion] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const {
    createProjectQuestion,
    getProjectQuestions,
    getProjectQuestionAnswers,
  } = useFirestore();

  const handleChange = (e) => {
    setQuestion(e.target.value);
  };

  const deselectKeywordHelper = (word) => {
    // remove from array
    setSelectedKeywords(selectedKeywords.filter((a) => a !== word));
  };

  const selectKeywordHelper = (word) => {
    // add from array
    setSelectedKeywords(
      // Replace the state
      [
        // with a new array
        ...selectedKeywords, // that contains all the old items
        word, // and one new item at the end
      ]
    );
  };

  const showQuestionModelHelper = () => {
    setShowQuestionModel(true);
    setQuestion("");
    setSelectedKeywords([]);
  };

  const saveNewQuestion = async () => {
    if (question === "" || selectedKeywords.length === 0) {
      alert("Please enter a question and select at least one keyword.");
    } else {
      setLoading(true);
      let newQuestion = await createProjectQuestion(
        projectid,
        selectedKeywords,
        question
      );
      console.log("Question saved at ID: ");
      console.log(newQuestion.f_.path.segments[1]);
      setShowQuestionModel(false);
      setLoading(false);
      dataProcessService.startQuestionProcessing(
        newQuestion.f_.path.segments[1]
      );
      getProjectQuestionsAnswers();
    }
  };

  const getProjectQuestionsAnswers = async () => {
    // get all the questions and the questionAnswers for this projectid

    let projectQuestionsResponse = await getProjectQuestions(projectid);
    console.log(projectQuestionsResponse);
    let projectQuestionAnswersResponse = await getProjectQuestionAnswers(
      projectid
    );
    console.log(projectQuestionAnswersResponse);

    let mergedProjectQuestionsAndAnswers = mergeProjectQuestionsAndAnswers(
      projectQuestionsResponse,
      projectQuestionAnswersResponse
    );
    setProjectQuestions(mergedProjectQuestionsAndAnswers);
  };

  useEffect(() => {
    getProjectQuestionsAnswers();
    setProjectKeywords(keywords);

    const db = firebase.firestore();
    db.collection("questionAnswers").onSnapshot((doc) => {
      if (doc) {
        getProjectQuestionsAnswers();
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showQuestionModel && (
        <OverlayBackground>
          <OverlayInner>
            <ModalInner>
              <Row1>
                <H1>Add a Question</H1>

                <CloseBtn
                  onClick={() => setShowQuestionModel(false)}
                  src={require("assets/overlay-close.png")}
                />
              </Row1>

              <Label2>1) Add your question:</Label2>
              <Input
                width={"100%"}
                onChange={handleChange}
                value={question}
                placeholder="Enter your question here..."
              />

              <Label2>2) Select the keywords related to your question:</Label2>
              <Keywords>
                {projectKeywords.map((k, id) => {
                  let selected = selectedKeywords.includes(k?.word);
                  return (
                    <Keyword
                      key={id}
                      selected={selected}
                      onClick={() =>
                        selected
                          ? deselectKeywordHelper(k?.word)
                          : selectKeywordHelper(k?.word)
                      }
                    >
                      <KeywordText2 selected={selected}>
                        {k?.word} <span>({k?.count})</span>
                        {selectedKeywords.includes(k?.word)}
                      </KeywordText2>

                      {selected ? (
                        <SelectedCheckMarkDiv>
                          <img alt="Check mark" src={CheckmarkNewImg} />
                        </SelectedCheckMarkDiv>
                      ) : (
                        <UnselectedCheckMarkDiv />
                      )}
                    </Keyword>
                  );
                })}
              </Keywords>

              {loading ? (
                <Spinner />
              ) : (
                <>
                  <PrimaryBtn onClick={() => saveNewQuestion()}>
                    Submit
                  </PrimaryBtn>
                  <SecondaryBtn onClick={() => setShowQuestionModel(false)}>
                    Cancel
                  </SecondaryBtn>
                </>
              )}
            </ModalInner>
          </OverlayInner>
        </OverlayBackground>
      )}

      <Main>
        {projectQuestions.length > 0 ? (
          projectQuestions.map((q, id) => {
            return (
              <QuestionDiv key={id}>
                <div className="row">
                  <p className="question">{q?.question}</p>
                  <img
                    className="profile"
                    src={require("assets/user-icon.png")}
                  />
                </div>

                <div className="row spaceabove">
                  <img
                    className="profile2"
                    src={require("assets/chubbs-response-icon.png")}
                  />
                  <p className="answer">
                    {q.answer ? q.answer : "Loading . . ."}
                  </p>
                </div>
              </QuestionDiv>
            );
          })
        ) : (
          <EmptyState>You haven't asked any questions yet!</EmptyState>
        )}
        <FalseInput onClick={() => showQuestionModelHelper()}>
          Ask a question of your data...
        </FalseInput>
      </Main>
    </>
  );
};

export default QuestionsAndAnswers;

const QuestionDiv = styled.div`
  margin-bottom: 30px;

  .col {
  }

  .row {
    display: flex;
    align-items: flex-end;
  }

  .question {
    background-color: #e4fd90;
    padding: 20px;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    flex: 1;
    margin: 0;
    font-weight: 600;
  }

  .profile {
    width: 30px;
    height: 30px;
    margin-left: 15px;
  }

  .profile2 {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .answer {
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    flex: 1;
    margin: 0;
  }

  .spaceabove {
    margin-top: 20px;
  }
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const EmptyState = styled.p`
  margin: 30px auto;
  font-size: 25px;
  color: #cbcbcb;
  font-weight: 300;
`;

const FalseInput = styled.p`
  margin: 15px 0;
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  padding: 8px 20px;
  width: calc(100% - 40px);
  color: #c1c1c1;
  border: 1px solid #cbcbcb;
  cursor: pointer;
`;

const ModalInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const CloseBtn = styled.img`
  width: 30px;
  height: 30px;
`;

const mergeProjectQuestionsAndAnswers = (questions, answers) => {
  let returnArr = [];
  console.log(questions);
  console.log(answers);
  for (let i = 0; i < questions.length; i++) {
    let current = questions[i];
    for (let j = 0; j < answers.length; j++) {
      if (answers[j].questionId === questions[i].id) {
        current.answer = answers[j].answer;
      }
    }
    returnArr.push(current);
  }

  return orderByCreatedon(returnArr);
};

const orderByCreatedon = (arr) => {
  return arr.sort((a, b) => {
    return new Date(a?.createdon) - new Date(b?.createdon);
  });
};
