import React, { FC, useEffect, useState } from "react";
import { H1 } from "components/CommonComponents";
import { DbQueryListenerType } from "services/hooks";
import { Container, R1, R2, R3 } from "../projectComponents";
import {
  DataHeaderLeft,
  SurveyStratificationRow,
} from "./surveyComponents";
import { Input } from "components/ThemeComponents";
// import ChatBox from "./ChatBox";
import { useFirestore } from "context/firestore";
import { DataHeaderDiv } from "../Survey/surveyComponents";



const GenerateKeywordSummaries = ({
  setKeyword,
  setSelectedTranscriptFiles,
  projectFiles,
  keyword,
  selectedTranscriptFiles,
  selectColumnHeaderHelper,
}: any) => {
  const [transcripts, setTranscripts] = useState<any[]>([]);
  useEffect(() => {
    let localProjectFilesBuilder: any[] = [];
    if (projectFiles) {
      for (var i = 0; i < projectFiles.length; i++) {
        const currentFile = projectFiles[i].data();
        currentFile.fileId = projectFiles[i].id;
        localProjectFilesBuilder.push(currentFile);
      }
      setTranscripts(localProjectFilesBuilder);
    }
  }, [projectFiles]);
  return (
    <Container>
      <H1>Survey Test Project</H1>
      <R1>Keyword Analysis</R1>

      <Input
        value={keyword || ""}
        onChange={(e: any) => setKeyword(e.target.value)}
      />
      <R3>Select the transcripts you want to analyze.</R3>

      {transcripts.map((header: any, index: any) => {
        return (
          <DataHeaderDiv
            key={index}
            onClick={() =>
              selectedTranscriptFiles.length < 2
                ? selectColumnHeaderHelper(header.name)
                : selectedTranscriptFiles.includes(header.name)
                ? selectColumnHeaderHelper(header.name)
                : null
            }
            selected={selectedTranscriptFiles?.includes(header.name)}
            selectable={selectedTranscriptFiles.length < 2}
            qualitative={true}
          >
            <DataHeaderLeft>
              <p className="headername">{header.name} </p>
            </DataHeaderLeft>
            <div className="data-header-right">
              <img
                className="data-header-right-check-icon"
                src={require("assets/checkmark-new.png")}
              />
              <div className="data-header-right-circle" />
            </div>
          </DataHeaderDiv>
        );
      })}
    </Container>
  );
};

const SelectData: FC<SelectDataProps> = ({
  projectFiles,
  setSelectedTranscriptFiles,
  setKeyword,
  selectedTranscriptFiles,
  keyword,
  ...props
}) => {

  const selectColumnHeaderHelperQuant = (headerName: string) => {
    if (selectedTranscriptFiles.includes(headerName)) {
      setSelectedTranscriptFiles(
        selectedTranscriptFiles.filter((item) => item !== headerName)
      );
    } else {
      setSelectedTranscriptFiles([...selectedTranscriptFiles, headerName]);
    }
  };

  if (props.surveyAnalysisType === "keyword-summary") {
    return (
      <GenerateKeywordSummaries
        keyword={keyword}
        setKeyword={setKeyword}
        setSelectedTranscriptFiles={setSelectedTranscriptFiles}
        projectFiles={projectFiles}
        selectedTranscriptFiles={selectedTranscriptFiles}
        selectColumnHeaderHelper={selectColumnHeaderHelperQuant}
        {...props}
      />
    );
  } else {
    return (
      <div>
        {/* <ChatBox 
        tableMetadata={parsedTableMetadata}
        setQuestionAnsData={setQuestionAnsData}
        questionAnsData={questionAnsData}
        createSurveyQuestionAns={createProjectQuestionAns}
        getSurveyQuestionAns={getSurveyQuestionAnsList}
        projectid={projectid}
        dataProcesss={dataProcesss}
        tabularFields={tabularFields}
        /> */}
      </div>
    );
  }
};

export default SelectData;

type TableMetadataType = {
  dataType: string;
  uniqueValues: number;
  columnName: string;
};

const parseDataFields = (data: any) =>
  data
    .map((f: any) => ({
      ...f,
      dataType: f.uniqueValues > 10 ? "qualitative" : "quantitative",
    }))
    .sort((a: any, b: any) => {
      if (a.dataType === b.dataType) return 0;
      if (a.dataType === "quantitative") return 1;
      return -1;
    }) as TableMetadataType[];

type SelectDataProps = {
  projectFiles: any;
  setSelectedTranscriptFiles: React.Dispatch<React.SetStateAction<string[]>>;
  setKeyword: React.Dispatch<React.SetStateAction<string | null>>;
  surveyAnalysisType: string | null;
  selectedTranscriptFiles: string[];
  keyword: string | null;
};
