import React, { useEffect, useMemo, useState } from "react";

import { Main, Container, P1, Step2Img } from "../projectComponents";
import { H1 } from "components/CommonComponents";
import ExploreAnalysis from "./ExploreAnalysis";
import ChooseAnalysisType from "./ChooseAnalysisType";
import SelectData from "./SelectData";
import UploadFile from "./UploadFile";
import TopBar from "./TopBar";
import { useParams } from "react-router-dom";
import { FilesOnDb, ProjectApiState, useFirestore } from "context/firestore";
import { useAsync } from "hooks";
import { useShowModal } from "context/Modal";
import AlertModal from "components/AlertModal";
import {
  useFirestoreFieldListener,
  useFirestoreQueryListener,
} from "services/hooks";
import dataProcesss from "services/DataProcess";
import ChatBox from "./ChatBox";

const tabular_fields = ["tabular"];
const proj_col = "projects";

const useWatchTabularFields = (projectId: string) => {
  const { tabular } = useFirestoreFieldListener<{
    tabular: {
      get_summary_of_columns: ProjectApiState;
      get_categorical_summaries: ProjectApiState;
      get_summary_of_keyword_tabular: ProjectApiState;
      answer_question_from_survey: ProjectApiState;
    };
  }>(proj_col, projectId, tabular_fields);

  return tabular;
};

export type TabularFields = ReturnType<typeof useWatchTabularFields>;
export type KeywordsData = {
  qualitativeFields: string[];
  keyword: string;
};

export type QuestionAnsData = {
  qualitativeFields: string[];
  question: string;
};
const useListenForTableMetadata = (projectId: string) => {
  const { db } = useFirestore();
  const query = useMemo(
    () => db.collection("tableMetadata").where("projectId", "==", projectId),
    [db, projectId]
  );

  const metadata = useFirestoreQueryListener(query);

  return metadata;
};

const useListenForProjectFilesLength = (projectId: string) => {
  const { db } = useFirestore();
  const query = useMemo(
    () => db.collection("files").where("projectId", "==", projectId),
    [db, projectId]
  );

  const filesList = useFirestoreQueryListener<FilesOnDb>(query);

  return filesList.length;
};

export type TabularKeywordSummaryType = {
  createdon: number;
  name: string;
  keyword: string;
  summary: string;
  selectedQualitativeFields: any;
};

const useListenForKeywordSummaries = (projectId: string) => {
  const { db } = useFirestore();
  const query = useMemo(
    () =>
      db.collection("tableKeywordSummary").where("projectId", "==", projectId),
    [db, projectId]
  );

  const transcriptSummaryList =
    useFirestoreQueryListener<TabularKeywordSummaryType>(query);

  return transcriptSummaryList;
};

export const Survey = () => {
  const showModal = useShowModal();
  const [step, setStep] = useState<number>(1);
  const [surveyAnalysisType, setSurveyAnalysisType] = useState<string | null>(
    null
  );
  const { projectid } = useParams<{ projectid: string }>();
  const { getProjectByProjectId } = useFirestore();
  const project = useAsync(getProjectByProjectId, projectid);
  const projectFilesLength = useListenForProjectFilesLength(projectid);

  const tabularFields = useWatchTabularFields(projectid);
  const tableMetadata = useListenForTableMetadata(projectid);
  const keywordSummaries = useListenForKeywordSummaries(projectid);

  const { createProjectKeywords } = useFirestore();

  const [selectedUploadedFileHeadersQual, setSelectedUploadedFileHeadersQual] =
    useState<string[]>([]);
  const [
    selectedUploadedFileHeadersQuant,
    setSelectedUploadedFileHeadersQuant,
  ] = useState<string[]>([]);
  const [keywordsData, setKeywordsData] = useState<KeywordsData>({
    qualitativeFields: [],
    keyword: "",
  });
  const [questionAnsData, setQuestionAnsData] = useState<QuestionAnsData>({
    qualitativeFields: [],
    question: "",
  });
  const hasStartedRunningApis =
    tabularFields?.get_summary_of_columns?.started ||
    tabularFields?.get_categorical_summaries?.started;

  const projectTitle = project.data?.name;

  const hasSelectedData = () => {
    // if (hasStartedRunningApis) return true;
    if (surveyAnalysisType === "just-text-responses") {
      if (tabularFields?.get_summary_of_columns?.started) return true;
      if (selectedUploadedFileHeadersQual.length > 0) return true;
      else return false;
    }

    if (surveyAnalysisType === "stratified-text-responses") {
      if (tabularFields?.get_categorical_summaries?.started) return true;
      if (
        selectedUploadedFileHeadersQual.length > 0 &&
        selectedUploadedFileHeadersQuant.length > 0
      )
        return true;
      else return false;
    }
    // if (tabularFields?.get_summary_of_columns?.error) return false;

    if (surveyAnalysisType === "keyword-summary") {
      return true;
      // if(tabularFields?.get_summary_of_keyword_tabular?.started) return true
      // if(keywordsData.keyword && keywordsData.qualitativeFields.length > 0) return true
      // else return false;
    }

    return false;
  };

  const finishedSteps: { [key: number]: () => boolean | undefined } = {
    1: () => projectFilesLength > 0,
    2: () => surveyAnalysisType != null,
    3: () => tableMetadata.length > 0,
    4: hasSelectedData,
    5: () => {
      if (surveyAnalysisType === "keyword-summary") {
        return true;
      }
      if (!hasStartedRunningApis) return false;

      if (
        surveyAnalysisType === "just-text-responses" &&
        tabularFields.get_summary_of_columns?.finished
      ) {
        return true;
      }

      if (
        surveyAnalysisType === "stratified-text-responses" &&
        tabularFields.get_categorical_summaries?.finished
      ) {
        return true;
      }

      return false;
    },
    6: () => false,
  };

  const triggerStepSideEffects = async (nextStep: number) => {
    switch (nextStep) {
      case 5:
        try {
          if (surveyAnalysisType === "just-text-responses") {
            await dataProcesss.tabular.get_summary_of_columns({
              projectId: projectid,
              qualitativeFields: selectedUploadedFileHeadersQual,
            });
          } else if (surveyAnalysisType === "stratified-text-responses") {
            await dataProcesss.tabular.get_categorical_summaries({
              projectId: projectid,
              qualitativeFields: selectedUploadedFileHeadersQual,
              quantitativeFields: selectedUploadedFileHeadersQuant,
            });
          } else if (surveyAnalysisType === "keyword-summary") {
          } else {
            throw new Error("Invalid analysis type");
          }
        } catch (e) {
          setSelectedUploadedFileHeadersQual([]);
          setSelectedUploadedFileHeadersQuant([]);
          throw e;
        }
    }
  };

  const triggerNextStep = (fromAction = true) => {
    const checkStepFunc = finishedSteps[step];
    if (!checkStepFunc()) {
      if (fromAction)
        showModal(<AlertModal>Finish this step first</AlertModal>);
      return;
    } else {
      const nextStep = step + 1;
      if (fromAction) {
        triggerStepSideEffects(nextStep).catch(() => {
          showModal(<AlertModal>Something went wrong</AlertModal>);
          setStep(step);
        });
      }

      setStep(nextStep);
    }
  };

  useEffect(() => {
    // go to last available page

    if (!project.error && project.firstLoad) {
      triggerNextStep(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    step,
    project.firstLoad,
    project.loading,
    projectFilesLength,
    tableMetadata.length,
    tabularFields,
    hasStartedRunningApis,
  ]);

  // TBD Remove this later
  const parseDataFields = (data: any) =>
    data
      .map((f: any) => ({
        ...f,
        dataType: f.uniqueValues > 10 ? "qualitative" : "quantitative",
      }))
      .sort((a: any, b: any) => {
        if (a.dataType === b.dataType) return 0;
        if (a.dataType === "quantitative") return 1;
        return -1;
      }) as any;

  const parsedTableMetadata = tableMetadata[0]
    ? parseDataFields(tableMetadata[0].data().dataFields)
    : null;

  return (
    <Main>
      <TopBar step={step} topBarNextFunction={triggerNextStep} />

      {step === 1 && (
        <UploadFile triggerNextStep={triggerNextStep} project={project.data} />
      )}
      {step === 2 && (
        <ChooseAnalysisType
          setSurveyAnalysisType={setSurveyAnalysisType}
          surveyAnalysisType={surveyAnalysisType}
          tabularFields={tabularFields}
        />
      )}
      {step === 3 && (
        <Container alignitems={"center"}>
          <br />
          <H1>Extracting data...</H1>
          <P1>This shouldn't take too long!</P1>
          <Step2Img src={require("assets/generating-report-chubbs.png")} />
        </Container>
      )}
      {step === 4 && (
        <SelectData
          selectedUploadedFileHeadersQual={selectedUploadedFileHeadersQual}
          setSelectedUploadedFileHeadersQual={
            setSelectedUploadedFileHeadersQual
          }
          selectedUploadedFileHeadersQuant={selectedUploadedFileHeadersQuant}
          setSelectedUploadedFileHeadersQuant={
            setSelectedUploadedFileHeadersQuant
          }
          surveyAnalysisType={surveyAnalysisType}
          tableMetadata={tableMetadata}
          keywordsData={keywordsData}
          setKeywordsData={setKeywordsData}
          setQuestionAnsData={setQuestionAnsData}
          questionAnsData={questionAnsData}
          projectid={projectid}
          dataProcesss={dataProcesss}
          tabularFields={tabularFields}
        />
      )}
      {step === 5 && (
        <Container alignitems={"center"}>
          <br />
          <H1>Generating report...</H1>
          <P1>We'll let you know when the process is finished via email.</P1>
          <Step2Img src={require("assets/generating-report-chubbs.png")} />
        </Container>
      )}
      {step === 6 && (
        <ExploreAnalysis
          projectTitle={projectTitle}
          tabularFields={tabularFields}
          projectId={projectid}
          surveyAnalysisType={surveyAnalysisType}
          tableMetadata={parsedTableMetadata}
          keywordSummaries={keywordSummaries}
        />
      )}
    </Main>
  );
};
