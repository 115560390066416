import React from "react";
import styled from "styled-components";

const ProjectCardSpinner = () => {
  return (
    <Main>
      <div className="spinner">
        <div className="spinner-inner"></div>
      </div>
    </Main>
  );
};

export default ProjectCardSpinner;

const Main = styled.div`
  .spinner {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0px;
  }

  .spinner-inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #f3f3f3;
    border-top-color: black;
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
