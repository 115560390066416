import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
  }

  body, input, button {
    font-family: 'Outfit', sans-serif;
  }
`;

export default GlobalStyles;
