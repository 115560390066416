import React, { FC, useEffect, useState } from "react";
import { H1 } from "components/CommonComponents";
import {
  DataHeaderDiv,
  DataHeaderLeft,
  SurveyReportQuestion,
} from "./surveyComponents";

import {
  Row,
  ReportContainer,
  ReportInner,
  R1,
  R3,
  R2,
  Row3,
  Row6,
  Col6,
  Col3,
  ReportInnerLoading,
  SummaryContainer,
} from "../projectComponents";
import { TabularFields } from ".";
import { useFirestore } from "context/firestore";
import { useAsync } from "hooks";
import { LoadingDots } from "./ChatBox";
import { Row4Flex, WhiteInput } from "../Transcript/surveyComponents";
import { PrimaryBtn } from "components/ThemeComponents";
import dataProcesss from "services/DataProcess";
import Select from "react-select";
import { FlexBox } from "../Transcript/ChatBox";

const TextResponsesAnalysis: FC<{
  projectId: string;
  projectTitle?: string;
}> = ({ projectId, projectTitle }) => {
  const { getTableColumnSummaries } = useFirestore();
  const { loading, data: summaries } = useAsync(
    getTableColumnSummaries,
    projectId
  );

  if (loading) return <div>Loading...</div>;

  return (
    <ReportContainer>
      <H1>{projectTitle}</H1>
      <br />
      <R1>Columns analyzed</R1>
      <Row>
        {summaries?.data.summaries.map(({ qualitativeField }, i) => (
          <SurveyReportQuestion key={i}>
            <p>{qualitativeField}</p>
          </SurveyReportQuestion>
        ))}
      </Row>

      {summaries?.data.summaries.map(({ qualitativeField, summary }, index) => (
        <React.Fragment key={index}>
          <R1>{qualitativeField}</R1>
          <ReportInner>{summary}</ReportInner>
        </React.Fragment>
      ))}
    </ReportContainer>
  );
};

const StratifiedResponsesAnalysis: FC<{
  projectId: string;
  projectTitle?: string;
}> = ({ projectId, projectTitle }) => {
  const { getProjectTabularCategoricalSummaries } = useFirestore();
  const { loading, data: summaries } = useAsync(
    getProjectTabularCategoricalSummaries,
    projectId
  );

  if (loading) return <div>Loading...</div>;

  return (
    <ReportContainer>
      <H1>{projectTitle}</H1>
      <br />

      {summaries?.data.summaries.map((sum, index) => (
        <div key={index}>
          <R1>
            Qualitative: {sum.qualitativeField} | Quantitative:{" "}
            {sum.quantitativeField} | Value: {sum.uniqueValue}
          </R1>

          <br />
          <ReportInner>{sum.summary}</ReportInner>
        </div>
      ))}
    </ReportContainer>
  );
};

const GenerateKeywordSummaries = ({
  projectid,
  keywordSummaries,
  tableMetadata,
  tabularFields,
}: any) => {
  const [keyword, setKeyword] = useState<string | null>(null);
  const [selectedTranscriptFiles, setSelectedTranscriptFiles] = useState<any[]>(
    []
  );
  const { getTranscriptKeywords, createProjectKeywords } = useFirestore();
  const [summaries, setSummaries] = useState<any>([]);
  const selectColumnHeaderHelper = (data: any) => {
    setSelectedTranscriptFiles(data);
  };
  useEffect(() => {
    let localSummaries: any[] = [];
    if (keywordSummaries) {
      for (var i = 0; i < keywordSummaries.length; i++) {
        const currentSummary = keywordSummaries[i].data();
        currentSummary.id = keywordSummaries[i].id;
        localSummaries.push(currentSummary);
      }
      setSummaries(
        localSummaries.sort((a: any, b: any) => b.createdOn - a.createdOn)
      );
    }
  }, [keywordSummaries]);
  const [btnLoading, setBtnLoading] = useState(false);
  const generateSummary = async () => {
    try {
      setBtnLoading(true);
      const _keyword = keyword;
      if (!_keyword) throw new Error("Keyword not selected!");
      const _files = selectedTranscriptFiles;
      setKeyword("");
      setSelectedTranscriptFiles([]);
      console.log(
        "file",
        _files.map((i: any) => i.value)
      );
      const _newKeyword = await createProjectKeywords(
        projectid,
        _keyword,
        _files.map((i: any) => i.value)
      );
      const _id = _newKeyword.f_.path.segments[1];
      await dataProcesss.tabular.get_keyword_summary({
        projectId: projectid,
        keyword_id: _id,
      });
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setBtnLoading(false);
    }
  };
  return (
    <ReportContainer>
      <H1>Keyword Summary</H1>

      {summaries && summaries.length < 5 && (
        <>
          <Row4Flex>
            <Col6>
              <WhiteInput
                placeholder="Enter keyword"
                value={keyword || ""}
                onChange={(e: any) => setKeyword(e.target.value)}
                maxLength={30}
                disabled={btnLoading}
              />
            </Col6>
            <Col3>
              {btnLoading ? (
                <PrimaryBtn
                  onClick={() => generateSummary()}
                  disabled={!(keyword && selectedTranscriptFiles.length > 0)}
                >
                  Generating Summary...
                </PrimaryBtn>
              ) : (
                <PrimaryBtn
                  onClick={() => generateSummary()}
                  disabled={
                    !(
                      keyword &&
                      selectedTranscriptFiles.length > 0 &&
                      !tabularFields?.get_summary_of_keyword_tabular?.running
                    )
                  }
                >
                  Generate Summary
                </PrimaryBtn>
              )}
            </Col3>
          </Row4Flex>
        </>
      )}
      {keyword && (
        <>
          <R3>Select the qualitative fields you want to analyze.</R3>

          <FlexBox>
            <Col6>
              <Select
                options={tableMetadata
                  .filter((header: any) => header.dataType === "qualitative")
                  .map((i: any) => ({
                    value: i.columnName,
                    label: i.columnName,
                  }))}
                placeholder="Select qualitative columns"
                value={selectedTranscriptFiles}
                onChange={selectColumnHeaderHelper}
                isOptionDisabled={() => selectedTranscriptFiles.length >= 5}
                isSearchable={true}
                isMulti={true}
              />
            </Col6>
          </FlexBox>
          {/* {transcripts.map((header: any, index: any) => {
            return (
              <DataHeaderDiv
                key={index}
                onClick={() =>
                  selectedTranscriptFiles.length < 2
                    ? selectColumnHeaderHelper(header.fileId)
                    : selectedTranscriptFiles.includes(header.fileId)
                    ? selectColumnHeaderHelper(header.fileId)
                    : null
                }
                selected={selectedTranscriptFiles?.includes(header.fileId)}
                selectable={selectedTranscriptFiles.length < 2}
                qualitative={true}
              >
                <DataHeaderLeft>
                  <p className="headername">{header.name} </p>
                </DataHeaderLeft>
              </DataHeaderDiv>
            );
          })} */}
        </>
      )}

      {summaries?.map((i: any) => (
        <SummaryContainer>
          <R1>{i.keyword}</R1>
          {i.summary ? (
            <ReportInner>
              <p>{i.summary}</p>
            </ReportInner>
          ) : (
            <ReportInnerLoading>
              <LoadingDots />
            </ReportInnerLoading>
          )}
        </SummaryContainer>
      ))}
    </ReportContainer>
  );
};

const ExploreAnalysis: FC<{
  tabularFields: TabularFields;
  projectId: string;
  projectTitle?: string;
  surveyAnalysisType: string | null;
  tableMetadata: any;
  keywordSummaries: any[];
}> = ({
  tabularFields,
  projectId,
  projectTitle,
  surveyAnalysisType,
  tableMetadata,
  keywordSummaries,
}) => {
  const justTextResponses = surveyAnalysisType === "just-text-responses";
  const stratifiedResponses =
    surveyAnalysisType === "stratified-text-responses";
  const keywordResponses = surveyAnalysisType === "keyword-summary";
  if (justTextResponses) {
    return (
      <TextResponsesAnalysis
        projectId={projectId}
        projectTitle={projectTitle}
      />
    );
  } else if (stratifiedResponses) {
    return (
      <StratifiedResponsesAnalysis
        projectId={projectId}
        projectTitle={projectTitle}
      />
    );
  } else if (keywordResponses) {
    return (
      <GenerateKeywordSummaries
        projectid={projectId}
        keywordSummaries={keywordSummaries}
        tableMetadata={tableMetadata}
        tabularFields={tabularFields}
      />
    );
  } else return null;
};

export default ExploreAnalysis;
