import React, { useState } from "react";
import styled from "styled-components";
import AlertModal from "components/AlertModal";

import { PrimaryBtn } from "components/ThemeComponents";
import { useShowModal } from "context/Modal";
import dataProcesss from "services/DataProcess";

function getCurrentDate() {
  var currentDate = new Date();

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var formattedDate =
    monthNames[currentDate.getMonth()] +
    " " +
    ("0" + currentDate.getDate()).slice(-2) +
    "- " +
    currentDate.getFullYear();

  return formattedDate;
}

const PDFReportManager = (passedobj: any) => {
  const { projectid, projectName } = passedobj;
  const [loading, setLoading] = useState(false);
  const showModal = useShowModal();

  const handleStartDownload = async () => {
    const pdfData = await dataProcesss.createReportPDF(projectid);
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(pdfData);
    const filename = `${projectName} - Final Report - ${getCurrentDate()}.pdf`;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      await handleStartDownload();
    } catch (e) {
      console.error(e);
      showModal(
        <AlertModal>
          Something went wrong
          <br />
          Try again later.
        </AlertModal>
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Main>
      <PrimaryBtn disabled={loading} onClick={handleClick}>
        Export PDF
      </PrimaryBtn>
    </Main>
  );
};

export default PDFReportManager;

const Main = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

