import styled from "styled-components";

export const TH1 = styled.h1``;

export const TH2 = styled.h2``;

export const TS1 = styled.p``;

export const Input = styled.input`
  margin: 15px 0;
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  padding: 8px 20px;
  width: calc(100% - 42px);
  max-width: 200px;
  border: 1px solid #bbb;
`;

export const InputFull = styled(Input)`
  max-width: initial;
`;

export const Textarea = styled.textarea`
  margin: 15px 0;
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  padding: 8px 20px;
  width: calc(100% - 40px);
  max-width: 200px;
  border: 1px solid #bbb;
  height: 150px;

  ::placeholder {
    font-family: "Outfit", sans-serif;
  }
`;

export const PrimaryBtn = styled.button<{ disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? "#d4d4d4" : "black")};
  color: white;
  border-radius: 25px;
  padding: 6px 18px;
  border: 2px solid ${({ disabled }) => (disabled ? "#999" : "black")};
  margin: 10px 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: 18px;

  :hover {
    background-color: ${({ disabled }) => (disabled ? "#d4d4d4" : "white")};
    color: ${({ disabled }) => (disabled ? "white" : "black")};
  }
`;

export const SecondaryBtn = styled.button`
  border: 2px solid black;
  background-color: white;
  color: black;
  border-radius: 25px;
  padding: 6px 18px;
  margin: 10px 0;
  cursor: pointer;

  :hover {
    background-color: black;
    color: white;
  }
`;

export const DeleteBtn = styled(PrimaryBtn)`
  background-color: #ff0000;
  border-color: #ff0000;
  border-size: 1px;
  font-size: 10px;
  margin: 0;
  margin-left: 10px;
  padding: 3px 5px;
`;

export const DeleteCancelBtn = styled(SecondaryBtn)`
  border-color: #ff0000;
  border-size: 1px;
  color: #ff0000;
  font-size: 10px;
  padding: 3px 5px;
  margin: 0;
`;

export const OverlayBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(166, 166, 166, 0.47);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const OverlayInner = styled.div`
  background-color: white;
  padding: 55px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: calc(90% - 110px);
  max-height: calc(90vh - 110px);
`;
