import React, { useState, useEffect, ReactNode, FC } from "react";

interface DataLoaderProps {
  condition: boolean;
  loadingComponent?: ReactNode;
}

const LoadingComponent: FC = () => <div>Loading...</div>;

const If: FC<DataLoaderProps> = ({
  condition,
  children,
  loadingComponent = <LoadingComponent />,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (condition) {
      setIsLoading(false);
    }
  }, [condition]);

  return <>{isLoading ? loadingComponent : children}</>;
};

export default If;
