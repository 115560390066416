import {
  Input,
  PrimaryBtn,
  SecondaryBtn,
  TH2,
  TS1,
} from "components/ThemeComponents";
import { PROJECT_TYPES_MAP } from "context/firestore";
import { useHideModal } from "context/Modal";
import React, { useState } from "react";
import { ProjectTypeSelect } from "./styles";

const NewProjectModal = ({
  handleCreateProject,
}: {
  handleCreateProject: (
    projectName: string | undefined,
    projectType: string
  ) => Promise<void>;
}) => {
  const [projectName, setProjectName] = useState<string>();
  const hideModal = useHideModal();
  const [loading, setLoading] = useState(false);
  const [projectType, setProjectType] = useState('survey');

  const handleClick = async () => {
    setLoading(true);
    try {
      await handleCreateProject(projectName, projectType);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TH2>Let's create a project!</TH2>
      <TS1>Name your project:</TS1>
      <Input
        value={projectName || ""}
        onChange={(e: any) => {
          setProjectName(e.target.value);
        }}
      />
      <ProjectTypeSelect onChange={(e) => setProjectType(e.target.value)}>
        {Object.keys(PROJECT_TYPES_MAP).map((tp, i) => (
          <option value={tp} key={i}>
            {PROJECT_TYPES_MAP[tp as keyof typeof PROJECT_TYPES_MAP]}
          </option>
        ))}
      </ProjectTypeSelect>
      <PrimaryBtn disabled={loading} onClick={handleClick}>
        Start
      </PrimaryBtn>
      <SecondaryBtn onClick={hideModal}>Cancel</SecondaryBtn>
    </>
  );
};

export default NewProjectModal;
