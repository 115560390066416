import React, { Dispatch, FC } from "react";
import { H1 } from "components/CommonComponents";
import { Row4, Container, R3, R1, R2 } from "../projectComponents";
import styled from "styled-components";

export const TypeSelectorBtn = styled.div<{ selected: boolean }>`
  border: 2px solid black;
  border-radius: 8px;
  padding: 50px 40px;
  flex: 1;
  margin: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: ${({ selected }) => (selected ? "black" : "white")};

  p {
    color: ${({ selected }) => (selected ? "white" : "black")};
  }

  :hover {
    background-color: ${({ selected }) => (selected ? "#333" : "#eee")};
  }
`;

export const TypeSelectorBtnCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 20px;
  border: 2px solid black;
  margin-left: auto;
  margin-top: 20px;
  background-color: white;
`;

export const ReportGeneratedStatusBtn = styled.button`
  border: 2px solid green;
  color: white;
  background: green;
  padding: 8px 16px;
  border-radius: 8px;
  flex: 1;
`;

const ChooseAnalysisType: FC<{
  surveyAnalysisType: string | null;
  setSurveyAnalysisType: Dispatch<string | null>;
  transcriptFields: any;
  project: any;
}> = ({
  setSurveyAnalysisType,
  surveyAnalysisType,
  transcriptFields,
  project,
}) => {
  return (
    <Container>
      <H1>{project?.data?.name}</H1>
      <R1>Select analysis mode</R1>
      <R2>Choose from the analysis below.</R2>

      <Row4>
        <TypeSelectorBtn
          onClick={() => setSurveyAnalysisType("just-text-responses")}
          selected={surveyAnalysisType === "just-text-responses"}
        >
          <R1>Transcript Summaries</R1>
          <R3>
            You'll get individual transcript summaries and general summary of
            the entire transcripts.
          </R3>
          {transcriptFields?.get_summary_of_transcripts?.finished ? (
            <ReportGeneratedStatusBtn>
              Summary Generated
            </ReportGeneratedStatusBtn>
          ) : (
            <TypeSelectorBtnCircle />
          )}
          {/* <TypeSelectorBtnCircle /> */}
        </TypeSelectorBtn>
      </Row4>
      <Row4>
        <TypeSelectorBtn
          onClick={() => setSurveyAnalysisType("keyword-summary")}
          selected={surveyAnalysisType === "keyword-summary"}
        >
          <R1>Keyword Summary</R1>
          <R3>
            You can select keyword and we will generate summary based on that.
          </R3>
          {transcriptFields?.get_summary_of_keyword_transcript?.finished ? (
            <ReportGeneratedStatusBtn>
              Summary Generated
            </ReportGeneratedStatusBtn>
          ) : (
            <TypeSelectorBtnCircle />
          )}
        </TypeSelectorBtn>

        <TypeSelectorBtn
          onClick={() => setSurveyAnalysisType("question-answer-bot")}
          selected={surveyAnalysisType === "question-answer-bot"}
        >
          <R1>Question Answer Bot</R1>
          <R3>
            You can ask questions regarding your transcripts and SumUps' Q&A Bot
            will provide you the necessary answers, based on the transcripts you
            have chosen.
          </R3>
          {transcriptFields?.answer_question_from_transcript?.finished ? (
            <ReportGeneratedStatusBtn>Continue Q&A</ReportGeneratedStatusBtn>
          ) : (
            <TypeSelectorBtnCircle />
          )}
        </TypeSelectorBtn>
      </Row4>
    </Container>
  );
};

export default ChooseAnalysisType;
