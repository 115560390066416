import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useFirestore } from "context/firestore";
import { Row1 } from "../projectComponents";

const ProjectStatistics = ({ projectid, keywords }) => {
  // const [loading, setLoading] = useState(false);
  const { getProjectStatistics } = useFirestore();
  const [projectStatistics, setProjectStatistics] = useState(null);

  const getProjectStatisticsFunction = async () => {
    const projectStatisics = await getProjectStatistics(projectid);
    setProjectStatistics(projectStatisics);
  };

  useEffect(() => {
    getProjectStatisticsFunction();
    // eslint-disable-next-line
  }, []);

  return (
    <Row1Styled>
      <Stat side={"left"}>
        <p>{projectStatistics ? projectStatistics[0].numberOfDocs : 0}</p>
        <span>files analysed</span>
      </Stat>

      <Stat side={"middle"}>
        <p>{projectStatistics ? projectStatistics[0].numberOfpages : 0}</p>
        <span>pages</span>
      </Stat>

      <Stat side={"right"}>
        <p>{keywords ? keywords.length : 0}</p>
        <span>keywords</span>
      </Stat>
    </Row1Styled>
  );
};

export default ProjectStatistics;

const Row1Styled = styled(Row1)`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Stat = styled.div`
  flex: 1;
  margin: 0 10px;
  background-color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-left: ${({ side }) => (side === "left" ? "0" : "10px")};
  margin-right: ${({ side }) => (side === "right" ? "0" : "10px")};

  p {
    margin: 0;
    margin-right: 10px;
    font-size: 30px;
    font-weight: 600;
  }

  span {
    margin-left: 10px;
    font-size: 18px;
    margin: 0;
  }
`;
