import React from "react";
import styled from "styled-components";

const BillingHistory = ({ billingHistory }: { billingHistory: any[] }) => (
  <Main>
    <P1>Billing History</P1>

    <table>
      <thead>
        <tr>
          <th>Invoice</th>
          <th>Amount</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {billingHistory.map((b, i) => {
          console.log(b);
          console.log(b.created);
          let date = new Date(b.created * 1000);
          const formattedDate = date.toLocaleString();
          return (
            <tr key={i}>
              <td>{b.description}</td>
              <td>
                {b.currency.toUpperCase()} ${b.amount / 100}
              </td>
              <td>{formattedDate}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </Main>
);

export default BillingHistory;

const Main = styled.div`
  padding: 20px 0;

  table {
    margin: 20px 0;
    width: 100%;
    margin-bottom: 50px;
  }

  thead,
  th {
    text-align: left;
    color: #7d7d7d;
    padding: 20px 0;
    border-bottom: 0.5px solid rgba(193, 193, 193, 0.3);
  }

  td {
    padding: 20px 0;
    border-bottom: 0.5px solid rgba(193, 193, 193, 0.3);
  }
`;

const P1 = styled.h3`
  margin-bottom: 15px;
`;
