import React, { useState, ReactChildren, ReactChild } from "react";
import styled from "styled-components";

import { useAuthentication } from "../context/authentication";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

interface AuthMenuWrapperProps {
  children: ReactChild | ReactChildren;
  hideSidebar?: boolean;
}

const AuthMenuWrapper = ({ children, hideSidebar }: AuthMenuWrapperProps) => {
  const [isActive, setActive] = useState(false);
  const { push } = useHistory();
  const { doLogout, user } = useAuthentication();
  const location = useLocation();

  const handleToggle = () => {
    setActive(!isActive);
  };

  const logout = async () => {
    await doLogout();
    handleToggle();
    push("/login");
  };

  return (
    <Page>
      {!hideSidebar && (
        <LeftMenu>
          <LogoContainer>
            <Link to="/">
              <Logo src={require("../assets/SumUps-logo-v2.png")} />
            </Link>
          </LogoContainer>

          <MenuButtons>
            <MenuButton
              to="/"
              selected={location.pathname === "/" ? true : false}
            >
              Projects
            </MenuButton>
            {process.env.REACT_APP_FEATURES_IN_DEV && (
              <MenuButton
                to="/payments"
                selected={location.pathname === "/payments" ? true : false}
              >
                Payments
              </MenuButton>
            )}

            <MenuButton
              to="/support"
              selected={location.pathname === "/support" ? true : false}
            >
              Support
            </MenuButton>
          </MenuButtons>
        </LeftMenu>
      )}

      <RightMain>
        <RightMenu>
          <ProfileDiv onClick={() => setActive(!isActive)}>
            {/* <ProfilePic src={require("../assets/sumups-profile-pic.png")} /> */}
            <ProfileText>{user?.email && user?.email}</ProfileText>
            <ProfileDropdownBtn
              src={require("../assets/profile-dropdown-btn.png")}
            />
          </ProfileDiv>

          {isActive ? (
            <ProfileDropdown onClick={() => logout()}>
              <p>Logout</p>
            </ProfileDropdown>
          ) : null}
        </RightMenu>

        <InjectedComponent className="AuthMenuWrapper-InjectedComponent">
          {children}
        </InjectedComponent>
      </RightMain>
    </Page>
  );
};

export default AuthMenuWrapper;

const Page = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

const LeftMenu = styled.div`
  width: 300px;
`;

const LogoContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 125px;
  height: 30px;
  margin-top: 10px;
  object-fit: contain;
`;

const MenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const MenuButton = styled(Link)<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? "black" : "white")};
  color: ${({ selected }) => (!selected ? "black" : "white")};
  border: 2px solid ${({ selected }) => (selected ? "black" : "white")};
  border-radius: 12px;
  padding: 8px 15px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  transition: border 0.3s ease-in-out;

  :hover {
    border: 2px solid black;
  }
`;

const RightMain = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const RightMenu = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ProfileDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  border-radius: 10px;
  padding: 0 15px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  :hover {
    box-shadow: 0px 0px 9px 2px #a2a2a27a;
  }
`;

// const ProfilePic = styled.img`
//   width: 30px;
//   height: 30px;
//   margin-right: 20px;
// `;

const ProfileText = styled.p`
  font-size: 16px;
`;

const ProfileDropdownBtn = styled.img`
  cursor: pointer;
  width: 16px;
  height: 10px;
  margin-left: 20px;
`;

const ProfileDropdown = styled.div`
  position: absolute;
  border: 1px solid #d1d1d1;
  background-color: white;
  border-radius: 8px;
  bottom: 0px;
  padding: 0 15px;
  left: 20px;
  cursor: pointer;

  p {
    margin: 5px 0;
  }
`;

const InjectedComponent = styled.div`
  flex: 1;
  display: flex;
`;
