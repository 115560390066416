import React, { useState } from "react";

import { Link } from "react-router-dom";
import { TH2, Input, PrimaryBtn, TS1 } from "../../components/ThemeComponents";
import { useFirebase } from "../../context/firebase";
import { useAuthForm } from "./hooks";
import {
  InnerLeft,
  InnerRight,
  LoginDudeImg,
  Logo,
  Main,
  OuterLeft,
} from "./styles";

const ForgotPassword = () => {
  const { auth } = useFirebase();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleChange, formValues } = useAuthForm();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    auth
      ?.sendPasswordResetEmail(formValues.email)
      .then(() => {
        alert("A password reset link has been sent to your email.");
      })
      .catch((r) => {
        if (r.code === "auth/user-not-found") {
          alert("Email not found.");
        } else {
          alert("Service unavailable currently.\nTry again later.");
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Main>
      <OuterLeft>
        <Logo src={require("../../assets/SumUps-logo-v2.png")} />
        <InnerLeft>
          <TH2>Fill in your e-mail.</TH2>

          <form onSubmit={handleSubmit}>
            <Input
              type="email"
              required
              id="email"
              name="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />
            <PrimaryBtn type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Loading..." : "Recover"}
            </PrimaryBtn>
            <Link to="/login">
              <TS1>Go back to login</TS1>
            </Link>
          </form>
        </InnerLeft>
      </OuterLeft>

      <InnerRight>
        <LoginDudeImg src={require("../../assets/sumups-d2-login-dude.png")} />
      </InnerRight>
    </Main>
  );
};

export default ForgotPassword;
