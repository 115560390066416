import React, { useState } from "react";

import { useHistory, Link } from "react-router-dom";
import { useAuthentication } from "../../context/authentication";

import { TH2, Input, PrimaryBtn, TS1 } from "../../components/ThemeComponents";
import {
  ForgotPasswordLink,
  InnerLeft,
  InnerRight,
  LoginDudeImg,
  Logo,
  Main,
  OuterLeft,
} from "./styles";
import { useAuthForm } from "./hooks";

const Login = () => {
  const { push } = useHistory();
  const { doLogin } = useAuthentication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleChange, formValues } = useAuthForm();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = formValues;

    try {
      setIsSubmitting(true);

      await doLogin(email, password);

      push("/");
    } catch (e) {
      alert("Login failed, email or password is incorrect.");
      setIsSubmitting(false);
    }
  };

  return (
    <Main>
      <OuterLeft>
        <Logo src={require("../../assets/SumUps-logo-v2.png")} />
        <InnerLeft>
          <TH2>Log in</TH2>
          <form onSubmit={handleSubmit}>
            <Input
              type="email"
              required
              id="email"
              name="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />
            <Input
              type="password"
              required
              id="password"
              name="password"
              placeholder="Password"
              value={formValues.password}
              onChange={handleChange}
            />
            <ForgotPasswordLink to="/forgot-password">
              Forgot your password?
            </ForgotPasswordLink>
            <PrimaryBtn type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Loading..." : "Login"}
            </PrimaryBtn>
            <Link to="/register">
              <TS1>I don't have an account yet!</TS1>
            </Link>
          </form>
        </InnerLeft>
      </OuterLeft>
      <InnerRight>
        <LoginDudeImg src={require("../../assets/sumups-d2-login-dude.png")} />
      </InnerRight>
    </Main>
  );
};

export default Login;
