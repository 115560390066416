import React, { FC } from "react";
import { Link } from "react-router-dom";

import { PrimaryBtn } from "components/ThemeComponents";

import {
  TopBarRow,
  TopBarLeft,
  TopBarCenter,
  StepContainer,
  StepLine,
  TopBarRight,
  BackBtnImg,
} from "../projectComponents";

const stepsContainers = [
  { maxStep: 1, title: "Upload files" },
  { maxStep: 2, title: "Choose Analysis Type" },
  { maxStep: 5, title: "Process data" },
  { maxStep: 6, title: "Explore analysis" },
];

const TopBar: FC<{ step: number; topBarNextFunction: () => any }> = ({
  step,
  topBarNextFunction,
}) => {
  return (
    <TopBarRow>
      <TopBarLeft>
        <Link to="/">
          <BackBtnImg src={require("assets/back-btn.png")} />
        </Link>
      </TopBarLeft>

      <TopBarCenter>
        {stepsContainers.map((cont, index) => {
          const status =
            step === cont.maxStep
              ? "active"
              : step > cont.maxStep
              ? "done"
              : "future";
          return (
            <React.Fragment key={index}>
              <StepContainer status={status}>
                <div className="step">
                  <span>{index + 1}</span>
                </div>
                <p>{cont.title}</p>
              </StepContainer>

              {stepsContainers.length - 1 !== index && (
                <StepLine status={status} />
              )}
            </React.Fragment>
          );
        })}
      </TopBarCenter>

      <TopBarRight>
        { step < 6 && (
          <PrimaryBtn onClick={() => topBarNextFunction()}>Next</PrimaryBtn>
        )}
      </TopBarRight>
    </TopBarRow>
  );
};

export default TopBar;
