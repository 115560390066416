import React, { FC, useEffect, useState } from "react";
import { Button1, Button2, H1 } from "components/CommonComponents";
import {
  DataHeaderDiv,
  DataHeaderLeft,
  Row4Flex,
  SurveyReportQuestion,
  WhiteInput,
} from "./surveyComponents";

import {
  Row,
  ReportContainer,
  ReportInner,
  R1,
  Container,
  R3,
  Row4,
  R2,
  Col6,
  Col3,
  ReportInnerLoading,
  SummaryContainer,
} from "../projectComponents";
import { useFirestore } from "context/firestore";
import { useAsync } from "hooks";
import TranscriptResults from "./TranscriptResults";
import { PrimaryBtn } from "components/ThemeComponents";
import dataProcesss from "services/DataProcess";
import ChatBox, { FlexBox, LoadingDots } from "./ChatBox";
import Spinner from "components/Spinner";

const TextResponsesAnalysis: FC<{
  projectId: string;
  projectTitle?: string;
}> = ({ projectId, projectTitle }) => {
  const { getTableColumnSummaries } = useFirestore();
  const { loading, data: summaries } = useAsync(
    getTableColumnSummaries,
    projectId
  );

  if (loading) return <div>Loading...</div>;

  return (
    <ReportContainer>
      <H1>{projectTitle}</H1>
      <br />
      <R1>Columns analyzed</R1>
      <Row>
        {summaries?.data.summaries.map(({ qualitativeField }, i) => (
          <SurveyReportQuestion key={i}>
            <p>{qualitativeField}</p>
          </SurveyReportQuestion>
        ))}
      </Row>

      {summaries?.data.summaries.map(({ qualitativeField, summary }, index) => (
        <React.Fragment key={index}>
          <R1>{qualitativeField}</R1>
          <ReportInner>{summary}</ReportInner>
        </React.Fragment>
      ))}
    </ReportContainer>
  );
};

const GenerateKeywordSummaries = ({
  setKeyword,
  setSelectedTranscriptFiles,
  projectFiles,
  keyword,
  selectedTranscriptFiles,
  selectColumnHeaderHelper,
  projectid,
  keywordSummaries,
  transcriptFields,
}: any) => {
  const [transcripts, setTranscripts] = useState<any[]>([]);
  const { getTranscriptKeywords, createTranscriptKeywords } = useFirestore();
  const [summaries, setSummaries] = useState<any>([]);
  useEffect(() => {
    let localProjectFilesBuilder: any[] = [];
    let localSummaries: any[] = [];
    if (projectFiles) {
      for (var i = 0; i < projectFiles.length; i++) {
        const currentFile = projectFiles[i].data();
        currentFile.fileId = projectFiles[i].id;
        localProjectFilesBuilder.push(currentFile);
      }
      setTranscripts(localProjectFilesBuilder);
    }
    if (keywordSummaries) {
      for (var i = 0; i < keywordSummaries.length; i++) {
        const currentSummary = keywordSummaries[i].data();
        currentSummary.id = keywordSummaries[i].id;
        localSummaries.push(currentSummary);
      }
      setSummaries(
        localSummaries.sort((a: any, b: any) => b.createdOn - a.createdOn)
      );
    }
  }, [projectFiles, keywordSummaries]);
  const [btnLoading, setBtnLoading] = useState(false);
  const generateSummary = async () => {
    try {
      setBtnLoading(true);
      const _keyword = keyword;
      const _files = selectedTranscriptFiles;
      setKeyword("");
      setSelectedTranscriptFiles([]);
      const _newKeyword = await createTranscriptKeywords(
        projectid,
        _keyword,
        _files
      );
      const _id = _newKeyword.f_.path.segments[1];
      await dataProcesss.transcript.get_summary_of_keyword(projectid, _id);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setBtnLoading(false);
    }
  };
  return (
    <ReportContainer>
      <H1>Keyword Summary</H1>

      {summaries && summaries.length < 5 && (
        <>
          <Row4Flex>
            <Col6>
              <WhiteInput
                placeholder="Enter keyword"
                value={keyword || ""}
                onChange={(e: any) => setKeyword(e.target.value)}
                maxLength={30}
                disabled={btnLoading}
              />
            </Col6>
            <Col3>
              {btnLoading ? (
                <PrimaryBtn
                  onClick={() => generateSummary()}
                  disabled={!(keyword && selectedTranscriptFiles.length > 0)}
                >
                  Generating Summary...
                </PrimaryBtn>
              ) : (
                <PrimaryBtn
                  onClick={() => generateSummary()}
                  disabled={!(keyword && selectedTranscriptFiles.length > 0)}
                >
                  Generate Summary
                </PrimaryBtn>
              )}
            </Col3>
          </Row4Flex>
        </>
      )}
      {keyword && (
        <>
          <R3>Select the transcripts you want to analyze.</R3>

          <FlexBox>
            {transcripts.map((header: any, index: any) => {
              return (
                <DataHeaderDiv
                  key={index}
                  onClick={() =>
                    selectedTranscriptFiles.length < 2
                      ? selectColumnHeaderHelper(header.fileId)
                      : selectedTranscriptFiles.includes(header.fileId)
                      ? selectColumnHeaderHelper(header.fileId)
                      : null
                  }
                  selected={selectedTranscriptFiles?.includes(header.fileId)}
                  selectable={selectedTranscriptFiles.length < 2}
                  qualitative={true}
                >
                  <DataHeaderLeft>
                    <p className="headername">{header.name?.split(".")[0]} </p>
                  </DataHeaderLeft>
                </DataHeaderDiv>
              );
            })}
          </FlexBox>
        </>
      )}

      {summaries?.map((i: any) => (
        <SummaryContainer>
          <R1>{i.keyword}</R1>

          {i.summary ? (
            <ReportInner>
              <p>{i.summary}</p>
            </ReportInner>
          ) : (
            <ReportInnerLoading>
              <LoadingDots />
            </ReportInnerLoading>
          )}
        </SummaryContainer>
      ))}
    </ReportContainer>
  );
};

const ExploreAnalysis: FC<{
  project: any;
  fileSummaries: any;
  generalSummaries: any;
  projectId: string;
  projectTitle?: string;
  projectFiles: any;
  setSelectedTranscriptFiles: React.Dispatch<React.SetStateAction<string[]>>;
  setKeyword: React.Dispatch<React.SetStateAction<string | null>>;
  surveyAnalysisType: string | null;
  selectedTranscriptFiles: string[];
  keyword: string | null;
  transcriptFields: any;
  keywordSummaries: any[];
  transcriptQuestionAnswerList: any[];
}> = ({
  project,
  fileSummaries,
  generalSummaries,
  projectId,
  projectTitle,
  projectFiles,
  setSelectedTranscriptFiles,
  setKeyword,
  surveyAnalysisType,
  selectedTranscriptFiles,
  keyword,
  transcriptFields,
  keywordSummaries,
  transcriptQuestionAnswerList,
}) => {
  const selectColumnHeaderHelperQuant = (headerName: string) => {
    if (selectedTranscriptFiles.includes(headerName)) {
      setSelectedTranscriptFiles(
        selectedTranscriptFiles.filter((item) => item !== headerName)
      );
    } else {
      setSelectedTranscriptFiles([...selectedTranscriptFiles, headerName]);
    }
  };
  if (surveyAnalysisType === "just-text-responses") {
    return (
      <TranscriptResults
        project={project}
        projectFiles={projectFiles}
        fileSummaries={fileSummaries}
        generalSummaries={generalSummaries}
      />
    );
  } else if (surveyAnalysisType === "keyword-summary") {
    return (
      <GenerateKeywordSummaries
        keyword={keyword}
        setKeyword={setKeyword}
        setSelectedTranscriptFiles={setSelectedTranscriptFiles}
        projectFiles={projectFiles}
        selectedTranscriptFiles={selectedTranscriptFiles}
        selectColumnHeaderHelper={selectColumnHeaderHelperQuant}
        projectid={projectId}
        transcriptFields={transcriptFields}
        keywordSummaries={keywordSummaries}
      />
    );
  } else if (surveyAnalysisType === "question-answer-bot") {
    return (
      <div>
        <H1>Question Answer Bot</H1>

        <ChatBox
          projectid={projectId}
          transcriptQuestionAnswerList={transcriptQuestionAnswerList}
          transcriptFields={transcriptFields}
          projectFiles={projectFiles}
          selectedTranscriptFiles={selectedTranscriptFiles}
          selectColumnHeaderHelper={selectColumnHeaderHelperQuant}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default ExploreAnalysis;
