import React, { useEffect, useState } from "react";
import styled from "styled-components";

import DropDownIcon from "assets/categorical-summary-dropdown.png";

const CategoricalSummaryComponent = (categoricalSummary) => {
  const [csData, setCSData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    setCSData(categoricalSummary.categoricalSummary);
    // eslint-disable-next-line
  }, []);

  return (
    <Main onClick={() => setShow(!show)}>
      <InnerRow>
        <Left>
          <Keyword>{csData.keyword ? csData.keyword : ""}</Keyword>
          <Label>{csData.label ? csData.label : ""}</Label>
        </Left>

        <OpenBtnContainer>
          <img
            src={DropDownIcon}
            className={show ? "flip" : ""}
            alt="Open categorical summary"
          />
        </OpenBtnContainer>
      </InnerRow>

      {show ? <SummaryText>{csData.summary}</SummaryText> : null}
    </Main>
  );
};

export default CategoricalSummaryComponent;

const Main = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 30px;
  padding: 10px;
  background-color: white;
  margin-bottom: 20px;
`;

const InnerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Keyword = styled.p`
  font-size: 22px;
  color: black;
  margin: 0;
  margin-left: 20px;

  :first-letter {
    text-transform: uppercase;
  }
`;

const Label = styled.p`
  font-size: 12px;
  margin: 0;
  margin-left: 20px;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #e4fd90;
  color: #94a06a;
  text-transform: uppercase;
`;

const OpenBtnContainer = styled.div`
  width: 33px;
  height: 29px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 70%;
  }

  .flip {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
`;

const SummaryText = styled.p`
  color: black;
  font-size: 17px;
  margin: 20px;
`;
