import http from "../httpService";

type dataProcess = {
  projectid: String;
};

const dataProcesss = {
  getUserStatus: async (data: any) => {
    // await http.post("/user-role", data);
    try {
      await http.post("/user-role", data).then((res) => res.data);
      return { approved_user: true };
    } catch (error: any) {
      return { apierror: error.response.data, approved_user: false };
    }
  },
  sendSlackMsg: async (data: any) => {
    await http.post("/slack-msg", data);
  },
  startDataProcessing: async (data: dataProcess) => {
    await http.post(`/projects/${data.projectid}/pdf-extraction/api-1`);
  },
  startReportGeneration: async (data: any) => {
    // TODO FIX THIS
    const paymentMethodsPromise = http.get("/payments/payment-methods");

    const { data: paymentMethods } = await paymentMethodsPromise;
    const methodID = paymentMethods.data[0].id;
    const projectid = data.projectid;

    await http.post(`/projects/${projectid}/pdf-extraction/api-2`, {
      ...data,
      payment_method_id: methodID,
    });
  },
  startQuestionProcessing: async (questionid: string) => {
    await http.post("/question-answer", { questionid });
  },
  createReportPDF: async (projectId: string) => {
    try {
      const result = await http
        .get(`/projects/${projectId}/create-report-pdf`, {
          responseType: "blob",
        })
        .then((res) => res.data);
      return result;
    } catch (error: any) {
      return { apierror: error.response.data };
    }
  },
  deleteProject: async (data: any) => {
    await http.post("/delete-project", data);
  },
  deleteFile: async (data: any) => {
    await http.post("/delete-file", data);
  },

  transcript: {
    upload_data_to_pinecone: async (projectId: string) => {
      return await http.post(
        `/projects/${projectId}/transcript_data/upload_vectors`
      );
    },
    get_summary_of_transcripts: async (projectId: string) => {
      return await http.post(
        `/projects/${projectId}/transcript_data/get_summary_of_transcripts`
      );
    },
    get_general_summary_of_transcripts: async (projectId: string) => {
      return await http.post(
        `/projects/${projectId}/transcript_data/get_general_summary_of_transcripts`
      );
    },
    get_summary_of_keyword: async (projectId: string,keyword_id: string) => {
      return await http.post(
        `/projects/${projectId}/transcript_data/summary_of_keyword`, {
          keyword_id
        }
      );
    },
    get_summary_of_question_ans: async (projectId: string,question_id: string) => {
      return await http.post(
        `/projects/${projectId}/transcript_data/answer_from_transcript`, {
          question_id
        }
      );
    },
  },

  tabular: {
    get_tabular_data: async (projectId: string) => {
      return await http.post(
        `/projects/${projectId}/tabular_data/get_tabular_data`
      );
    },
    get_summary_of_columns: async (props: {
      projectId: string;
      qualitativeFields: string[];
    }) => {
      return await http.post(
        `/projects/${props.projectId}/tabular_data/get_summary_of_columns`,
        { selected_qualitative_fields: props.qualitativeFields }
      );
    },
    get_categorical_summaries: async (props: {
      projectId: string;
      qualitativeFields: string[];
      quantitativeFields: string[];
    }) => {
      return await http.post(
        `/projects/${props.projectId}/tabular_data/get_categorical_summaries`,
        {
          selected_qualitative_fields: props.qualitativeFields,
          selected_quantitative_fields: props.quantitativeFields,
        }
      );
    },
    get_keyword_summary: async (props: {
      projectId: string;
      keyword_id: string;
    }) => {
      return await http.post(
        `/projects/${props.projectId}/tabular_data/summary_of_keyword`,
        { keyword_id:props.keyword_id }
      );
    },
    get_question_ans: async (props: {
      projectId: string;
      question_id: string;
    }) => {
      return await http.post(
        `/projects/${props.projectId}/tabular_data/answer_from_survey`,
        { question_id:props.question_id }
      );
    },
  },
};

export default dataProcesss;
