import React, { useState } from "react";

import { useHistory, Link } from "react-router-dom";
import { useAuthentication } from "../../context/authentication";

import DataProcessService from "../../services/DataProcess";

import { TH2, Input, PrimaryBtn, TS1 } from "../../components/ThemeComponents";
import {
  InnerLeft,
  InnerRight,
  Logo,
  Main,
  OuterLeft,
  RegisterDudeImg,
} from "./styles";
import { useAuthForm } from "./hooks";

const Register = () => {
  const { push } = useHistory();
  const { doRegister } = useAuthentication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleChange, formValues } = useAuthForm();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = formValues;

    try {
      setIsSubmitting(true);

      await doRegister(email, password);
      DataProcessService.sendSlackMsg({
        msg: "New user registered : " + email,
      });

      push("/");
    } catch (err) {
      alert(
        "Registration failed, you may have already used this email address."
      );
      setIsSubmitting(false);
    }
  };

  return (
    <Main>
      <OuterLeft>
        <Logo src={require("../../assets/SumUps-logo-v2.png")} />
        <InnerLeft>
          <TH2>Register</TH2>
          <form onSubmit={handleSubmit}>
            <Input
              type="email"
              required
              id="email"
              name="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />
            <Input
              type="password"
              required
              id="password"
              name="password"
              placeholder="Password"
              value={formValues.password}
              onChange={handleChange}
            />
            <PrimaryBtn type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Loading..." : "Register"}
            </PrimaryBtn>
            <Link to="/login">
              <TS1>I already have an account</TS1>
            </Link>
          </form>
        </InnerLeft>
      </OuterLeft>
      <InnerRight>
        <RegisterDudeImg
          src={require("../../assets/sumups-d2-signup-dude.png")}
        />
      </InnerRight>
    </Main>
  );
};

export default Register;
